import React from 'react';
import { useNavigate } from 'react-router-dom';


function Scatter({ }) {
  const navigate = useNavigate();
  return (
    <div>
        var             Scatter
    </div>
  );
}

export default Scatter;
