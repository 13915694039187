import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar'
import EncadreRechercheEport from '../components/EncadreRechercheEport'
import SautDemarquation from '../components/SautDemarquation';
import TableChamp from '../components/TableChamp';
import EportBtnNav from '../components/EportBtnNav';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';
import TableChampGlobal from '../components/TableChampGlobal';
import TableChampBan from '../components/TableChampBan';
import TableChampFrequent from '../components/TableChampFrequent';

function EportPickBan({ href, iconClass, text }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { name,value } = location.state || {};

    const handleClickOverview = (e) => {
        e.preventDefault();   
          navigate('/esportOverview', {
            state: {}});};
            const handleClickRanking = (e) => {
                e.preventDefault();   
                  navigate('/esportRanking', {
                    state: {}});};
                    const handleClickPickBan = (e) => {
                        e.preventDefault();   
                          navigate('/esportPickBan', {
                            state: {}});};
                            const handleClickMatchList = (e) => {
                                e.preventDefault();   
                                  navigate('/esportMatchList', {
                                    state: {}});};
                                    const handleClickSchedule = (e) => {
                                        e.preventDefault();   
                                          navigate('/esportSchedule', {
                                            state: {}});};
                                            const handleClickBracket = (e) => {
                                                e.preventDefault();   
                                                  navigate('/esportBracket', {
                                                    state: {}});};
    const [data, setData] = useState([]);
    const [tableType, setTableType] = useState('champ'); // Valeur initiale à 'champ'

    const fetchData = async (id) => {
        try {
            const response = await fetch(`https://lolprostat.com:8088/tournament?to=${value}`);
            const result = await response.json();
            console.log("Résultat API:", result);

            // Vérifier si les données sont sous forme de chaîne JSON et parser si nécessaire
            const dataFromAPI = result['participants'][0][id];
            const parsedData = typeof dataFromAPI === 'string' ? JSON.parse(dataFromAPI) : dataFromAPI;

            setData(parsedData); // Mettre à jour l'état avec les nouvelles données
            setTableType(id); // Mettre à jour le type de tableau
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        }
    };

    // Appeler fetchData avec l'id 'champ' au moment où le composant est monté
    useEffect(() => {
        fetchData('champ');
    }, []); // Le tableau vide signifie que ce useEffect ne s'exécutera qu'une seule fois, au montage

    const handleClick = (id) => {
        fetchData(id);
    };

    console.log(data)
  return (
    <div>
        <Navbar />
        <section className="home">




        <div className="text">Eport hub</div>
            <div className="flexwrap mt10" >
                <div className="encadre90SA">
                    <EportBtnNav href='/esportOverview' name={name} value={value} text='Overview'/>
                    <EportBtnNav href='/esportRanking' name={name} value={value} text='Ranking'/>
                    <div className="newBtnNav" style={{backgroundColor:'white'}}>Picks & Bans</div>
                    <EportBtnNav href='/esportMatchList' name={name} value={value} text='Match List'/>
                    {/* <EportBtnNav href='/esportSchedule' name={name} value={value} text='Schedule'/>
                    <EportBtnNav href='/esportBracket' name={name} value={value} text='Bracket'/> */}
                    <EportBtnNav href='/esportplayerlist' name={name} value={value} text='Player List' />
                    <EportBtnNav href='/esportteamlist' name={name} value={value} text='Team List' />
                </div>
            </div>

            <div className="flexwrap mt25">
                <h1 className="encadre90fs">{name} - Picks & Bans</h1>
            </div>

            <div className="flexwrap mt10">
  <div className="encadre90SA">
    <div
      className={`newBtn ${tableType === 'champ' ? 'activeButton' : ''}`}
      onClick={() => handleClick('champ')}
      id="btnAll"
    >
      Champion All
    </div>
    <div
      className={`newBtn ${tableType === 'champT' ? 'activeButton' : ''}`}
      onClick={() => handleClick('champT')}
      id="btnTop"
    >
      Champion Top
    </div>
    <div
      className={`newBtn ${tableType === 'champJ' ? 'activeButton' : ''}`}
      onClick={() => handleClick('champJ')}
      id="btnJgl"
    >
      Champion Jgl
    </div>
    <div
      className={`newBtn ${tableType === 'champM' ? 'activeButton' : ''}`}
      onClick={() => handleClick('champM')}
      id="btnMid"
    >
      Champion Mid
    </div>
    <div
      className={`newBtn ${tableType === 'champA' ? 'activeButton' : ''}`}
      onClick={() => handleClick('champA')}
      id="btnAdc"
    >
      Champion Adc
    </div>
    <div
      className={`newBtn ${tableType === 'champS' ? 'activeButton' : ''}`}
      onClick={() => handleClick('champS')}
      id="btnSup"
    >
      Champion Supp
    </div>
    <div
      className={`newBtn ${tableType === 'ban' ? 'activeButton' : ''}`}
      onClick={() => handleClick('ban')}
      id="btnBan"
    >
      Ban
    </div>
    <div
      className={`newBtn ${tableType === 'frequent' ? 'activeButton' : ''}`}
      onClick={() => handleClick('frequent')}
      id="btnMatchup"
    >
      Matchup
    </div>
  </div>
</div>



            {data && (
                    <div className="debutEncadre95Centre mt10 mb30">
                        {tableType === 'champT' && <TopChampionsTable data={data} />}
                        {tableType === 'champJ' && <JglChampionsTable data={data} />}
                        {tableType === 'champM' && <MidChampionsTable data={data} />}
                        {tableType === 'champA' && <AdcChampionsTable data={data} />}
                        {tableType === 'champS' && <SupChampionsTable data={data} />}
                        {tableType === 'ban' && <BanTable data={data} />}
                        {tableType === 'frequent' && <MatchupTable data={data} />}
                        {tableType === 'champ' && <ChampTable data={data} />}
                    </div>
                )}

                <br></br>




        </section>
    </div>
  );
}

const TopChampionsTable = ({ data }) => <TableChamp data={data}/>;
const JglChampionsTable = ({ data }) => <TableChamp data={data}/>;
const MidChampionsTable = ({ data }) => <TableChamp data={data}/>;
const AdcChampionsTable = ({ data }) => <TableChamp data={data}/>;
const SupChampionsTable = ({ data }) => <TableChamp data={data}/>;
const ChampTable = ({ data }) => <TableChampGlobal data={data}/>;
const BanTable = ({ data }) => <TableChampBan data={data}/>;
const MatchupTable = ({ data }) => <TableChampFrequent data={data}/>;
export default EportPickBan;
