import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useLocation } from 'react-router-dom';
import SpinnerAttente from '../../components/SpinnerAttente';
import { Line, Radar } from "react-chartjs-2";
import "chart.js/auto";
import CarteMatchup from '../../components/analyse/CarteMatchup';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from "chart.js";
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);


function DetailChampion() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const puuid = queryParams.get('puuid');
    const champion = queryParams.get('champion');

    const [loading, setLoading] = useState(true);
    const [playerData, setPlayerData] = useState(null);
    const [soloqData, setSoloqData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [itemData, setItemData] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(null);
    const [tooltipcontent, setTooltipContent] = useState(null);


    const fetchGames = (puuid, champion) => {
        fetch(`https://lolprostat.com:8088/analyse/getChampion?puuid=${puuid}&champion=${champion}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setPlayerData(data);
                fetch(`https://lolprostat.com:8088/data/getChampion?champion=${champion}`)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data2) => {
                        setSoloqData(data2);
                        fetch(`https://ddragon.leagueoflegends.com/cdn/14.22.1/data/fr_FR/item.json`)
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                }
                                return response.json();
                            })
                            .then((data3) => {
                                setItemData(data3);
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.error('Erreur lors du chargement des données soloq:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Erreur lors du chargement des données soloq:', error);
                    });
            })
            .catch((error) => {
                console.error('Erreur lors du chargement des données du joueur:', error);
            });
    };

    useEffect(() => {
        if (puuid && champion) {
            fetchGames(puuid, champion);
        }
    }, [puuid, champion]);

    useEffect(() => {
        if (playerData) {
            const simplifiedData = playerData.participants.map((p) => ({
                patch: p.Patch.split(".").slice(0, 2).join("."),
                win: p.win,
            }));

            const patchWinrates = simplifiedData.reduce((acc, { patch, win }) => {
                if (!acc[patch]) {
                    acc[patch] = { wins: 0, total: 0 };
                }
                acc[patch].wins += win;
                acc[patch].total += 1;
                return acc;
            }, {});

            const sortedPatches = Object.keys(patchWinrates).sort((a, b) => {
                const [majorA, minorA] = a.split(".").map(Number);
                const [majorB, minorB] = b.split(".").map(Number);
                return majorA - majorB || minorA - minorB;
            });

            const labels = sortedPatches;
            const winrates = labels.map(
                (patch) => (patchWinrates[patch].wins / patchWinrates[patch].total) * 100
            );
            const winrate50 = new Array(labels.length).fill(50);

            setChartData({
                labels,
                datasets: [
                    {
                        label: "Winrate (%)",
                        data: winrates,
                        borderColor: "rgba(75, 192, 192, 1)",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        tension: 0.4,
                    },
                    {
                        label: "50% Winrate",
                        data: winrate50,
                        borderColor: "red",  // Couleur de la ligne
                        borderWidth: 2,
                        borderDash: [5, 5],  // Style pointillé
                        fill: false,  // Ne pas remplir sous la ligne
                    },
                ],
            });
        }
    }, [playerData]);

    if (loading) {
        return (
            <div>
                <Navbar />
                <section className="home">
                    <div className="Titre">
                        Deep player analysis - Champion details : {champion}
                    </div>
                    <SpinnerAttente text="player and champion" />
                </section>
            </div>
        );
    }

    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    };
    const images = importAll(require.context('../../img/champion', false, /\.(png|jpe?g|svg)$/));
    const imageSpell = importAll(require.context('../../img/spell', false, /\.(png|jpe?g|svg)$/));
    const imagesItems = importAll(require.context('../../img/item', false, /\.(png|jpe?g|svg)$/));

    const championStats = {};
    const matchupStats = {};

    playerData.participants.forEach(game => {
        const champ = game.champ;
        const win = game.win === 1; // Si win = 1, c'est une victoire
        const opponentChamp = game.opposantChamp;
        const matchupKey = `${champ} vs ${opponentChamp}`;

        // Si le champion n'existe pas encore dans championStats, initialiser ses stats
        if (!championStats[champ]) {
            championStats[champ] = {
                games: 0,
                wins: 0,
                losses: 0,
                kills: 0,
                deaths: 0,
                assists: 0,
                dpm: 0,
                gpm: 0,
                kp: 0,
                dshare: 0,
                vspm: 0,
            };
        }
        if (!matchupStats[matchupKey]) {
            matchupStats[matchupKey] = {
                games: 0,
                wins: 0,
                losses: 0,
                kills: 0,
                deaths: 0,
                assists: 0,
                dpm: 0,
                gpm: 0,
                kp: 0,
            };
        }

        // Mettre à jour les stats du champion
        championStats[champ].games += 1;
        championStats[champ].kills += game.kills;
        championStats[champ].deaths += game.death;
        championStats[champ].assists += game.assist;
        championStats[champ].dpm += game.damagePerMinute;
        championStats[champ].gpm += game.goldPerMinute;
        championStats[champ].kp += game.killParticipation;
        championStats[champ].dshare += game.teamDamagePercentage;
        championStats[champ].vspm += game.visionScorePerMinute;

        if (win) {
            championStats[champ].wins += 1;
        } else {
            championStats[champ].losses += 1;
        }

        matchupStats[matchupKey].games += 1;
        matchupStats[matchupKey].kills += game.kills;
        matchupStats[matchupKey].deaths += game.death;
        matchupStats[matchupKey].assists += game.assist;
        matchupStats[matchupKey].dpm += game.damagePerMinute;
        matchupStats[matchupKey].gpm += game.goldPerMinute;
        matchupStats[matchupKey].kp += game.killParticipation;

        if (win) {
            matchupStats[matchupKey].wins += 1;
        } else {
            matchupStats[matchupKey].losses += 1;
        }
    });
    const championDetails = [];
    for (const champ in championStats) {
        const { games, wins, losses, kills, deaths, assists, dpm, gpm, kp, dshare, vspm } = championStats[champ];
        const winrate = (wins / games) * 100;
        const kda = deaths === 0 ? (kills + assists) : (kills + assists) / deaths;

        // Pousser les détails dans un tableau
        championDetails.push({
            champion: champ,
            games,
            wins,
            losses,
            winrate: winrate.toFixed(0), // Pourcentage avec 2 décimales
            KDA: kda.toFixed(2), // KDA avec 2 décimales^
            kills: (kills / games).toFixed(1),
            deaths: (deaths / games).toFixed(1),
            assists: (assists / games).toFixed(1),
            dpm: (dpm / games).toFixed(0),
            gpm: (gpm / games).toFixed(0),
            kp: ((kp / games) * 100).toFixed(1),
            dshare: (dshare / games).toFixed(2),
            vspm: (vspm / games).toFixed(2),
        });
    }

    // Trier par nombre de parties (games) en ordre décroissant
    championDetails.sort((a, b) => b.games - a.games);

    const matchupDetails = [];
    for (const matchup in matchupStats) {
        const { games, wins, losses, kills, deaths, assists, dpm, gpm, kp } = matchupStats[matchup];

        // Garder seulement les matchups avec plus de 3 games
        if (games > 0) {
            const winrate = (wins / games) * 100;
            const kda = deaths === 0 ? (kills + assists) : (kills + assists) / deaths;

            // Pousser les détails dans un tableau
            matchupDetails.push({
                matchup, // Ce sera sous la forme "Champion vs OpponentChampion"
                games,
                wins,
                losses,
                winrate: winrate.toFixed(0), // Pourcentage avec 2 décimales
                KDA: kda.toFixed(2),
                kills: (kills / games).toFixed(1),
                deaths: (deaths / games).toFixed(1),
                assists: (assists / games).toFixed(1),
                dpm: (dpm / games).toFixed(0),
                gpm: (gpm / games).toFixed(0),
                kp: ((kp / games) * 100).toFixed(1)
            });
        }
    }

    // Trier par nombre de games (optionnel, similaire à ce que tu voulais avant)
    matchupDetails.sort((a, b) => b.games - a.games);

    const scaleGPM = [];
    const scaleDPM = [];
    const scaleKP = [];
    const scaleK = [];
    const scaleD = [];
    const scaleA = [];
    const winrate = [];
    const scaleKDA = [];
    var dureeSoloQ = 0;
    var hordePlayerSQ = 0;
    var baronPlayerSQ = 0;
    var dragonPlayerSQ = 0;
    var inhibitorPlayerSQ = 0;
    var heraldPlayerSQ = 0;
    var towerPlayerSQ = 0;
    var towerFirstPlayerSQ = 0;

    var dshareSoloQ = 0;
    var vspmSoloQ = 0;


    soloqData.participants.forEach(game => {
        scaleGPM.push(game.goldPerMinute);
        scaleDPM.push(game.damagePerMinute);
        scaleKP.push(game.killParticipation);
        scaleK.push(game.kills);
        scaleD.push(game.death);
        scaleA.push(game.assist);
        winrate.push(game.win);
        scaleKDA.push(game.kda);
        dureeSoloQ = dureeSoloQ + (game.goldEarned / game.goldPerMinute) * 60;
        hordePlayerSQ = hordePlayerSQ + game.hordeK;
        baronPlayerSQ = baronPlayerSQ + game.baronK;
        dragonPlayerSQ = dragonPlayerSQ + game.dragonK;
        inhibitorPlayerSQ = inhibitorPlayerSQ + game.inhibitorK;
        heraldPlayerSQ = heraldPlayerSQ + game.heraldK;
        towerPlayerSQ = towerPlayerSQ + game.towerK;
        towerFirstPlayerSQ = towerFirstPlayerSQ + game.towerFirst;
        dshareSoloQ = dshareSoloQ + game.damagePerMinute;
        vspmSoloQ = vspmSoloQ + game.visionScorePerMinute;
    });

    var spell1Cast = 0;
    var spell2Cast = 0;
    var spell3Cast = 0;
    var spell4Cast = 0;
    var duree = 0;
    var hordePlayer = 0;
    var baronPlayer = 0;
    var dragonPlayer = 0;
    var inhibitorPlayer = 0;
    var heraldPlayer = 0;
    var towerPlayer = 0;
    var towerFirstPlayer = 0;

    const itemPlayer = [];
    playerData.participants.forEach(game => {
        spell1Cast = spell1Cast + game.spell1Casts;
        spell2Cast = spell2Cast + game.spell2Casts;
        spell3Cast = spell3Cast + game.spell3Casts;
        spell4Cast = spell4Cast + game.spell4Casts;
        duree = duree + game.duree;
        hordePlayer = hordePlayer + game.hordeK;
        baronPlayer = baronPlayer + game.baronK;
        dragonPlayer = dragonPlayer + game.dragonK;
        inhibitorPlayer = inhibitorPlayer + game.inhibitorK;
        heraldPlayer = heraldPlayer + game.heraldK;
        towerPlayer = towerPlayer + game.towerK;
        towerFirstPlayer = towerFirstPlayer + game.towerFirst;
        itemPlayer.push(game.item0);
        itemPlayer.push(game.item1);
        itemPlayer.push(game.item2);
        itemPlayer.push(game.item3);
        itemPlayer.push(game.item4);
        itemPlayer.push(game.item5);
    })
    const dureeMoyenneS = ((duree / playerData.participants.length).toFixed(0));
    const dureeMoyenne = formatTime((duree / playerData.participants.length).toFixed(0));
    const dureeMoyenneSoloQ = formatTime((dureeSoloQ / soloqData.participants.length).toFixed(0));
    const dureeMoyenneSoloQS = ((dureeSoloQ / soloqData.participants.length).toFixed(0));

    const hordePlayerF = (hordePlayer / playerData.participants.length).toFixed(1);
    const baronPlayerF = (baronPlayer / playerData.participants.length).toFixed(1);
    const dragonPlayerF = (dragonPlayer / playerData.participants.length).toFixed(1);
    const inhibitorPlayerF = (inhibitorPlayer / playerData.participants.length).toFixed(1);
    const heraldPlayerF = (heraldPlayer / playerData.participants.length).toFixed(1);
    const towerPlayerF = (towerPlayer / playerData.participants.length).toFixed(1);
    const towerFirstPlayerF = (towerFirstPlayer / playerData.participants.length).toFixed(1);

    const hordePlayerFSQ = (hordePlayerSQ / soloqData.participants.length).toFixed(1);
    const baronPlayerFSQ = (baronPlayerSQ / soloqData.participants.length).toFixed(1);
    const dragonPlayerFSQ = (dragonPlayerSQ / soloqData.participants.length).toFixed(1);
    const inhibitorPlayerFSQ = (inhibitorPlayerSQ / soloqData.participants.length).toFixed(1);
    const heraldPlayerFSQ = (heraldPlayerSQ / soloqData.participants.length).toFixed(1);
    const towerPlayerFSQ = (towerPlayerSQ / soloqData.participants.length).toFixed(1);
    const towerFirstPlayerFSQ = (towerFirstPlayerSQ / soloqData.participants.length).toFixed(1);
    const dshareSoloQF = (dshareSoloQ / soloqData.participants.length);
    const vspmSoloQF = (vspmSoloQ / soloqData.participants.length);

    if (scaleK.length === scaleD.length && scaleD.length === scaleA.length) {
        // Créer la nouvelle liste
        const result2 = scaleK.map((k, index) => {
            const d = scaleD[index];
            const a = scaleA[index];

            // Éviter la division par zéro
            return d === 0 ? k + a : k + a / d;
        });

        console.log("Nouvelle liste :", result2);
    }
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
    }
    function calculateKDA(K, D, A) {
        if (K.length !== D.length || D.length !== A.length) {
            throw new Error("Les listes K, D et A doivent avoir la même taille !");
        }

        return K.map((k, index) => {
            const d = D[index];
            const a = A[index];

            // Éviter la division par zéro
            return d === 0 ? k + a : k + a / d;
        });
    }

    function createScale(data) {
        const min = Math.min(...data);
        const max = Math.max(...data);

        return function (value) {
            if (value < min) return 0; // En dessous de l'échelle
            if (value > max) return 100; // Au-dessus de l'échelle
            // Normalisation entre 0 et 100
            return ((value - min) / (max - min)) * 100;
        };
    }

    function calculateAverage(data) {
        if (data.length === 0) return 0; // Évite la division par zéro
        const sum = data.reduce((acc, val) => acc + val, 0);
        return sum / data.length;
    }

    const scaleFinalGPM = createScale(scaleGPM);
    const scaleFinalDPM = createScale(scaleDPM);
    const scaleFinalKP = createScale(scaleKP);
    const scaleFinalK = createScale(scaleK);
    const scaleFinalD = createScale(scaleD);
    const scaleFinalA = createScale(scaleA);
    const scaleFinalKDA = createScale(scaleKDA);

    const avgFinalGPM = calculateAverage(scaleGPM).toFixed(0);
    const avgFinalDPM = calculateAverage(scaleDPM).toFixed(0);
    const avgFinalKP = (calculateAverage(scaleKP) * 100).toFixed(0);
    const avgFinalK = calculateAverage(scaleK).toFixed(0);
    const avgFinalD = calculateAverage(scaleD).toFixed(0);
    const avgFinalA = calculateAverage(scaleA).toFixed(0);
    const avgFinalKDA = calculateAverage(scaleKDA);


    // Calculer le pourcentage sur l'échelle
    const percentageGPM = scaleFinalGPM(championDetails[0].gpm);
    const percentageDPM = scaleFinalDPM(championDetails[0].dpm);
    const percentageKP = scaleFinalKP(championDetails[0].kp / 100);
    const percentageKDA = scaleFinalKDA(championDetails[0].KDA);

    const totalWins = winrate.reduce((sum, value) => sum + value, 0);
    const winratePercentage = (totalWins / winrate.length) * 100;


    //Fréquence des items
    const frequencyMap = itemPlayer.reduce((acc, num) => {
        acc[num] = (acc[num] || 0) + 1;
        return acc;
    }, {});

    // Conversion en tableau et tri
    const sortedFrequency = Object.entries(frequencyMap)
        .map(([num, freq]) => ({ number: parseInt(num), frequency: freq }))
        .filter(item => item.number !== 0) // Exclure l'objet 0
        .sort((a, b) => b.frequency - a.frequency);




    const handleMouseEnter = (number) => {
        const itemName = yourFunction(number); // Appelle la fonction pour récupérer le name
        setTooltipContent(itemName); // Définit le contenu de la tooltip
        setTooltipVisible(number); // Affiche la tooltip
    };

    const handleMouseLeave = () => {
        setTooltipVisible(null); // Cache la tooltip
    };

    const yourFunction = (number) => {
        const item = itemData.data[number];
        if (item) {
            console.log(`Nom de l'item ${number}: ${item.name}`);
            return item.name;
        } else {
            console.log(`Aucun item trouvé pour le numéro ${number}`);
            return null;
        }
    };

    //RADAR ---------------------------------------------------------------------------------
    const scoreObjectif = Math.round(dragonPlayerF) + Math.round(hordePlayerF) + Math.round(heraldPlayerF) + Math.round(baronPlayerF) + Math.round(towerFirstPlayerF)
    const scoreObjectifSoloQ = Math.round(dragonPlayerFSQ) + Math.round(hordePlayerFSQ) + Math.round(heraldPlayerFSQ) + Math.round(baronPlayerFSQ) + Math.round(towerFirstPlayerFSQ)

    //DPM : championDetails[0].dpm
    //KP : championDetails[0].kp
    //GPM : championDetails[0].gpm
    //%Win : championDetails[0].winrate
    //Dshare : championDetails[0].dshare
    //VSPM : championDetails[0].vspm
    //OS : scoreObjectif
    //MeanDuration :     const dureeMoyenneS = ((duree / playerData.participants.length).toFixed(0));









    const normalizedData = {
        dpm: (championDetails[0].dpm / 1000).toFixed(2),
        kp: (championDetails[0].kp / 100).toFixed(2),
        gpm: (championDetails[0].gpm / 1000).toFixed(2),
        winrate: (championDetails[0].winrate / 100).toFixed(4),
        dshare: (championDetails[0].dshare),
        vspm: (championDetails[0].vspm),
        os: (scoreObjectif / 10).toFixed(2),
        meanDuration: (dureeMoyenneS / 2400).toFixed(2),
    };

    const normalizedDataSoloQ = {
        dpm: (avgFinalDPM / 1000).toFixed(2),
        kp: (avgFinalKP / 100).toFixed(2),
        gpm: (avgFinalGPM / 1000).toFixed(2),
        winrate: (winratePercentage / 100).toFixed(4),
        dshare: (dshareSoloQF / 1000).toFixed(2),
        vspm: (vspmSoloQF).toFixed(2),
        os: (scoreObjectifSoloQ / 10).toFixed(2),
        meanDuration: (dureeMoyenneSoloQS / 2000).toFixed(2),
    };








    // Données du graphique
    const dataRadar = {
        labels: ["DPM", "KP", "GPM", "%Win", "Dshare", "VSPM", "OS", "MeanDuration"],
        datasets: [
            {
                label: "Player",
                data: Object.values(normalizedData),
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 2,
            },
            {
                label: "SoloQ",
                data: Object.values(normalizedDataSoloQ),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 2,
            },
        ],
    };

    // Options du graphique
    const optionsRadar = {
        responsive: true,
        scales: {
            r: {
                angleLines: {
                    color: "gray", // Couleur des lignes angulaires
                },
                suggestedMin: 0, // Valeur minimale
                suggestedMax: 1, // Valeur maximale
                grid: {
                    color: "rgba(0, 0, 0, 0.1)", // Couleur de la grille
                },
                ticks: {
                    stepSize: 2, // Incréments des ticks
                    showLabelBackdrop: false, // Supprime le fond des labels
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.raw} points`,
                },
            },
        },
    };



    return (
        <div>
            <Navbar />
            <section className="home">
                <div className="Titre">
                    Deep player analysis - Champion details : {champion}
                </div>
                <div className="centerHome" style={{ flexDirection: "column" }}>

                    <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                        <div className='graphOk'>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center" }}>
                                <img src={images[`${champion}.png`]} alt='' style={{ display: "block", marginLeft: "10px" }} className='imgCha160' />
                                <div style={{ fontSize: "25px" }}>
                                    <div className='tooltip-container2'>
                                        <div>GPM : {championDetails[0].gpm} | {percentageGPM.toFixed(0)}%</div>
                                        <span className="tooltip-text2">Pour les GPM - Gold par minute, vous êtes meilleur que {percentageGPM.toFixed(0)}% des joueurs sur {champion}, la moyenne étant à {avgFinalGPM}</span>
                                    </div>

                                    <div className='tooltip-container2'>
                                        <div>DPM : {championDetails[0].dpm} | {percentageDPM.toFixed(0)}%</div>
                                        <span className="tooltip-text2">Pour les DPM - Dommage par minute, vous êtes meilleur que {percentageDPM.toFixed(0)}% des joueurs sur {champion}, la moyenne étant à {avgFinalDPM}</span>
                                    </div>

                                    <div className='tooltip-container2'>
                                        <div>KP% : {championDetails[0].kp} | {percentageKP.toFixed(0)}%</div>
                                        <span className="tooltip-text2">Pour la KP - Kill participation, vous êtes meilleur que {percentageKP.toFixed(0)}% des joueurs sur {champion}, la moyenne étant à {avgFinalKP}%</span>
                                    </div>
                                </div>
                                <div style={{ fontSize: "25px", textAlign: "center" }}>
                                    <div className='tooltip-container2'>
                                        <div>{championDetails[0].KDA} KDA</div>
                                        <span className="tooltip-text2">Pour le KDA - Kills + Assists / Morts, vous êtes meilleur que {percentageKDA.toFixed(0)}% des joueurs sur {champion}, la moyenne étant à {avgFinalKDA.toFixed(2)}</span>
                                    </div>
                                    <div>({championDetails[0].kills}/{championDetails[0].deaths}/{championDetails[0].assists})</div>
                                </div>
                                <div style={{ fontSize: "25px", marginRight: "10px", textAlign: "center" }}>
                                    <div className='tooltip-container2'>
                                        <div >{championDetails[0].winrate}%</div>
                                        <span className="tooltip-text2">Pour le taux de victoire,  la moyenne étant à {winratePercentage.toFixed(1)}% pour {champion} sur le dernier patch</span>
                                    </div>
                                    <div>{championDetails[0].games} games</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='tempnamess'>
                        <div className='graphOk'>
                            <div className='tooltip-container2'>
                                <div className='Titlebaza'>Radar des performances</div>
                                <span className="tooltip-text2">
                                    <div>Chaque variable est indépendante l'une de l'autre</div>
                                    <div></div>
                                    <div>DPM : Dommage par minute</div>
                                    <div>KP : Pourcentage de participation aux kills de l'équipe</div>
                                    <div>GPM : Gold par minute</div>
                                    <div>% Win : Pourcentage de victoire</div>
                                    <div>Dshare : Pourcentage des dégâts de l'équipe</div>
                                    <div>VSPM : Score de vision par minute</div>
                                    <div>OS : Score d'objectif (Nombre de hérald, dragons, tours, nashord, et grubs)</div>
                                    <div>MeanDuration : Durée moyenne des parties</div>
                                </span>
                            </div>
                            <div>

                                <Radar data={dataRadar} options={optionsRadar} />


                            </div>
                        </div>
                    </div>

                    <div className='tempnamess'>
                        <div className='graphOk'>
                            <div className='Titlebaza'>Winrate selon le patch</div>
                            {chartData && (
                                <Line
                                    data={chartData}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: "top",
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    label: (context) =>
                                                        `${context.raw.toFixed(2)}% de victoires`,
                                                },
                                            },
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div className='tempnamess'>
                        <div className='graphOk'>
                            <div className='Titlebaza'>Généralités sur le champion</div>
                            <div className='tooltip-container2'>
                                <div >Durée moyenne : {dureeMoyenne}</div>
                                <span className="tooltip-text2">La durée moyenne des parties de {champion} sur le dernier patch est de {dureeMoyenneSoloQ}</span>
                            </div>
                            <div className='tooltip-container2'>
                                <div >Nombre de dragon tués : {dragonPlayerF}</div>
                                <span className="tooltip-text2">En moyenne pour {champion} sur le dernier patch, le nombre moyen de dragon tués est de {dragonPlayerFSQ}</span>
                            </div>
                            <div className='tooltip-container2'>
                                <div >Nombre de grubs tués : {hordePlayerF}</div>
                                <span className="tooltip-text2">En moyenne pour {champion} sur le dernier patch, le nombre moyen de grubs tués est de {hordePlayerFSQ}</span>
                            </div>
                            <div className='tooltip-container2'>
                                <div >Nombre d'herald tués : {heraldPlayerF}</div>
                                <span className="tooltip-text2">En moyenne pour {champion} sur le dernier patch, le nombre moyen d'herald tués est de {heraldPlayerFSQ}</span>
                            </div>
                            <div className='tooltip-container2'>
                                <div >Nombre de baron nashor tués : {baronPlayerF}</div>
                                <span className="tooltip-text2">En moyenne pour {champion} sur le dernier patch, le nombre moyen de baron tués est de {baronPlayerFSQ}</span>
                            </div>
                            <div className='tooltip-container2'>
                                <div >Nombre de tours détruites : {towerPlayerF}</div>
                                <span className="tooltip-text2">En moyenne pour {champion} sur le dernier patch, le nombre moyen de tours détruites est de {towerPlayerFSQ}</span>
                            </div>
                            <div className='tooltip-container2'>
                                <div >Nombre d'inhibiteurs détruits : {inhibitorPlayerF}</div>
                                <span className="tooltip-text2">En moyenne pour {champion} sur le dernier patch, le nombre moyen d'inhibiteurs tués est de {inhibitorPlayerFSQ}</span>
                            </div>
                            <div className='tooltip-container2'>
                                <div >Nombre de premières tours détruites : {towerFirstPlayerF}</div>
                                <span className="tooltip-text2">En moyenne pour {champion} sur le dernier patch, le nombre premières tours détruites est de {towerFirstPlayerFSQ}</span>
                            </div>
                        </div>
                    </div>

                    <div className='tempnamess'>
                        <div className='graphOk'>
                            <div className='Titlebaza'>Détail des matchups</div>
                            {matchupDetails.map((element) => {
                                return (
                                    <CarteMatchup data={element} puuid={puuid} />
                                );
                            })}
                        </div>
                    </div>

                    <div className='tempnamess'>
                        <div className='graphOk'>
                            <div className='Titlebaza'>Item les plus récurrents</div>
                            <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: "center", textAlign: "center" }}>
                                {sortedFrequency.map((element) => {
                                    return (
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div className='tooltip-container'>
                                                <img src={imagesItems[`${element.number}.png`]} alt='' style={{ display: "block", marginLeft: "10px" }} className='imgCha' onMouseEnter={() => handleMouseEnter(element.number)} onMouseLeave={handleMouseLeave} />
                                                <span className="tooltip-text">{tooltipcontent}</span>
                                            </div>
                                            <div>{element.frequency}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='tempnamess'>
                        <div className='graphOk'>
                            <div className='Titlebaza'>Utilisation des spells</div>
                            <div style={{ display: "flex", justifyContent: 'space-around' }}>
                                <div style={{ textAlign: "center" }}>
                                    <img src={imageSpell[`${champion}Q.png`]} alt='' style={{ display: "block", marginLeft: "10px" }} className='imgCha' />
                                    {spell1Cast}
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <img src={imageSpell[`${champion}W.png`]} alt='' style={{ display: "block", marginLeft: "10px" }} className='imgCha' />
                                    {spell2Cast}
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <img src={imageSpell[`${champion}E.png`]} alt='' style={{ display: "block", marginLeft: "10px" }} className='imgCha' />
                                    {spell3Cast}
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <img src={imageSpell[`${champion}R.png`]} alt='' style={{ display: "block", marginLeft: "10px" }} className='imgCha' />
                                    {spell4Cast}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default DetailChampion;
