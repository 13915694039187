import React, { useEffect, useState } from "react";
import { Line, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

function Generalite({ puuid }) {
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState(null);
    const [laneData, setLaneData] = useState(null);
    const [laneStats, setLaneStats] = useState([]);
    const [winStats, setWinStats] = useState({});

    const fetchGames = (puuid) => {
        fetch(`https://lolprostat.com:8088/analyse/getWinratePatch?puuid=${puuid}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                processWinrateData(data.participants); // Traitement des données
                fetch(`https://lolprostat.com:8088/analyse/getLane?puuid=${puuid}`)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setLaneData(data.participants); // Traitement des données
                    })
                    .catch((error) => {
                        console.error("Erreur lors de la récupération des données :", error);
                    });
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération des données :", error);
            });
    };

    const processWinrateData = (participants) => {
        const simplifiedData = participants.map((p) => ({
            patch: p.Patch.split(".").slice(0, 2).join("."),
            win: p.win,
        }));

        const patchWinrates = simplifiedData.reduce((acc, { patch, win }) => {
            if (!acc[patch]) {
                acc[patch] = { wins: 0, total: 0 };
            }
            acc[patch].wins += win;
            acc[patch].total += 1;
            return acc;
        }, {});

        const sortedPatches = Object.keys(patchWinrates).sort((a, b) => {
            const [majorA, minorA] = a.split(".").map(Number);
            const [majorB, minorB] = b.split(".").map(Number);
            return majorA - majorB || minorA - minorB;
        });

        const labels = sortedPatches;
        const winrates = labels.map(
            (patch) => (patchWinrates[patch].wins / patchWinrates[patch].total) * 100
        );
        const winrate50 = new Array(labels.length).fill(50);

        setChartData({
            labels,
            datasets: [
                {
                    label: "Winrate (%)",
                    data: winrates,
                    borderColor: "rgba(75, 192, 192, 1)",
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                    tension: 0.4,
                },
                {
                    label: "50% Winrate",
                    data: winrate50,
                    borderColor: "red",  // Couleur de la ligne
                    borderWidth: 2,
                    borderDash: [5, 5],  // Style pointillé
                    fill: false,  // Ne pas remplir sous la ligne
                },
            ],
        });
        setLoading(false);
    };

    useEffect(() => {
        if (puuid) {
            fetchGames(puuid); // Charger les données
        }
    }, [puuid]);

    useEffect(() => {
        if (laneData) {
            const lanes = laneData.reduce((acc, { lane, win }) => {
                if (!acc[lane]) {
                    acc[lane] = { total: 0, wins: 0 };
                }
                acc[lane].total += 1;
                acc[lane].wins += win;
                return acc;
            }, {});

            // Calculer le total des participations
            const totalLanes = Object.values(lanes).reduce((total, lane) => total + lane.total, 0);

            // Normaliser les données de lanes en pourcentages
            const laneDataPrepared = Object.keys(lanes).map((lane) => ({
                label: lane,
                value: ((lanes[lane].total / totalLanes) * 100).toFixed(2), // Normalisation en %
            }));

            const winData = Object.keys(lanes).reduce((acc, lane) => {
                acc[lane] = ((lanes[lane].wins / lanes[lane].total) * 100).toFixed(2); // Calcul du taux de victoire en %
                return acc;
            }, {});

            setLaneStats(laneDataPrepared);
            setWinStats(winData);
        }
    }, [laneData]);

    if (loading) {
        return <div>Chargement...</div>;
    }

    // Données pour le graphique donut global des lanes
    const laneChartData = {
        labels: laneStats.map((item) => item.label),
        datasets: [
            {
                data: laneStats.map((item) => parseFloat(item.value)), // Convertir les valeurs en nombre
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0','#AAAAAA'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0','#AAAAAA'],
            },
        ],
    };

    

    return (
        <div className="centerHome">
            <div className='tempnamess'>
                <div className='graphOk'>
                    <div className='Titlebaza'>Winrate selon le patch</div>
                    {chartData && (
                        <Line
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: "top",
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: (context) =>
                                                `${context.raw.toFixed(2)}% de victoires`,
                                        },
                                    },
                                },
                            }}
                        />
                    )}
                </div>

                <div className='graphOk'>
                    <div className='Titlebaza'>Distribution des Lanes</div>
                    <div className="graphOk2">
                        {laneStats.length > 0 && (
                            <div className="sizeDonut">
                                <Doughnut
                                    data={laneChartData}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: "top",
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    label: (context) =>
                                                        `${context.raw}% de participations`,
                                                },
                                            },
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Graphiques des taux de victoire par lane */}
                <div className="graphOk">
                    <div className='Titlebaza'>Taux de victoire par Lane</div>
                    <div className="graphOk2">
                        {Object.keys(winStats).map((lane) => (
                            <div key={lane} className="lane-chart">
                                <h4 style={{ textAlign: "center" }}>{lane}</h4>
                                <Doughnut
                                    data={{
                                        labels: ['Victoire', 'Défaite'],
                                        datasets: [
                                            {
                                                data: [winStats[lane], 100 - winStats[lane]],
                                                backgroundColor: ['#36A2EB', '#FF6384'],
                                            },
                                        ],
                                    }}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: "top",
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    label: (context) => {
                                                        // Vérifier si context.raw est un nombre avant d'utiliser toFixed
                                                        const value = context.raw;
                                                        if (typeof value === 'number') {
                                                            return `${value.toFixed(2)}% de participations`;
                                                        } else {
                                                            return `${value}% de participations`; // Dans le cas où c'est déjà un pourcentage
                                                        }
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Generalite;
