import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import SpinnerAttente from '../components/SpinnerAttente';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import './EsportPlayer.css';

import Head from '../components/profile/Head';
import Game from '../components/profile/Game';

function EsportPlayer({ }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const name = queryParams.get('name');
    const [games, setData] = useState([]); // Liste des parties (games)
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [error, setError] = useState(null);


    const fetchGames = (startValue) => {
        setLoading(startValue === 0); // On affiche le spinner principal seulement lors de la première requête
        setLoadingMore(startValue > 0); // Spinner pour les autres requêtes
        fetch(`https://lolprostat.com:8088/player?player=${name}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log('Data fetched:', data); // Debug: afficher les données récupérées
                if (startValue === 0) {
                    setData(data); // Stocker les deux premiers éléments (contexte)
                } else {
                    setData(data); // Stocker les deux premiers éléments (contexte)
                }
                setLoading(false);
                setLoadingMore(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
                setLoadingMore(false);
            });
    };

    useEffect(() => {
        if (name) {
            fetchGames(0); // Charger les données initiales avec start = 0
        } else {
            setError("Les données du joueur sont manquantes.");
            setLoading(false);
        }
    }, [name]);

    console.log(games);

    if (loading) {
        return (
            <div>
                <Navbar />
                <section className="home">
                    <SpinnerAttente text="player" />
                </section>
            </div>
        );
    }

    if (error) {
        return <p>Erreur: {error}</p>;
    }

    let totalGames = games.participants.length;
    let wins = games.participants.filter(game => game.result === 1).length;
    let losses = totalGames - wins;
    let winrate = (wins / totalGames) * 100;

    let totalKills = 0;
    let totalAssists = 0;
    let totalDeaths = 0;
    let totalCS = 0;
    let totalGold = 0;
    let totalKillParticipation = 0;
    let totalGameLengthMinutes = 0;
    let totalKillsTeam = 0;
    let highestKDA = 0;
    let bestGame = null;
    let highestDPM = 0;
    let highestCSdiff = 0;
    let highestCSM = 0;
    let highestVSPM = 0;
    // Calcul des totaux
    games.participants.forEach(game => {
        let kills = game.kills;
        let assists = game.assists;
        let deaths = game.deaths;
        let totalcs = game.totalcs;
        let gamelengthMinutes = game.gamelength / 60;
        let totalgold = game.totalgold;
        let teamkills = game.teamkills;
        let kda;
        let gameid = game.gameid;
        let dpm = game.dpm;
        let csdiff = game.csdiffat15;
        let csm = game.cspm;
        let vspm = game.vspm;

        if(deaths===0){
            kda = kills + assists
        }
        else{
            kda = (kills + assists) / deaths;
        }
        
        if (kda > highestKDA) {
            highestKDA = kda;
        }

        if(dpm>highestDPM){
            highestDPM = dpm;
        }
        if(csdiff>highestCSdiff){
            highestCSdiff = csdiff;

        }

        if(csm>highestCSM){
            highestCSM = csm;

        }

        if(vspm>highestVSPM){
            highestVSPM = vspm;

        }

        totalKills += kills;
        totalAssists += assists;
        totalDeaths += deaths;
        totalCS += totalcs;
        totalGold += totalgold;
        totalGameLengthMinutes += gamelengthMinutes;
        totalKillsTeam += teamkills;


    });

    // Moyennes globales
    let averageKDA = (totalKills + totalAssists) / (totalDeaths === 0 ? 1 : totalDeaths);
    let averageCSPerMinute = totalCS / totalGameLengthMinutes;
    let averageGoldPerMinute = totalGold / totalGameLengthMinutes;
    let averageKillParticipation = (totalKills + totalAssists) / totalKillsTeam * 100;

    // Afficher les résultats globaux

    return (
        <div>
            <Navbar />
            <h1>Player Profile</h1>
            <section className="home">
                <div className='selectionTPSummary'>
                    <div className='Titre'>Player statistics : {name}</div>
                    <div className='blocprin' style={{ border: "red solid 1px" }}> {/*Encadré principal pour les 2 blocs*/}
                        <div style={{ border: "green solid 1px" }} className='gauche'>{/*Bloc de gauche  */}
                            <div>Hello</div>
                            <div className='stats'>{/*Bloc statistiques  */}
                                <div class="titreStats">Key statistics</div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "right", alignItems: "right", textAlign: "right", marginRight: "20px" }}>
                                        <div>Record track :</div>
                                        <div>Win rate :</div>
                                        <div>KDA :</div>
                                        <div>CS per minute :</div>
                                        <div>Gold per minute :</div>
                                        <div>Kill participation :</div>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "left", textAlign: "left", marginLeft: "20px" }}>
                                        <div>{wins} W - {losses} L</div>
                                        <div>{winrate.toFixed(1)}%</div>
                                        <div>{averageKDA.toFixed(2)}</div>
                                        <div>{averageCSPerMinute.toFixed(2)} CSM</div>
                                        <div>{averageGoldPerMinute.toFixed(0)} GPM</div>
                                        <div>{averageKillParticipation.toFixed(2)}% PK</div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div style={{ border: "green solid 1px" }} className='droite'>{/*Bloc de droite  */}
                            Caca
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default EsportPlayer;
