import React from 'react';
import { useNavigate } from 'react-router-dom';

function TimeLine({ data }) {
  const navigate = useNavigate();
  return (
    <div>DD</div>
  );
}

export default TimeLine;
