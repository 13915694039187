import React, { useState } from 'react';
import DetailChampion from '../../page/analyse/DetailChampion';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate

 // Assurez-vous d'importer le composant

const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
};
const images = importAll(require.context('../../img/champion', false, /\.(png|jpe?g|svg)$/));

function CarteChampion({ data , puuid}) {
    const navigate = useNavigate();
    const [showDetail, setShowDetail] = useState(false);

    const handleClick = () => {
        window.open(`/analyse/playerChampion?puuid=${puuid}&champion=${data.champion}`, '_blank');  // Afficher le détail lors du clic
    };

    return (
        <div>
            {showDetail ? (
                <DetailChampion />  // Affichage conditionnel de DetailChampion
            ) : (
                <div className='champList' onClick={handleClick}>
                    <div className='ChaC1'>
                        <img src={images[`${data.champion}.png`]} alt='' style={{ display: "block" }} className='imgCha' />
                    </div>
                    <div className='ChaC2'>
                        <div>GPM : {data.gpm}</div>
                        <div>DPM : {data.dpm}</div>
                        <div>KP% : {data.kp}</div>
                    </div>
                    <div className='ChaC3'>
                        <div style={{ paddingBlock: "5px" }}>{data.KDA} KDA</div>
                        <div>({data.kills}/{data.deaths}/{data.assists})</div>
                    </div>
                    <div className='ChaC4'>
                        <div style={{ paddingBlock: "5px", fontSize: "25px" }}>{data.winrate}%</div>
                        <div>{data.games} games</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CarteChampion;
