import React from 'react';
import { useNavigate } from 'react-router-dom';


function Bubble({ }) {
  const navigate = useNavigate();
  return (
    <div>
        var             Bubble
    </div>
  );
}

export default Bubble;
