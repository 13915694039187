import React from 'react';

function TableChamp({ data }) {
  return (
    <table id="myTable" className="tablesorter test neumorphic">
                        <thead>
                            <tr>
                                <th>Champion</th>
                                <th>Wins</th>
                                <th>Games_played</th>
                                <th>Winrate</th>
                                <th>Pickrate</th>
                                <th>LPS Score</th>

                            </tr>
                        </thead>
                        <tbody id="tableBody">
                            {Array.isArray(data) && data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.champ}</td>
                                        <td>{item.wins}</td>
                                        <td>{item.games_played}</td>
                                        <td>{item.winrate} %</td>
                                        <td>{item.pickrate} %</td>
                                        <td>{item['LPS Score']}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Loading data, please wait . . .</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
  );
}

export default TableChamp;
