import React from 'react';
import { HeatMapGrid } from "react-heatmap-grid";
import CarteChampion from '../../components/analyse/CarteChampion';
import CarteMatchup from '../../components/analyse/CarteMatchup';


function Champion({championSS, matchupSS, puuid }) {
    


    return (
        <div className='centerHome'>{/*Composant principale*/}
            <div className='hello95'>
                <div className='champListMain'>
                    <div className='Titlebaza'>Champion</div>
                    {championSS.map((element) => {
                        return (
                            <CarteChampion data={element} puuid={puuid}/>
                        );
                    })}
                </div>

                <div className='matchupListMain'>
                    <div className='Titlebaza'>Matchup</div>
                    {matchupSS.map((element) => {
                        return (
                            <CarteMatchup data={element} puuid={puuid}/>
                        );
                    })}
                </div>

            </div>
        </div>
    );
}

export default Champion;
