import React from 'react';
import { useNavigate } from 'react-router-dom';

function AllStats({ data }) {
    const game = JSON.parse(data['results1'][0].gameR);
    console.log('game', game)
    

    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }
    const images = importAll(require.context('../../img/champion', false, /\.(png|jpe?g|svg)$/));

    const tailleIconeChamp = 50;

    var ranking = [];
    ranking[0] = {
        name:"Player",
        i1:game[0]['participants'][0]['riotIdGameName'],
        i2:game[0]['participants'][1]['riotIdGameName'],
        i3:game[0]['participants'][2]['riotIdGameName'],
        i4:game[0]['participants'][3]['riotIdGameName'],
        i5:game[0]['participants'][4]['riotIdGameName'],
        i6:game[0]['participants'][5]['riotIdGameName'],
        i7:game[0]['participants'][6]['riotIdGameName'],
        i8:game[0]['participants'][7]['riotIdGameName'],
        i9:game[0]['participants'][8]['riotIdGameName'],
        i10:game[0]['participants'][9]['riotIdGameName'],
    };
    ranking[1] = {
        name:"Level",
        i1:game[0]['participants'][0]['champLevel'],
        i2:game[0]['participants'][1]['champLevel'],
        i3:game[0]['participants'][2]['champLevel'],
        i4:game[0]['participants'][3]['champLevel'],
        i5:game[0]['participants'][4]['champLevel'],
        i6:game[0]['participants'][5]['champLevel'],
        i7:game[0]['participants'][6]['champLevel'],
        i8:game[0]['participants'][7]['champLevel'],
        i9:game[0]['participants'][8]['champLevel'],
        i10:game[0]['participants'][9]['champLevel'],
    };

    var text = "kills"
    ranking[2] = {
        name:"Kills",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "deaths"
    ranking[3] = {
        name:"Deaths",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "assists"
    ranking[4] = {
        name:"Assists",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "kda"
    ranking[5] = {
        name: "KDA",
        i1: game[0]['participants'][0]['challenges'][text].toFixed(2),
        i2: game[0]['participants'][1]['challenges'][text].toFixed(2),
        i3: game[0]['participants'][2]['challenges'][text].toFixed(2),
        i4: game[0]['participants'][3]['challenges'][text].toFixed(2),
        i5: game[0]['participants'][4]['challenges'][text].toFixed(2),
        i6: game[0]['participants'][5]['challenges'][text].toFixed(2),
        i7: game[0]['participants'][6]['challenges'][text].toFixed(2),
        i8: game[0]['participants'][7]['challenges'][text].toFixed(2),
        i9: game[0]['participants'][8]['challenges'][text].toFixed(2),
        i10: game[0]['participants'][9]['challenges'][text].toFixed(2),
    };

    var text = "totalMinionsKilled"
    ranking[6] = {
        name:"CS",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    // var text = "kda"
    // ranking[7] = {
    //     name: "CSM",
    //     i1: game[0]['participants'][0]['challenges'][text].toFixed(2),
    //     i2: game[0]['participants'][1]['challenges'][text].toFixed(2),
    //     i3: game[0]['participants'][2]['challenges'][text].toFixed(2),
    //     i4: game[0]['participants'][3]['challenges'][text].toFixed(2),
    //     i5: game[0]['participants'][4]['challenges'][text].toFixed(2),
    //     i6: game[0]['participants'][5]['challenges'][text].toFixed(2),
    //     i7: game[0]['participants'][6]['challenges'][text].toFixed(2),
    //     i8: game[0]['participants'][7]['challenges'][text].toFixed(2),
    //     i9: game[0]['participants'][8]['challenges'][text].toFixed(2),
    //     i10: game[0]['participants'][9]['challenges'][text].toFixed(2),
    // };

    var text = "goldEarned"
    ranking[8] = {
        name:"Gold",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "goldPerMinute"
    ranking[9] = {
        name: "GPM",
        i1: game[0]['participants'][0]['challenges'][text].toFixed(2),
        i2: game[0]['participants'][1]['challenges'][text].toFixed(2),
        i3: game[0]['participants'][2]['challenges'][text].toFixed(2),
        i4: game[0]['participants'][3]['challenges'][text].toFixed(2),
        i5: game[0]['participants'][4]['challenges'][text].toFixed(2),
        i6: game[0]['participants'][5]['challenges'][text].toFixed(2),
        i7: game[0]['participants'][6]['challenges'][text].toFixed(2),
        i8: game[0]['participants'][7]['challenges'][text].toFixed(2),
        i9: game[0]['participants'][8]['challenges'][text].toFixed(2),
        i10: game[0]['participants'][9]['challenges'][text].toFixed(2),
    };

    var text = "visionScore"
    ranking[10] = {
        name:"Vision score",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "wardsPlaced"
    ranking[11] = {
        name:"Wards placed",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "wardsKilled"
    ranking[12] = {
        name:"Wards killed",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "controlWardsPlaced"
    ranking[13] = {
        name: "Control wards purchased",
        i1: game[0]['participants'][0]['challenges'][text],
        i2: game[0]['participants'][1]['challenges'][text],
        i3: game[0]['participants'][2]['challenges'][text],
        i4: game[0]['participants'][3]['challenges'][text],
        i5: game[0]['participants'][4]['challenges'][text],
        i6: game[0]['participants'][5]['challenges'][text],
        i7: game[0]['participants'][6]['challenges'][text],
        i8: game[0]['participants'][7]['challenges'][text],
        i9: game[0]['participants'][8]['challenges'][text],
        i10: game[0]['participants'][9]['challenges'][text],
    };

    var text = "detectorWardsPlaced"
    ranking[14] = {
        name:"Detector wards placed",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "visionScorePerMinute"
    ranking[15] = {
        name: "VSPM",
        i1: game[0]['participants'][0]['challenges'][text].toFixed(2),
        i2: game[0]['participants'][1]['challenges'][text].toFixed(2),
        i3: game[0]['participants'][2]['challenges'][text].toFixed(2),
        i4: game[0]['participants'][3]['challenges'][text].toFixed(2),
        i5: game[0]['participants'][4]['challenges'][text].toFixed(2),
        i6: game[0]['participants'][5]['challenges'][text].toFixed(2),
        i7: game[0]['participants'][6]['challenges'][text].toFixed(2),
        i8: game[0]['participants'][7]['challenges'][text].toFixed(2),
        i9: game[0]['participants'][8]['challenges'][text].toFixed(2),
        i10: game[0]['participants'][9]['challenges'][text].toFixed(2),
    };

    var text = "totalDamageDealtToChampions"
    ranking[16] = {
        name:"Total damage to Champion",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "physicalDamageDealtToChampions"
    ranking[17] = {
        name:"Physical damage",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "magicDamageDealtToChampions"
    ranking[18] = {
        name:"Magical damage",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "trueDamageDealtToChampions"
    ranking[19] = {
        name:"True damage",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "damagePerMinute"
    ranking[20] = {
        name: "DPM",
        i1: game[0]['participants'][0]['challenges'][text].toFixed(0),
        i2: game[0]['participants'][1]['challenges'][text].toFixed(0),
        i3: game[0]['participants'][2]['challenges'][text].toFixed(0),
        i4: game[0]['participants'][3]['challenges'][text].toFixed(0),
        i5: game[0]['participants'][4]['challenges'][text].toFixed(0),
        i6: game[0]['participants'][5]['challenges'][text].toFixed(0),
        i7: game[0]['participants'][6]['challenges'][text].toFixed(0),
        i8: game[0]['participants'][7]['challenges'][text].toFixed(0),
        i9: game[0]['participants'][8]['challenges'][text].toFixed(0),
        i10: game[0]['participants'][9]['challenges'][text].toFixed(0),
    };

    var text = "teamDamagePercentage"
    ranking[21] = {
        name: "DMG%",
        i1: (game[0]['participants'][0]['challenges'][text]*100).toFixed(1),
        i2: (game[0]['participants'][1]['challenges'][text]*100).toFixed(1),
        i3: (game[0]['participants'][2]['challenges'][text]*100).toFixed(1),
        i4: (game[0]['participants'][3]['challenges'][text]*100).toFixed(1),
        i5: (game[0]['participants'][4]['challenges'][text]*100).toFixed(1),
        i6: (game[0]['participants'][5]['challenges'][text]*100).toFixed(1),
        i7: (game[0]['participants'][6]['challenges'][text]*100).toFixed(1),
        i8: (game[0]['participants'][7]['challenges'][text]*100).toFixed(1),
        i9: (game[0]['participants'][8]['challenges'][text]*100).toFixed(1),
        i10: (game[0]['participants'][9]['challenges'][text]*100).toFixed(1),
    };

    var text = "killParticipation"
    ranking[22] = {
        name: "KP%",
        i1: (game[0]['participants'][0]['challenges'][text]*100).toFixed(1),
        i2: (game[0]['participants'][1]['challenges'][text]*100).toFixed(1),
        i3: (game[0]['participants'][2]['challenges'][text]*100).toFixed(1),
        i4: (game[0]['participants'][3]['challenges'][text]*100).toFixed(1),
        i5: (game[0]['participants'][4]['challenges'][text]*100).toFixed(1),
        i6: (game[0]['participants'][5]['challenges'][text]*100).toFixed(1),
        i7: (game[0]['participants'][6]['challenges'][text]*100).toFixed(1),
        i8: (game[0]['participants'][7]['challenges'][text]*100).toFixed(1),
        i9: (game[0]['participants'][8]['challenges'][text]*100).toFixed(1),
        i10: (game[0]['participants'][9]['challenges'][text]*100).toFixed(1),
    };

    var text = "soloKills"
    ranking[23] = {
        name: "Solo kills",
        i1: game[0]['participants'][0]['challenges'][text],
        i2: game[0]['participants'][1]['challenges'][text],
        i3: game[0]['participants'][2]['challenges'][text],
        i4: game[0]['participants'][3]['challenges'][text],
        i5: game[0]['participants'][4]['challenges'][text],
        i6: game[0]['participants'][5]['challenges'][text],
        i7: game[0]['participants'][6]['challenges'][text],
        i8: game[0]['participants'][7]['challenges'][text],
        i9: game[0]['participants'][8]['challenges'][text],
        i10: game[0]['participants'][9]['challenges'][text],
    };

    var text = "doubleKills"
    ranking[24] = {
        name:"Double kills",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "tripleKills"
    ranking[25] = {
        name:"Triple kills",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "quadraKills"
    ranking[26] = {
        name:"Quadra kills",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "pentaKills"
    ranking[27] = {
        name:"Penta kills",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "objectivesStolen"
    ranking[28] = {
        name:"Objectives stolen",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "damageDealtToTurrets"
    ranking[29] = {
        name:"Damage dealt to turrets",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "damageDealtToBuildings"
    ranking[30] = {
        name:"Damage dealt to buildings",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "totalHeal"
    ranking[31] = {
        name:"Total heal",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "totalHealsOnTeammates"
    ranking[32] = {
        name:"Total heal on teammates",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "damageSelfMitigated"
    ranking[33] = {
        name:"Damage self mitigated",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "totalDamageShieldedOnTeammates"
    ranking[34] = {
        name:"Total damage shielded on teammates",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "timeCCingOthers"
    ranking[35] = {
        name:"Time CCing others",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "totalTimeCCDealt"
    ranking[36] = {
        name:"Total time CC dealt",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "totalDamageTaken"
    ranking[37] = {
        name:"Total damage taken",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "totalTimeSpentDead"
    ranking[38] = {
        name:"Total time spent dead",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "consumablesPurchased"
    ranking[39] = {
        name:"Consumables purchased",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };

    var text = "itemsPurchased"
    ranking[40] = {
        name:"Items purchased",
        i1:game[0]['participants'][0][text],
        i2:game[0]['participants'][1][text],
        i3:game[0]['participants'][2][text],
        i4:game[0]['participants'][3][text],
        i5:game[0]['participants'][4][text],
        i6:game[0]['participants'][5][text],
        i7:game[0]['participants'][6][text],
        i8:game[0]['participants'][7][text],
        i9:game[0]['participants'][8][text],
        i10:game[0]['participants'][9][text],
    };


    


    console.log('ranking', ranking)

    return (
        // Une partie overview
        <div className="debutEncadre95Centre">
            <table id="myTable" className="tablesorter test neumorphic">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th><img src={images[`${game[0]['participants'][0]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][1]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][2]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][3]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][4]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][5]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][6]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][7]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][8]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                        <th><img src={images[`${game[0]['participants'][9]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></th>
                    </tr>
                </thead>
                <tbody id="tableBody">
                    {Array.isArray(ranking) && ranking.length > 0 ? (
                        ranking.map((item, index) => (
                            <tr key={index}>
                                <td style={{fontWeight:"600"}}>{item.name}</td>
                                <td>{item.i1}</td>
                                <td>{item.i2}</td>
                                <td>{item.i3}</td>
                                <td>{item.i4}</td>
                                <td>{item.i5}</td>
                                <td>{item.i6}</td>
                                <td>{item.i7}</td>
                                <td>{item.i8}</td>
                                <td>{item.i9}</td>
                                <td>{item.i10}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="11">Loading data, please wait . . .</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        //Une partie scoreboard

        //Gold graph & Timeline

        //Plates
    );
}

export default AllStats;
