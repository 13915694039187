import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import SpinnerAttente from '../../components/SpinnerAttente';
import '../../style/titre.css'
import '../../style/composant.css'
import '../../style/encadre.css'

import SummarySolo from './SummarySolo';



function AnalysePartie({ href, iconClass, text }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const id = queryParams.get('id');
    const region = queryParams.get('region');

    const [gameData, setGameData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (id && region) {
            // Effectuer la requête fetch
            fetch(`https://lolprostat.com:8088/game?id=${id}&region=${region}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Data fetched:', data);  // Debug: afficher les données récupérées
                    setGameData(data);  // Met à jour l'état avec les données reçues
                    setLoading(false);
                })
                .catch(error => {
                    setError(error.message);
                    setLoading(false);
                });
        } else {
            setError("Les données du joueur sont manquantes.");
            setLoading(false);
        }
    }, [id, region]);

    if (loading) {
        return (
            <div>
                <Navbar />
                <section className="home">
                    <SpinnerAttente />
                </section>
            </div>
        );
    }

    if (error) {
        return <p>Erreur: {error}</p>;
    }

    if (!gameData) {
        return <p>Pas de données disponibles pour ce joueur.</p>;
    }

    return (
        <div>
      <Navbar />
      <h1>Match details</h1>
      <section className="home">
        <SummarySolo data={gameData}/>
      </section>
    </div>
    );
}

export default AnalysePartie;
