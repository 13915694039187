import React from 'react';

function TableChampFrequent({ data }) {
  return (
    <table id="myTable" className="tablesorter test neumorphic">
                        <thead>
                            <tr>
                                <th>Champion</th>
                                <th>Opponent champion</th>
                                <th>Number of played</th>

                            </tr>
                        </thead>
                        <tbody id="tableBody">
                            {Array.isArray(data) && data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.champ}</td>
                                        <td>{item.opposantChamp}</td>
                                        <td>{item.count}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Loading data, please wait . . .</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
  );
}

export default TableChampFrequent;
