import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, Title } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, Title);

const HbarREAL = ({ label, data1, data2, options, info }) => {
  const chartRef = useRef(); // Create a reference for the chart

  const handleDownload = () => {
    const chartContainer = chartRef.current;
    html2canvas(chartContainer).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'chart.png';  // Set the download file name
      link.href = canvas.toDataURL();  // Convert the canvas to a data URL
      link.click();  // Trigger the download
    });
  };

  const chartData = {
    labels: label,
    datasets: (() => {
      const nameCat = options['nameCat'];
      let dataset = [];

      if (nameCat === 'Side' || label.length === 2) {
        dataset = [
          {
            label: options['nameDataTemp'][0],
            data: data1,
            backgroundColor: data1.map((_, index) =>
              index < 1 ? 'rgba(54, 162, 235, 0.7)' : 'rgba(255, 99, 132, 0.7)'
            ),
            borderColor: data1.map((_, index) =>
              index < 1 ? 'rgba(54, 162, 235, 1)' : 'rgba(255, 99, 132, 1)'
            ),
            borderWidth: 1,
          },
        ];
      } else {
        dataset = [
          {
            label: options['nameDataTemp'][0],
            data: data1,
            backgroundColor: 'rgba(255, 99, 132, 0.7)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ];
      }

      return dataset;
    })(),
  };

  const chartOptions = {
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          display: options['displayXAxisTitle'],
          text: options['xAxisTitle']
        },
      },
      y: {
        title: {
          display: options['displayYAxisTitle'],
          text: options['yAxisTitle']
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        display: options['displayLegend'],
      },
      title: {
        display: options['title'] !== '',
        text: options['title'],
        font: {
          size: 18,
        },
      },
    },
  };

  return (
    <div style={{ width: '800px', height: '400px' }} ref={chartRef}>
      <Bar data={chartData} options={chartOptions} />
      <button onClick={handleDownload} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' }}>
        Download as Image
      </button>
    </div>
  );
};

export default HbarREAL;
