import React, { useState, useEffect, useRef } from 'react';
import HbarREAL from './real/HbarREAL'; // Importation de votre composant HbarREAL
import { elements } from 'chart.js';
import html2canvas from 'html2canvas';

function Hbar({ data }) {
  const game = JSON.parse(data['results1'][0].gameR);
  const gameTimeline = JSON.parse(data['results1'][0].gameTimeLine);
  const info = data['results2'][0];

  console.log('Game', game);
  //console.log('GameTimeLine', gameTimeline);
  // console.log('info', info);

  //Définition des useState pour les options du graphique.
  const [title, setTitle] = useState('');
  const [xAxisTitle, setXAxisTitle] = useState('');
  const [displayXAxisTitle, setDisplayXAxisTitle] = useState(true);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [displayYAxisTitle, setDisplayYAxisTitle] = useState(true);
  const [displayLegend, setDisplayLegend] = useState(true);

  const [valueRech, setvalueRech] = useState('');
  const [valueRechCat, setvalueRechCat] = useState('');




  // Définition des catégories :
  const LaneT1 = ['TOP', 'JUNGLE', 'MID', 'ADC', 'SUPPORT'];
  const LaneT2 = ['TOP', 'JUNGLE', 'MID', 'ADC', 'SUPPORT'];
  const Side = ['Blue - ' + info['Blue'], 'Red - ' + info['Red']];
  const PlayerT1 = [game[0]['participants'][0]['riotIdGameName'], game[0]['participants'][1]['riotIdGameName'], game[0]['participants'][2]['riotIdGameName'], game[0]['participants'][3]['riotIdGameName'], game[0]['participants'][4]['riotIdGameName']];
  const PlayerT2 = [game[0]['participants'][5]['riotIdGameName'], game[0]['participants'][6]['riotIdGameName'], game[0]['participants'][7]['riotIdGameName'], game[0]['participants'][8]['riotIdGameName'], game[0]['participants'][9]['riotIdGameName']];
  const PlayerGlobal = [game[0]['participants'][0]['riotIdGameName'], game[0]['participants'][1]['riotIdGameName'], game[0]['participants'][2]['riotIdGameName'], game[0]['participants'][3]['riotIdGameName'], game[0]['participants'][4]['riotIdGameName'], game[0]['participants'][5]['riotIdGameName'], game[0]['participants'][6]['riotIdGameName'], game[0]['participants'][7]['riotIdGameName'], game[0]['participants'][8]['riotIdGameName'], game[0]['participants'][9]['riotIdGameName']];
  const T = ['TOP - ' + game[0]['participants'][0]['riotIdGameName'], 'TOP - ' + game[0]['participants'][5]['riotIdGameName']];
  const J = ['JUNGLE - ' + game[0]['participants'][1]['riotIdGameName'], 'JUNGLE - ' + game[0]['participants'][6]['riotIdGameName']];
  const M = ['MID - ' + game[0]['participants'][2]['riotIdGameName'], 'MID - ' + game[0]['participants'][7]['riotIdGameName']];
  const A = ['ADC - ' + game[0]['participants'][3]['riotIdGameName'], 'ADC - ' + game[0]['participants'][8]['riotIdGameName']];
  const S = ['SUPPORT - ' + game[0]['participants'][4]['riotIdGameName'], 'SUPPORT - ' + game[0]['participants'][9]['riotIdGameName']];

  //Faire les autres lanes ici

  const categoryNameMapping = {
    LaneT1: 'Lane - ' + info['Blue'],
    LaneT2: 'Lane - ' + info['Red'],
    Side: 'Side',
    PlayerT1: 'Players - ' + info['Blue'],
    PlayerT2: 'Players - ' + info['Red'],
    PlayerGlobal: 'All Players',
    T: 'Top - ' + game[0]['participants'][0]['riotIdGameName'] + ' vs ' + game[0]['participants'][5]['riotIdGameName'],
    J: 'Jungle - ' + game[0]['participants'][1]['riotIdGameName'] + ' vs ' + game[0]['participants'][6]['riotIdGameName'],
    M: 'Mid - ' + game[0]['participants'][2]['riotIdGameName'] + ' vs ' + game[0]['participants'][7]['riotIdGameName'],
    A: 'Adc - ' + game[0]['participants'][3]['riotIdGameName'] + ' vs ' + game[0]['participants'][8]['riotIdGameName'],
    S: 'Support - ' + game[0]['participants'][4]['riotIdGameName'] + ' vs ' + game[0]['participants'][9]['riotIdGameName'],
  };

  var categoryAvailable = ['Side', 'LaneT1', 'LaneT2', 'PlayerT1', 'PlayerT2', 'PlayerGlobal', 'T', 'J', 'M', 'A', 'S'];

  const categoryLabelMapping = {
    LaneT1: LaneT1,
    LaneT2: LaneT2,
    Side: Side,
    PlayerT1: PlayerT1,
    PlayerT2: PlayerT2,
    PlayerGlobal: PlayerGlobal,
    T: T,
    J: J,
    M: M,
    A: A,
    S: S,
  };

  var dataAvailable = [];

  const categoryDataMapping = {};
  categoryDataMapping['LaneT1'] = {}
  categoryDataMapping['LaneT2'] = {}
  categoryDataMapping['Side'] = {}
  categoryDataMapping['PlayerT1'] = {}
  categoryDataMapping['PlayerT2'] = {}
  categoryDataMapping['PlayerGlobal'] = {}
  categoryDataMapping['T'] = {}
  categoryDataMapping['J'] = {}
  categoryDataMapping['M'] = {}
  categoryDataMapping['A'] = {}
  categoryDataMapping['S'] = {}

  const categoryDataNameMapping = {};


  //Définition des données :

  //--------------------------- Fonction pour générer selon une timeline, selon un instant T ---------------------------//

  //Modifier la fonction pour prendre aussi le nom en paramètre comme ça on peut générer plein de truc. Et on traduit avec un tableau au début pour passer de gold => totalGold
  const generateGoldData = (time) => {
    if (gameTimeline[0] && gameTimeline[0]['frames'] && gameTimeline[0]['frames'][time]) {
      let datatemp = {};
      const frame = gameTimeline[0].frames[time].participantFrames;
      datatemp[`goldAt${time}LaneT1`] = [];
      datatemp[`goldAt${time}LaneT2`] = [];
      datatemp[`goldAt${time}PlayerGlobal`] = [];
      datatemp[`goldAt${time}Side`] = [0, 0];
      datatemp[`goldAt${time}PlayerT1`] = [];
      datatemp[`goldAt${time}PlayerT2`] = [];
      datatemp[`goldAt${time}T`] = [frame[1]['totalGold'], frame[6]['totalGold']];
      datatemp[`goldAt${time}J`] = [frame[2]['totalGold'], frame[7]['totalGold']];
      datatemp[`goldAt${time}M`] = [frame[3]['totalGold'], frame[8]['totalGold']];
      datatemp[`goldAt${time}A`] = [frame[4]['totalGold'], frame[9]['totalGold']];
      datatemp[`goldAt${time}S`] = [frame[5]['totalGold'], frame[10]['totalGold']];

      for (let i = 1; i < 6; i++) {
        datatemp[`goldAt${time}LaneT1`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['totalGold']);
        datatemp[`goldAt${time}PlayerGlobal`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['totalGold']);
        datatemp[`goldAt${time}Side`][0] += gameTimeline[0]['frames'][time]['participantFrames'][i]['totalGold'];
      }
      for (let i = 6; i < 11; i++) {
        datatemp[`goldAt${time}LaneT2`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['totalGold']);
        datatemp[`goldAt${time}PlayerGlobal`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['totalGold']);
        datatemp[`goldAt${time}Side`][1] += gameTimeline[0]['frames'][time]['participantFrames'][i]['totalGold'];
      }
      datatemp[`goldAt${time}PlayerT1`] = datatemp[`goldAt${time}LaneT1`]
      datatemp[`goldAt${time}PlayerT2`] = datatemp[`goldAt${time}LaneT2`]

      dataAvailable.push(`goldAt${time}`);

      if (datatemp[`goldAt${time}LaneT1`]) {
        categoryDataNameMapping[`goldAt${time}`] = `Gold at ${time}min`;
      }

      if (datatemp[`goldAt${time}LaneT1`]) {
        categoryDataMapping['LaneT1'][`goldAt${time}`] = datatemp[`goldAt${time}LaneT1`];
        categoryDataMapping['LaneT2'][`goldAt${time}`] = datatemp[`goldAt${time}LaneT2`];
        categoryDataMapping['Side'][`goldAt${time}`] = datatemp[`goldAt${time}Side`];
        categoryDataMapping['PlayerT1'][`goldAt${time}`] = datatemp[`goldAt${time}PlayerT1`];
        categoryDataMapping['PlayerT2'][`goldAt${time}`] = datatemp[`goldAt${time}PlayerT2`];
        categoryDataMapping['PlayerGlobal'][`goldAt${time}`] = datatemp[`goldAt${time}PlayerGlobal`];
        categoryDataMapping['T'][`goldAt${time}`] = datatemp[`goldAt${time}T`];
        categoryDataMapping['J'][`goldAt${time}`] = datatemp[`goldAt${time}J`];
        categoryDataMapping['M'][`goldAt${time}`] = datatemp[`goldAt${time}M`];
        categoryDataMapping['A'][`goldAt${time}`] = datatemp[`goldAt${time}A`];
        categoryDataMapping['S'][`goldAt${time}`] = datatemp[`goldAt${time}S`];
      }

      return datatemp


    } else {
      return null
    }

  }












  const generateTimelyDataTEMPORAIRE = (name, time) => {
    var name2;
    var labelaaffiche;
    if (name === "gold") { name2 = 'totalGold'; labelaaffiche = "Total gold" }
    if (name === "minions") { name2 = 'minionsKilled'; labelaaffiche = "Minions killed" }
    if (name === "xp") { name2 = 'xp'; labelaaffiche = "XP" }
    if (name === "timeEnemySpentControlled") { name2 = 'timeEnemySpentControlled'; labelaaffiche = "Time enemy spent controlled" }
    if (name === "level") { name2 = 'level'; labelaaffiche = "Level" }
    if (name === "jungleMinionsKilled") { name2 = 'jungleMinionsKilled'; labelaaffiche = "Jungle minions killed" }

    if (name === "magicDamageDone") { name2 = 'magicDamageDone'; labelaaffiche = "Magic damage done" }
    if (name === "magicDamageDoneToChampions") { name2 = 'magicDamageDoneToChampions'; labelaaffiche = "Magic damage done to champions" }
    if (name === "magicDamageTaken") { name2 = 'magicDamageTaken'; labelaaffiche = "Magic damage taken" }

    if (name === "physicalDamageDone") { name2 = 'physicalDamageDone'; labelaaffiche = "Physical damage done" }
    if (name === "physicalDamageDoneToChampions") { name2 = 'physicalDamageDoneToChampions'; labelaaffiche = "Physical damage done to champions" }
    if (name === "physicalDamageTaken") { name2 = 'physicalDamageTaken'; labelaaffiche = "Physical damage taken" }

    if (name === "totalDamageDone") { name2 = 'totalDamageDone'; labelaaffiche = "Total damage done" }
    if (name === "totalDamageDoneToChampions") { name2 = 'totalDamageDoneToChampions'; labelaaffiche = "Total damage done to champions" }
    if (name === "totalDamageTaken") { name2 = 'totalDamageTaken'; labelaaffiche = "Total damage taken" }

    if (name === "trueDamageDone") { name2 = 'trueDamageDone'; labelaaffiche = "True damage done" }
    if (name === "trueDamageDoneToChampions") { name2 = 'trueDamageDoneToChampions'; labelaaffiche = "True damage done to champions" }
    if (name === "trueDamageTaken") { name2 = 'trueDamageTaken'; labelaaffiche = "True damage taken" }

    if (gameTimeline[0] && gameTimeline[0]['frames'] && gameTimeline[0]['frames'][time]) {

      let datatemp = {};
      const frame = gameTimeline[0].frames[time].participantFrames;
      datatemp[`${name}At${time}LaneT1`] = [];
      datatemp[`${name}At${time}LaneT2`] = [];
      datatemp[`${name}At${time}PlayerGlobal`] = [];
      datatemp[`${name}At${time}Side`] = [0, 0];
      datatemp[`${name}At${time}PlayerT1`] = [];
      datatemp[`${name}At${time}PlayerT2`] = [];
      datatemp[`${name}At${time}T`] = [frame[1][name2], frame[6][name2]];
      datatemp[`${name}At${time}J`] = [frame[2][name2], frame[7][name2]];
      datatemp[`${name}At${time}M`] = [frame[3][name2], frame[8][name2]];
      datatemp[`${name}At${time}A`] = [frame[4][name2], frame[9][name2]];
      datatemp[`${name}At${time}S`] = [frame[5][name2], frame[10][name2]];



      for (let i = 1; i < 6; i++) {
        datatemp[`${name}At${time}LaneT1`].push(gameTimeline[0]['frames'][time]['participantFrames'][i][name2]);
        datatemp[`${name}At${time}PlayerGlobal`].push(gameTimeline[0]['frames'][time]['participantFrames'][i][name2]);
        datatemp[`${name}At${time}Side`][0] += gameTimeline[0]['frames'][time]['participantFrames'][i][name2];
      }
      for (let i = 6; i < 11; i++) {
        datatemp[`${name}At${time}LaneT2`].push(gameTimeline[0]['frames'][time]['participantFrames'][i][name2]);
        datatemp[`${name}At${time}PlayerGlobal`].push(gameTimeline[0]['frames'][time]['participantFrames'][i][name2]);
        datatemp[`${name}At${time}Side`][1] += gameTimeline[0]['frames'][time]['participantFrames'][i][name2];
      }
      datatemp[`${name}At${time}PlayerT1`] = datatemp[`${name}At${time}LaneT1`]
      datatemp[`${name}At${time}PlayerT2`] = datatemp[`${name}At${time}LaneT2`]

      dataAvailable.push(`${name}At${time}`);


      if (datatemp[`${name}At${time}LaneT1`]) {
        categoryDataNameMapping[`${name}At${time}`] = `${labelaaffiche} at ${time}min`;

      }

      if (datatemp[`${name}At${time}LaneT1`]) {
        categoryDataMapping['LaneT1'][`${name}At${time}`] = datatemp[`${name}At${time}LaneT1`];
        categoryDataMapping['LaneT2'][`${name}At${time}`] = datatemp[`${name}At${time}LaneT2`];
        categoryDataMapping['Side'][`${name}At${time}`] = datatemp[`${name}At${time}Side`];
        categoryDataMapping['PlayerT1'][`${name}At${time}`] = datatemp[`${name}At${time}PlayerT1`];
        categoryDataMapping['PlayerT2'][`${name}At${time}`] = datatemp[`${name}At${time}PlayerT2`];
        categoryDataMapping['PlayerGlobal'][`${name}At${time}`] = datatemp[`${name}At${time}PlayerGlobal`];
        categoryDataMapping['T'][`${name}At${time}`] = datatemp[`${name}At${time}T`];
        categoryDataMapping['J'][`${name}At${time}`] = datatemp[`${name}At${time}J`];
        categoryDataMapping['M'][`${name}At${time}`] = datatemp[`${name}At${time}M`];
        categoryDataMapping['A'][`${name}At${time}`] = datatemp[`${name}At${time}A`];
        categoryDataMapping['S'][`${name}At${time}`] = datatemp[`${name}At${time}S`];
      }

      return datatemp


    } else {
      return null
    }

  }

  const generateTimelyDataDamage = (name, time) => {
    var name2;
    var labelaaffiche;
    if (name === "gold") { name2 = 'totalGold'; labelaaffiche = "Total gold" }
    if (name === "minions") { name2 = 'minionsKilled'; labelaaffiche = "Minions killed" }
    if (name === "xp") { name2 = 'xp'; labelaaffiche = "XP" }
    if (name === "timeEnemySpentControlled") { name2 = 'timeEnemySpentControlled'; labelaaffiche = "Time enemy spent controlled" }
    if (name === "level") { name2 = 'level'; labelaaffiche = "Level" }
    if (name === "jungleMinionsKilled") { name2 = 'jungleMinionsKilled'; labelaaffiche = "Jungle minions killed" }

    if (name === "magicDamageDone") { name2 = 'magicDamageDone'; labelaaffiche = "Magic damage done" }
    if (name === "magicDamageDoneToChampions") { name2 = 'magicDamageDoneToChampions'; labelaaffiche = "Magic damage done to champions" }
    if (name === "magicDamageTaken") { name2 = 'magicDamageTaken'; labelaaffiche = "Magic damage taken" }

    if (name === "physicalDamageDone") { name2 = 'physicalDamageDone'; labelaaffiche = "Physical damage done" }
    if (name === "physicalDamageDoneToChampions") { name2 = 'physicalDamageDoneToChampions'; labelaaffiche = "Physical damage done to champions" }
    if (name === "physicalDamageTaken") { name2 = 'physicalDamageTaken'; labelaaffiche = "Physical damage taken" }

    if (name === "totalDamageDone") { name2 = 'totalDamageDone'; labelaaffiche = "Total damage done" }
    if (name === "totalDamageDoneToChampions") { name2 = 'totalDamageDoneToChampions'; labelaaffiche = "Total damage done to champions" }
    if (name === "totalDamageTaken") { name2 = 'totalDamageTaken'; labelaaffiche = "Total damage taken" }

    if (name === "trueDamageDone") { name2 = 'trueDamageDone'; labelaaffiche = "True damage done" }
    if (name === "trueDamageDoneToChampions") { name2 = 'trueDamageDoneToChampions'; labelaaffiche = "True damage done to champions" }
    if (name === "trueDamageTaken") { name2 = 'trueDamageTaken'; labelaaffiche = "True damage taken" }

    if (gameTimeline[0] && gameTimeline[0]['frames'] && gameTimeline[0]['frames'][time]) {

      let datatemp = {};
      const frame = gameTimeline[0].frames[time].participantFrames;
      datatemp[`${name}At${time}LaneT1`] = [];
      datatemp[`${name}At${time}LaneT2`] = [];
      datatemp[`${name}At${time}PlayerGlobal`] = [];
      datatemp[`${name}At${time}Side`] = [0, 0];
      datatemp[`${name}At${time}PlayerT1`] = [];
      datatemp[`${name}At${time}PlayerT2`] = [];
      datatemp[`${name}At${time}T`] = [frame[1]['damageStats'][name2], frame[6]['damageStats'][name2]];
      datatemp[`${name}At${time}J`] = [frame[2]['damageStats'][name2], frame[7]['damageStats'][name2]];
      datatemp[`${name}At${time}M`] = [frame[3]['damageStats'][name2], frame[8]['damageStats'][name2]];
      datatemp[`${name}At${time}A`] = [frame[4]['damageStats'][name2], frame[9]['damageStats'][name2]];
      datatemp[`${name}At${time}S`] = [frame[5]['damageStats'][name2], frame[10]['damageStats'][name2]];



      for (let i = 1; i < 6; i++) {
        datatemp[`${name}At${time}LaneT1`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['damageStats'][name2]);
        datatemp[`${name}At${time}PlayerGlobal`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['damageStats'][name2]);
        datatemp[`${name}At${time}Side`][0] += gameTimeline[0]['frames'][time]['participantFrames'][i]['damageStats'][name2];
      }
      for (let i = 6; i < 11; i++) {
        datatemp[`${name}At${time}LaneT2`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['damageStats'][name2]);
        datatemp[`${name}At${time}PlayerGlobal`].push(gameTimeline[0]['frames'][time]['participantFrames'][i]['damageStats'][name2]);
        datatemp[`${name}At${time}Side`][1] += gameTimeline[0]['frames'][time]['participantFrames'][i]['damageStats'][name2];
      }
      datatemp[`${name}At${time}PlayerT1`] = datatemp[`${name}At${time}LaneT1`]
      datatemp[`${name}At${time}PlayerT2`] = datatemp[`${name}At${time}LaneT2`]

      dataAvailable.push(`${name}At${time}`);


      if (datatemp[`${name}At${time}LaneT1`]) {
        categoryDataNameMapping[`${name}At${time}`] = `${labelaaffiche} at ${time}min`;

      }

      if (datatemp[`${name}At${time}LaneT1`]) {
        categoryDataMapping['LaneT1'][`${name}At${time}`] = datatemp[`${name}At${time}LaneT1`];
        categoryDataMapping['LaneT2'][`${name}At${time}`] = datatemp[`${name}At${time}LaneT2`];
        categoryDataMapping['Side'][`${name}At${time}`] = datatemp[`${name}At${time}Side`];
        categoryDataMapping['PlayerT1'][`${name}At${time}`] = datatemp[`${name}At${time}PlayerT1`];
        categoryDataMapping['PlayerT2'][`${name}At${time}`] = datatemp[`${name}At${time}PlayerT2`];
        categoryDataMapping['PlayerGlobal'][`${name}At${time}`] = datatemp[`${name}At${time}PlayerGlobal`];
        categoryDataMapping['T'][`${name}At${time}`] = datatemp[`${name}At${time}T`];
        categoryDataMapping['J'][`${name}At${time}`] = datatemp[`${name}At${time}J`];
        categoryDataMapping['M'][`${name}At${time}`] = datatemp[`${name}At${time}M`];
        categoryDataMapping['A'][`${name}At${time}`] = datatemp[`${name}At${time}A`];
        categoryDataMapping['S'][`${name}At${time}`] = datatemp[`${name}At${time}S`];
      }

      return datatemp


    } else {
      return null
    }

  }

  const generateData = (name) => {
    var name2;
    var labelaaffiche;
    if (name === "allInPings") { name2 = 'allInPings'; labelaaffiche = "Ping - all In" }
    if (name === "assistMePings") { name2 = 'assistMePings'; labelaaffiche = "Ping - assist me" }
    if (name === "assists") { name2 = 'assists'; labelaaffiche = "Assists" }
    if (name === "baronKills") { name2 = 'baronKills'; labelaaffiche = "Nashor killed" }
    if (name === "basicPings") { name2 = 'basicPings'; labelaaffiche = "Ping - basic" }
    if (name === "champExperience") { name2 = 'champExperience'; labelaaffiche = "XP - End" }
    if (name === "champLevel") { name2 = 'champLevel'; labelaaffiche = "Level - End" }
    if (name === "commandPings") { name2 = 'commandPings'; labelaaffiche = "Ping - command" }
    if (name === "consumablesPurchased") { name2 = 'consumablesPurchased'; labelaaffiche = "Consumables purchased" }
    if (name === "damageDealtToBuildings") { name2 = 'damageDealtToBuildings'; labelaaffiche = "Damage dealt to buildings" }
    if (name === "damageDealtToObjectives") { name2 = 'damageDealtToObjectives'; labelaaffiche = "Damage dealt to objectives" }
    if (name === "damageDealtToTurrets") { name2 = 'damageDealtToTurrets'; labelaaffiche = "Damage dealt to turrets" }
    if (name === "damageSelfMitigated") { name2 = 'damageSelfMitigated'; labelaaffiche = "Damage self mitigated" }
    if (name === "dangerPings") { name2 = 'dangerPings'; labelaaffiche = "Ping - danger" }
    if (name === "deaths") { name2 = 'deaths'; labelaaffiche = "Deaths" }
    if (name === "detectorWardsPlaced") { name2 = 'detectorWardsPlaced'; labelaaffiche = "Detector wards placed" }
    if (name === "doubleKills") { name2 = 'doubleKills'; labelaaffiche = "Double kill" }
    if (name === "dragonKills") { name2 = 'dragonKills'; labelaaffiche = "Dragon kill" }
    if (name === "enemyMissingPings") { name2 = 'enemyMissingPings'; labelaaffiche = "Ping - enemy is missing" }
    if (name === "enemyVisionPings") { name2 = 'enemyVisionPings'; labelaaffiche = "Ping - enemy has vision" }
    if (name === "getBackPings") { name2 = 'getBackPings'; labelaaffiche = "Ping - get back" }
    if (name === "getBackPings") { name2 = 'getBackPings'; labelaaffiche = "Ping - get back" }
    if (name === "goldEarned") { name2 = 'goldEarned'; labelaaffiche = "Gold earned - End" }
    if (name === "goldSpent") { name2 = 'goldSpent'; labelaaffiche = "Gold spent - End" }
    if (name === "holdPings") { name2 = 'holdPings'; labelaaffiche = "Ping - hold" }
    if (name === "inhibitorKills") { name2 = 'inhibitorKills'; labelaaffiche = "Inhibitor kills" }
    if (name === "itemsPurchased") { name2 = 'itemsPurchased'; labelaaffiche = "Item purchased" }
    if (name === "killingSprees") { name2 = 'killingSprees'; labelaaffiche = "Killing spree" }
    if (name === "kills") { name2 = 'kills'; labelaaffiche = "Kills" }
    if (name === "magicDamageDealt") { name2 = 'magicDamageDealt'; labelaaffiche = "Magic damage done at End" }
    if (name === "magicDamageDealtToChampions") { name2 = 'magicDamageDealtToChampions'; labelaaffiche = "Magic damage done to champions at End" }
    if (name === "magicDamageTaken") { name2 = 'magicDamageTaken'; labelaaffiche = "Magic damage taken at End" }
    if (name === "needVisionPings") { name2 = 'needVisionPings'; labelaaffiche = "Ping - need vision" }
    if (name === "neutralMinionsKilled") { name2 = 'neutralMinionsKilled'; labelaaffiche = "Neutral minions killed" }
    if (name === "objectivesStolen") { name2 = 'objectivesStolen'; labelaaffiche = "Objective stolen" }
    if (name === "onMyWayPings") { name2 = 'onMyWayPings'; labelaaffiche = "Ping - on my way" }
    if (name === "pentaKills") { name2 = 'pentaKills'; labelaaffiche = "Pentakills" }
    if (name === "physicalDamageDealt") { name2 = 'physicalDamageDealt'; labelaaffiche = "Physical damage done at End" }
    if (name === "physicalDamageDealtToChampions") { name2 = 'physicalDamageDealtToChampions'; labelaaffiche = "Physical damage done to champions at End" }
    if (name === "physicalDamageTaken") { name2 = 'physicalDamageTaken'; labelaaffiche = "Physical damage taken at End" }
    if (name === "pushPings") { name2 = 'pushPings'; labelaaffiche = "Ping - push" }
    if (name === "quadraKills") { name2 = 'quadraKills'; labelaaffiche = "Quadrakills" }
    if (name === "sightWardsBoughtInGame") { name2 = 'sightWardsBoughtInGame'; labelaaffiche = "Sight wards bought" }
    if (name === "spell1Casts") { name2 = 'spell1Casts'; labelaaffiche = "Spell 1 cast" }
    if (name === "spell2Casts") { name2 = 'spell2Casts'; labelaaffiche = "Spell 2 cast" }
    if (name === "spell3Casts") { name2 = 'spell3Casts'; labelaaffiche = "Spell 3 cast" }
    if (name === "spell4Casts") { name2 = 'spell4Casts'; labelaaffiche = "Spell 4 cast" }
    if (name === "summoner1Casts") { name2 = 'summoner1Casts'; labelaaffiche = "Summoner 1 cast" }
    if (name === "summoner2Casts") { name2 = 'summoner2Casts'; labelaaffiche = "Summoner 2 cast" }
    if (name === "timeCCingOthers") { name2 = 'timeCCingOthers'; labelaaffiche = "Time CCing others" }
    if (name === "totalAllyJungleMinionsKilled") { name2 = 'totalAllyJungleMinionsKilled'; labelaaffiche = "Total ally jungle minions killed" }
    if (name === "totalDamageDealt") { name2 = 'totalDamageDealt'; labelaaffiche = "Total damage done at End" }
    if (name === "totalDamageDealtToChampions") { name2 = 'totalDamageDealtToChampions'; labelaaffiche = "Total damage done to champions at End" }
    if (name === "totalDamageShieldedOnTeammates") { name2 = 'totalDamageShieldedOnTeammates'; labelaaffiche = "Total shield on teammates" }
    if (name === "totalDamageTaken") { name2 = 'totalDamageTaken'; labelaaffiche = "Total damage taken at End" }
    if (name === "totalEnemyJungleMinionsKilled") { name2 = 'totalEnemyJungleMinionsKilled'; labelaaffiche = "Total enemy jungle minions killed" }
    if (name === "totalHeal") { name2 = 'totalHeal'; labelaaffiche = "Total heal" }
    if (name === "totalHealsOnTeammates") { name2 = 'getBackPings'; labelaaffiche = "Total heal on teammate" }
    if (name === "totalMinionsKilled") { name2 = 'totalMinionsKilled'; labelaaffiche = "Minions killed at End" }
    if (name === "totalTimeCCDealt") { name2 = 'totalTimeCCDealt'; labelaaffiche = "Time CC dealt" }
    if (name === "totalTimeSpentDead") { name2 = 'totalTimeSpentDead'; labelaaffiche = "Time spent dead" }
    if (name === "tripleKills") { name2 = 'tripleKills'; labelaaffiche = "Triplekills" }
    if (name === "trueDamageDealt") { name2 = 'trueDamageDealt'; labelaaffiche = "True damage done at End" }
    if (name === "trueDamageDealtToChampions") { name2 = 'trueDamageDealtToChampions'; labelaaffiche = "True damage done to champions at End" }
    if (name === "trueDamageTaken") { name2 = 'trueDamageTaken'; labelaaffiche = "True damage taken at End" }
    if (name === "turretKills") { name2 = 'turretKills'; labelaaffiche = "Turret kills" }
    if (name === "turretTakedowns") { name2 = 'turretTakedowns'; labelaaffiche = "Turret takedowns" }
    if (name === "turretsLost") { name2 = 'turretsLost'; labelaaffiche = "Turret lost" }
    if (name === "visionClearedPings") { name2 = 'visionClearedPings'; labelaaffiche = "Ping - vision cleared" }
    if (name === "visionScore") { name2 = 'visionScore'; labelaaffiche = "Vision score" }
    if (name === "visionWardsBoughtInGame") { name2 = 'visionWardsBoughtInGame'; labelaaffiche = "Vision ward bought" }
    if (name === "wardsKilled") { name2 = 'wardsKilled'; labelaaffiche = "Wards killed" }
    if (name === "wardsPlaced") { name2 = 'wardsPlaced'; labelaaffiche = "Wards placed" }


    let datatemp = {};
    datatemp[`${name}LaneT1`] = [];
    datatemp[`${name}LaneT2`] = [];
    datatemp[`${name}PlayerGlobal`] = [];
    datatemp[`${name}Side`] = [0, 0];
    datatemp[`${name}PlayerT1`] = [];
    datatemp[`${name}PlayerT2`] = [];
    datatemp[`${name}T`] = [game[0]['participants'][0][name2], game[0]['participants'][5][name2]];
    datatemp[`${name}J`] = [game[0]['participants'][1][name2], game[0]['participants'][6][name2]];
    datatemp[`${name}M`] = [game[0]['participants'][2][name2], game[0]['participants'][7][name2]];
    datatemp[`${name}A`] = [game[0]['participants'][3][name2], game[0]['participants'][8][name2]];
    datatemp[`${name}S`] = [game[0]['participants'][4][name2], game[0]['participants'][9][name2]];



    for (let i = 0; i < 5; i++) {
      datatemp[`${name}LaneT1`].push(game[0]['participants'][i][name2]);
      datatemp[`${name}PlayerGlobal`].push(game[0]['participants'][i][name2]);
      datatemp[`${name}Side`][0] += game[0]['participants'][i][name2];
    }
    for (let i = 5; i < 10; i++) {
      datatemp[`${name}LaneT2`].push(game[0]['participants'][i][name2]);
      datatemp[`${name}PlayerGlobal`].push(game[0]['participants'][i][name2]);
      datatemp[`${name}Side`][1] += game[0]['participants'][i][name2];
    }
    datatemp[`${name}PlayerT1`] = datatemp[`${name}LaneT1`]
    datatemp[`${name}PlayerT2`] = datatemp[`${name}LaneT2`]

    dataAvailable.push(`${name}`);


    if (datatemp[`${name}LaneT1`]) {
      categoryDataNameMapping[`${name}`] = `${labelaaffiche}`;

    }

    if (datatemp[`${name}LaneT1`]) {
      categoryDataMapping['LaneT1'][`${name}`] = datatemp[`${name}LaneT1`];
      categoryDataMapping['LaneT2'][`${name}`] = datatemp[`${name}LaneT2`];
      categoryDataMapping['Side'][`${name}`] = datatemp[`${name}Side`];
      categoryDataMapping['PlayerT1'][`${name}`] = datatemp[`${name}PlayerT1`];
      categoryDataMapping['PlayerT2'][`${name}`] = datatemp[`${name}PlayerT2`];
      categoryDataMapping['PlayerGlobal'][`${name}`] = datatemp[`${name}PlayerGlobal`];
      categoryDataMapping['T'][`${name}`] = datatemp[`${name}T`];
      categoryDataMapping['J'][`${name}`] = datatemp[`${name}J`];
      categoryDataMapping['M'][`${name}`] = datatemp[`${name}M`];
      categoryDataMapping['A'][`${name}`] = datatemp[`${name}A`];
      categoryDataMapping['S'][`${name}`] = datatemp[`${name}S`];
    }

    return datatemp




  }
  const generateDataChallenge = (name) => {
    var name2;
    var labelaaffiche;
    if (name === "abilityUses") { name2 = 'abilityUses'; labelaaffiche = "Ability used" }
    if (name === "buffsStolen") { name2 = 'buffsStolen'; labelaaffiche = "Buff stolen" }
    if (name === "controlWardsPlaced") { name2 = 'controlWardsPlaced'; labelaaffiche = "Control ward placed" }
    if (name === "damagePerMinute") { name2 = 'damagePerMinute'; labelaaffiche = "Damage per minute (DPM)" }
    if (name === "goldPerMinute") { name2 = 'goldPerMinute'; labelaaffiche = "Gold per minute (GPM)" }
    if (name === "maxCsAdvantageOnLaneOpponent") { name2 = 'maxCsAdvantageOnLaneOpponent'; labelaaffiche = "Max CS advantage on opponent" }
    if (name === "maxLevelLeadLaneOpponent") { name2 = 'maxLevelLeadLaneOpponent'; labelaaffiche = "Max Level advantage on opponent" }
    if (name === "soloKills") { name2 = 'soloKills'; labelaaffiche = "Solokill" }
    if (name === "visionScorePerMinute") { name2 = 'visionScorePerMinute'; labelaaffiche = "Vision score per minute (VSPM)" }





    let datatemp = {};
    datatemp[`${name}LaneT1`] = [];
    datatemp[`${name}LaneT2`] = [];
    datatemp[`${name}PlayerGlobal`] = [];
    datatemp[`${name}Side`] = [0, 0];
    datatemp[`${name}PlayerT1`] = [];
    datatemp[`${name}PlayerT2`] = [];
    datatemp[`${name}T`] = [game[0]['participants'][0]['challenges'][name2], game[0]['participants'][5]['challenges'][name2]];
    datatemp[`${name}J`] = [game[0]['participants'][1]['challenges'][name2], game[0]['participants'][6]['challenges'][name2]];
    datatemp[`${name}M`] = [game[0]['participants'][2]['challenges'][name2], game[0]['participants'][7]['challenges'][name2]];
    datatemp[`${name}A`] = [game[0]['participants'][3]['challenges'][name2], game[0]['participants'][8]['challenges'][name2]];
    datatemp[`${name}S`] = [game[0]['participants'][4]['challenges'][name2], game[0]['participants'][9]['challenges'][name2]];



    for (let i = 0; i < 5; i++) {
      datatemp[`${name}LaneT1`].push(game[0]['participants'][i]['challenges'][name2]);
      datatemp[`${name}PlayerGlobal`].push(game[0]['participants'][i]['challenges'][name2]);
      datatemp[`${name}Side`][0] += game[0]['participants'][i]['challenges'][name2];
    }
    for (let i = 5; i < 10; i++) {
      datatemp[`${name}LaneT2`].push(game[0]['participants'][i]['challenges'][name2]);
      datatemp[`${name}PlayerGlobal`].push(game[0]['participants'][i]['challenges'][name2]);
      datatemp[`${name}Side`][1] += game[0]['participants'][i]['challenges'][name2];
    }
    datatemp[`${name}PlayerT1`] = datatemp[`${name}LaneT1`]
    datatemp[`${name}PlayerT2`] = datatemp[`${name}LaneT2`]

    dataAvailable.push(`${name}`);


    if (datatemp[`${name}LaneT1`]) {
      categoryDataNameMapping[`${name}`] = `${labelaaffiche}`;

    }

    if (datatemp[`${name}LaneT1`]) {
      categoryDataMapping['LaneT1'][`${name}`] = datatemp[`${name}LaneT1`];
      categoryDataMapping['LaneT2'][`${name}`] = datatemp[`${name}LaneT2`];
      categoryDataMapping['Side'][`${name}`] = datatemp[`${name}Side`];
      categoryDataMapping['PlayerT1'][`${name}`] = datatemp[`${name}PlayerT1`];
      categoryDataMapping['PlayerT2'][`${name}`] = datatemp[`${name}PlayerT2`];
      categoryDataMapping['PlayerGlobal'][`${name}`] = datatemp[`${name}PlayerGlobal`];
      categoryDataMapping['T'][`${name}`] = datatemp[`${name}T`];
      categoryDataMapping['J'][`${name}`] = datatemp[`${name}J`];
      categoryDataMapping['M'][`${name}`] = datatemp[`${name}M`];
      categoryDataMapping['A'][`${name}`] = datatemp[`${name}A`];
      categoryDataMapping['S'][`${name}`] = datatemp[`${name}S`];
    }
    return datatemp
  }
  // const nomdestimeline = ["gold","minions","xp","timeEnemySpentControlled","level","jungleMinionsKilled","magicDamageDone","magicDamageDoneToChampions","magicDamageTaken", "physicalDamageDone","physicalDamageDoneToChampions", "physicalDamageTaken","totalDamageDone","totalDamageDoneToChampions","totalDamageTaken","trueDamageDone","trueDamageDoneToChampions","trueDamageTaken"];


  const nomdestimeline = ["gold", "minions", "xp", "timeEnemySpentControlled", "level", "jungleMinionsKilled"];
  const nomtimelineDmg = ["magicDamageDone", "magicDamageDoneToChampions", "magicDamageTaken", "physicalDamageDone", "physicalDamageDoneToChampions", "physicalDamageTaken", "totalDamageDone", "totalDamageDoneToChampions", "totalDamageTaken", "trueDamageDone", "trueDamageDoneToChampions", "trueDamageTaken"]
  const timeTime = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]

  for (let nom of nomdestimeline) {
    for (let time of timeTime) {
      generateTimelyDataTEMPORAIRE(nom, time);
    }
  }

  for (let nom of nomtimelineDmg) {
    for (let time of timeTime) {
      generateTimelyDataDamage(nom, time);
    }
  }

  const nomencore = [
    "allInPings",
    "assistMePings",
    "assists",
    "baronKills",
    "basicPings",
    "champExperience",
    "champLevel",
    "commandPings",
    "consumablesPurchased",
    "damageDealtToBuildings",
    "damageDealtToObjectives",
    "damageDealtToTurrets",
    "damageSelfMitigated",
    "dangerPings",
    "deaths",
    "detectorWardsPlaced",
    "doubleKills",
    "dragonKills",
    "enemyMissingPings",
    "enemyVisionPings",
    "getBackPings",
    "goldEarned",
    "goldSpent",
    "holdPings",
    "inhibitorKills",
    "itemsPurchased",
    "killingSprees",
    "kills",
    "magicDamageDealt",
    "magicDamageDealtToChampions",
    "magicDamageTaken",
    "needVisionPings",
    "neutralMinionsKilled",
    "objectivesStolen",
    "onMyWayPings",
    "pentaKills",
    "physicalDamageDealt",
    "physicalDamageDealtToChampions",
    "physicalDamageTaken",
    "pushPings",
    "quadraKills",
    "sightWardsBoughtInGame",
    "spell1Casts",
    "spell2Casts",
    "spell3Casts",
    "spell4Casts",
    "summoner1Casts",
    "summoner2Casts",
    "timeCCingOthers",
    "totalAllyJungleMinionsKilled",
    "totalDamageDealt",
    "totalDamageDealtToChampions",
    "totalDamageShieldedOnTeammates",
    "totalDamageTaken",
    "totalEnemyJungleMinionsKilled",
    "totalHeal",
    "totalHealsOnTeammates",
    "totalMinionsKilled",
    "totalTimeCCDealt",
    "totalTimeSpentDead",
    "tripleKills",
    "trueDamageDealt",
    "trueDamageDealtToChampions",
    "trueDamageTaken",
    "turretKills",
    "turretTakedowns",
    "turretsLost",
    "visionClearedPings",
    "visionScore",
    "visionWardsBoughtInGame",
    "wardsKilled",
    "wardsPlaced"
  ];

  for (let nom of nomencore) {
    generateData(nom);
  }

  const nomencoreCha = [
    "abilityUses",
    "buffsStolen",
    "controlWardsPlaced",
    "damagePerMinute",
    "goldPerMinute",
    "maxCsAdvantageOnLaneOpponent",
    "maxLevelLeadLaneOpponent",
    "soloKills",
    "visionScorePerMinute"
  ];

  for (let nom of nomencoreCha) {
    generateDataChallenge(nom);
  }


  // État du composant
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataCategory, setSelectedDataCategory] = useState([]);
  const [displayData, setDisplayData] = useState(null);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [nameData, setNameData] = useState([]);
  const [nameCat, setCatName] = useState("");


  function getLabel(key) {
    return categoryDataNameMapping[key] || 'Label not found'; // Retourne un message par défaut si la clé n'existe pas
  }

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedData(prevSelectedData => [...prevSelectedData, value]);
    } else {
      setSelectedData(prevSelectedData => prevSelectedData.filter(item => item !== value));
    }
  };

  const getCheckedItems = () => {
    // Obtenir les éléments cochés
    const checkedItems = selectedData;
    // Log les éléments cochés
    // console.log('Checked items:', checkedItems);
    // Décocher les éléments en les supprimant de `selectedData`
    setSelectedData([]);
  };

  const Decocher = () => {
    //Décocher les éléments cocher

    setSelectedData([]);

  }



  // Fonction pour mettre à jour le contenu HTML


  // setNameData(selectedData);


  useEffect(() => {
  }, [nameCat]);

  const handleCheckboxChangeCategory = (event) => {
    const value = event.target.value;
    let txttemp = "";

    if (value === "LaneT1") {
      txttemp = 'Lane - ' + info['Blue'];
    } else if (value === "LaneT2") {
      txttemp = 'Lane - ' + info['Red'];
    } else if (value === "Side") {
      txttemp = 'Side';
    } else if (value === "PlayerT1") {
      txttemp = 'Players - ' + info['Blue'];
    } else if (value === "PlayerT2") {
      txttemp = 'Players - ' + info['Red'];
    } else if (value === "PlayerGlobal") {
      txttemp = 'All Players';
    } else if (value === "T") {
      txttemp = 'Top - ' + game[0]['participants'][0]['riotIdGameName'] + ' vs ' + game[0]['participants'][5]['riotIdGameName'];
    } else if (value === "J") {
      txttemp = 'Jungle - ' + game[0]['participants'][1]['riotIdGameName'] + ' vs ' + game[0]['participants'][6]['riotIdGameName'];
    } else if (value === "M") {
      txttemp = 'Mid - ' + game[0]['participants'][2]['riotIdGameName'] + ' vs ' + game[0]['participants'][7]['riotIdGameName'];
    } else if (value === "A") {
      txttemp = 'Adc - ' + game[0]['participants'][3]['riotIdGameName'] + ' vs ' + game[0]['participants'][8]['riotIdGameName'];
    } else if (value === "S") {
      txttemp = 'Support - ' + game[0]['participants'][4]['riotIdGameName'] + ' vs ' + game[0]['participants'][9]['riotIdGameName'];
    }

    setCatName(txttemp);

    setSelectedDataCategory(event.target.checked ? [value] : []);  // Only one category can be selected
  };



  const handleDisplay = () => {
    setIsDisplayed(true);

    if (selectedDataCategory.length > 1) {
      alert('Select only one category');
      return; // Arrête l'exécution de la fonction si une erreur est détectée
    }

    // Détermine la catégorie sélectionnée et les données associées
    const selectedCategory = selectedDataCategory[0];
    const selectedCategoryData = categoryDataMapping[selectedCategory] || {};
    const categoryLabels = categoryLabelMapping[selectedCategory] || [];

    const nameDataTemp = [];
    for (let i = 0; i < selectedData.length; i++) {
      nameDataTemp[i] = getLabel(selectedData[i])
    }

    const dataOptions = {
      title,
      xAxisTitle,
      displayXAxisTitle,
      yAxisTitle,
      displayYAxisTitle,
      displayLegend,
      nameCat,
      nameDataTemp,
    };

    // Détermine les données à afficher
    const displayData = {
      selectedCategories: selectedCategory ? [selectedCategory] : [],
      selectedData1: selectedData.length > 0 ? selectedCategoryData[selectedData[0]] : [],
      selectedData2: selectedData.length > 1 ? selectedCategoryData[selectedData[1]] : null,
      labels: categoryLabels,
      options: dataOptions, // Passe les labels pour l'axe X
    };



    setDisplayData(displayData);
  };


  //Fonction qui regarde si valueRech est inclut dans un des éléments de categoryNameMapping

  const fonctionTest = (valueRech) => {
    // Convertir valueRech en minuscules pour une recherche insensible à la casse
    const lowerValueRech = valueRech.toLowerCase();

    // Filtrage des clés basées sur les valeurs associées
    const filteredKeys = Object.keys(categoryDataNameMapping)
      .filter(key => categoryDataNameMapping[key].toLowerCase().includes(lowerValueRech));

    // Création du nouveau mapping
    const newMapping = filteredKeys.reduce((acc, key) => {
      acc[key] = categoryDataNameMapping[key];
      return acc;
    }, {});

    // Mise à jour de dataAvailable pour ne garder que les éléments filtrés
    dataAvailable = dataAvailable.filter(key => filteredKeys.includes(key));

    // Retourner le nouveau mapping pour l'utiliser à l'extérieur
    return newMapping;
  };

  const fonctionTest2 = (valueRechCat) => {
    const lowerValueRechCat = valueRechCat.toLowerCase();
    const filteredKeys = Object.keys(categoryNameMapping)
      .filter(key => categoryNameMapping[key].toLowerCase().includes(lowerValueRechCat))

    const newMapping = filteredKeys.reduce((acc, key) => {
      acc[key] = categoryNameMapping[key];
      return acc;
    }, {});

    categoryAvailable = categoryAvailable.filter(key => filteredKeys.includes(key));
  };

  var newMapping2 = fonctionTest(valueRech);
  var newMapping3 = fonctionTest2(valueRechCat);



  //fonctionTest();



  return (
    <div className='chartsSelection' style={{ flexDirection: "column" }}> {/* Conteneur Principal */}
      <div className='interieur90' style={{ flexDirection: "row", border: "#92aaee  solid 1px" }}>
        {/* Affichage des checkbox */}
        <div style={{ border: "#92aaee  solid 1px", borderRadius: "0.5rem", flexDirection: 'row', width: "350px", padding: "10px", backgroundColor: "rgb(195 207 240)" }}>
          <div style={{ fontWeight: "500", fontSize: "28px", textAlign: "center", marginBottom: "10px" }}>X- Category</div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}><input type='text' value={valueRechCat} onChange={(e) => setvalueRechCat(e.target.value)} className='input400' /></div>
          <div style={{ border: "#92aaee  solid 1px", borderRadius: "0.5rem", maxHeight: '240px', overflowY: 'auto', padding: "5px", backgroundColor: "rgb(176 192 238)" }} className='limit'>
            {categoryAvailable.map((dataItem) => (
              <div key={dataItem}>
                <label>
                  <input
                    type="radio"
                    value={dataItem}
                    checked={selectedDataCategory.includes(dataItem)}
                    onChange={handleCheckboxChangeCategory}
                  />
                  {categoryNameMapping[dataItem]} {/* Affiche le nom explicite */}
                </label>
              </div>

            ))}
          </div>
        </div>

        <div style={{ border: "#92aaee  solid 1px", borderRadius: "0.5rem", flexDirection: 'row', width: "350px", padding: "10px", backgroundColor: "rgb(195 207 240)" }}>
          <div style={{ fontWeight: "500", fontSize: "28px", textAlign: "center", marginBottom: "10px" }}>Y - Data</div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}><input type='text' value={valueRech} onChange={(e) => setvalueRech(e.target.value)} className='input400' /></div>
          <div style={{ border: "#92aaee  solid 1px", borderRadius: "0.5rem", maxHeight: '240px', overflowY: 'auto', padding: "5px", backgroundColor: "rgb(176 192 238)" }} className='limit'>
            {dataAvailable.map((dataItem) => (
              <div key={dataItem}>
                <label>
                  <input
                    type="checkbox"
                    value={dataItem}
                    checked={selectedData.includes(dataItem)}
                    onChange={handleCheckboxChange}
                  />
                  {categoryDataNameMapping[dataItem]}
                </label>
              </div>
            ))}
          </div>

        </div>


        <div style={{ border: "#92aaee  solid 1px", borderRadius: "0.5rem", flexDirection: 'row', width: "350px", padding: "10px", backgroundColor: "rgb(195 207 240)" }}>
          <div style={{ fontWeight: "500", fontSize: "28px", textAlign: "center", marginBottom: "10px" }}>Settings</div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
              <div style={{ fontWeight: "500" }}>Chart Title</div>
              <div style={{ display: "flex", justifyContent: "center" }}><input type='text' value={title} onChange={(e) => setTitle(e.target.value)} className='input400' /></div>
            </div>


            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontWeight: "500" }}>Title axis X</div>
                <div style={{ display: "flex", justifyContent: "center" }}><input type='text' value={xAxisTitle} onChange={(e) => setXAxisTitle(e.target.value)} className='input400' /></div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <div style={{ fontWeight: "500" }}>Display title axis X</div>
                  <div><input type='checkbox' checked={displayXAxisTitle} onChange={(e) => setDisplayXAxisTitle(e.target.checked)} style={{ marginLeft: "15px" }} /></div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontWeight: "500" }}>Title axis Y</div>
                <div style={{ display: "flex", justifyContent: "center" }}><input type='text' value={xAxisTitle} onChange={(e) => setYAxisTitle(e.target.value)} className='input400' /></div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontWeight: "500" }}>Display title axis Y</div>
                <div><input type='checkbox' checked={displayXAxisTitle} onChange={(e) => setDisplayYAxisTitle(e.target.checked)} style={{ marginLeft: "15px" }} /></div>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <div style={{ fontWeight: "500" }}>Display Legend</div>
              <input type='checkbox' checked={displayLegend} onChange={(e) => setDisplayLegend(e.target.checked)} style={{ marginLeft: "15px" }} />
            </div>
          </div>

        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center", border: "#92aaee  solid 1px", borderRadius: "0.5rem", padding: "10px", backgroundColor: "rgb(195 207 240)" }}>
          <button onClick={handleDisplay} className='input600' style={{ fontWeight: "500", fontSize: "20px" }}>Display</button> {/* Bouton qui déclenche l'affichage */}
          <button onClick={getCheckedItems} className='input600'>Unchecked data</button> {/* Enlève tout ce qui est coché */}
        </div>


      </div>

      {/* Affichage conditionnel du composant HbarREAL */}
      {isDisplayed && displayData && displayData.selectedCategories.length > 0 && (
        <div style={{ border: "#92aaee  solid 1px", borderRadius: "0.5rem", padding: "10px", backgroundColor: "#d2dcf8", marginTop: "30px", marginBottom: "30px" }}>

          <HbarREAL
            // label={displayData.selectedCategories[0]} 
            data1={displayData.selectedData1}
            data2={displayData.selectedData2} // Passe les données correctement mappées
            label={displayData.labels}
            options={displayData.options}
            info={info}
          // Passe les labels pour l'axe X
          />

        </div>

      )}
    </div>
  );
}

export default Hbar;
