import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate


const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
};
const images = importAll(require.context('../../img/champion', false, /\.(png|jpe?g|svg)$/));

function separerParVs(chaine) {
    // On sépare la chaîne en utilisant "vs" comme séparateur
    let elements = chaine.split(' vs ');
    
    // On retourne un tableau contenant les deux éléments
    return elements;
}



function CarteMatchup({ data, puuid }) {

    const navigate = useNavigate();
    const handleClick = () => {
        window.open(`/analyse/playerMatchup?puuid=${puuid}&champion=${separerParVs(data.matchup)[0]}&matchup=${separerParVs(data.matchup)[1]}`, '_blank');  // Afficher le détail lors du clic
    };

    return (
        <div className='champList' onClick={handleClick}>{/*Bloc champion liste*/}
            <div className='ChaC1'><img src={images[`${separerParVs(data.matchup)[0]}.png`]} alt='' style={{ display: "block" }} className='imgCha' /></div>
            <div>x</div>
            <div className='ChaC1'><img src={images[`${separerParVs(data.matchup)[1]}.png`]} alt='' style={{ display: "block" }} className='imgCha' /></div>
            <div className='ChaC2'>
                <div>GPM : {data.gpm}</div>
                <div>DPM : {data.dpm}</div>
                <div>KP% : {data.kp}</div>
            </div>
            <div className='ChaC3'>
                <div style={{ paddingBlock: "5px" }}>{data.KDA} KDA</div>
                <div>({data.kills}/{data.deaths}/{data.assists})</div>
            </div>
            <div className='ChaC4'>
                <div style={{ paddingBlock: "5px", fontSize: "25px" }}>{data.winrate}%</div>
                <div>{data.games} games</div>
            </div>
        </div>
    );
}

export default CarteMatchup;
