import React from 'react';
import logo from '../logo.svg';
import capture from '../img/capture.PNG'
import '../style/sidebar.css'
import '../style/titre.css'
import '../style/encadre.css'
import '../style/input.css'
import '../style/button.css'
import '../style/composant.css'
import Navbar from '../components/Navbar';
import GrandTitre from '../components/GrandTitre';
import EncadreMain from '../components/EncadreMain';
import EncadreRechercheJoueur from '../components/EncadreRechercheJoueur';
import SautDemarquation from '../components/SautDemarquation';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import Deux from './Deux'; // Assure-toi que le chemin est correct



function App() {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
        <section className="home">
          <GrandTitre value="LOL Pro Stat . com"/>
          <div className='debutEncadre95Centre'>
            {/* <EncadreMain text1='Title 1 tempo'/> */}
            <EncadreRechercheJoueur />
            {/* <EncadreMain text1='Title 3 tempo'/>   */}
          </div>

          <SautDemarquation />
          
          <div className='encadre95Left mt10 justifierTexte'>
            <h2>Welcome</h2>
            <p>Discover a brand-new feature on our website: access complete statistics on League of Legends players, teams and esport tournaments. Follow the performance of your favorite teams, analyze players in detail, and stay up-to-date on the results of major tournaments. All this is now available in one place on our platform!</p>
          </div>

          <div className='encadre95Left mt10 justifierTexte'>
            <h2>New features</h2>
            <p>New features coming soon! You'll soon be able to create an account to add your favorites, discover a brand-new build page, and integrate runes and builds directly into the client with a single click. We can't wait to share them with you! In the meantime, don't hesitate to contact me with any questions by <a href='google.fr'>clicking here</a>.</p>
          </div>


            



          <br></br>

          <SautDemarquation />

          <div className='encadre95Left mt10 justifierTexte'>
            <h2>Many thanks</h2>
            <p>A huge thank you to all of you! It's incredibly motivating to see the buzz around the site grow. Your support drives us to keep innovating and developing exciting new features. This is just the beginning, and we can't wait to show you what's coming!</p>
            <div className='center mt10'>
              <img src={capture} alt="" style={{ width: '90%'}}/> 
            </div>
            
          </div>
          {/* <nav>
          <Link to="/">Accueil</Link>
          <Link to="/deuxieme">Deuxième Page</Link>
          <Link to="/tierlist">Tierlist</Link>
          <Link to="/esport">Eport</Link>
          <Link to="/esportOverview">Overview</Link>
          <button
        onClick={() => navigate('/esportBestOf?to=Esports_World_Cup_2024&mid=Esports World Cup 2024_Finals_1', { state: { to: "Esports_World_Cup_2024", mid: "Esports World Cup 2024_Finals_1" } })}
      >
        Go to Esport Overview
      </button>
        </nav> */}

        </section>

    </div>
  );
}

export default App;
