import React from 'react';
import { useNavigate } from 'react-router-dom';


function Pie({ }) {
  const navigate = useNavigate();
  return (
    <div>
        var             Pie
    </div>
  );
}

export default Pie;
