import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function EncadreRechercheEport() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    // Appel à l'API pour récupérer les données
    fetch('https://lolprostat.com:8088/showtable')
      .then(response => response.json())
      .then(result => {
        setData(result['data']);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des données : ", error);
      });
  }, []);

  console.log(data)

  // Simuler une base de données
  const data2 = [
    { id: 1, name: "Team Alpha", value: "team_alpha" },
    { id: 2, name: "PlayerOne#1234", value: "player_one_1234" },
    { id: 3, name: "Tournament XYZ", value: "tournament_xyz" },
    { id: 4, name: "PlayerTwo#5678", value: "player_two_5678" },
    { id: 5, name: "Team Beta", value: "team_beta" },
    { id: 6, name: "Esports World Cup 2024", value: "Esports_World_Cup_2024" },
    { id: 7, name: "LFL 2024 Spring", value: "LFL_2024_Spring"}
  ];
  console.log(data2)

  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedName, setSelectedName] = useState('');   // Nouvel état pour stocker le nom sélectionné
  const [selectedValue, setSelectedValue] = useState(''); // Nouvel état pour stocker la value sélectionnée

  const handleSearchInput = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filtrer les suggestions en fonction de la recherche
    if (query.trim() !== "") {
      const filteredSuggestions = data.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Vérifier si un nom et une value sont sélectionnés
    if (selectedName && selectedValue) {
      // Naviguer vers la page avec le name et la value sélectionnés
      navigate('/esportOverview', {
        state: { name: selectedName, value: selectedValue }
      });
    } else {
      alert("Please select a valid option.");
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.name); // Afficher le nom dans l'input
    setSelectedName(suggestion.name); // Stocker le nom sélectionné
    setSelectedValue(suggestion.value); // Stocker la value sélectionnée
    setSuggestions([]); // Fermer la liste des suggestions
  };

  return (
    <div className="encadre33min300max400" style={{ position: 'relative' }}>
      <div className="titreEncadre">Search tournament, team or player</div>
      <div className="mt10 w100">
        <form id="searchForm" onSubmit={handleSubmit} className="flexwrap">
          <input
            type="text"
            placeholder="Search..."
            name="search"
            className="input200"
            id="searchInput"
            value={searchQuery}
            onChange={handleSearchInput}
          />
          <button type="submit" className="boutonClassique ml10">Search</button>
        </form>
        {/* Suggestions s'affichent en dessous de l'input */}
        {suggestions.length > 0 && (
          <ul className="suggestions">
            {suggestions.map((suggestion) => (
              <li 
                key={suggestion.id} 
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default EncadreRechercheEport;
