import React from 'react';
import { useNavigate } from 'react-router-dom';


function Line({ }) {
  const navigate = useNavigate();
  return (
    <div>
        var             Line
    </div>
  );
}

export default Line;
