import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar'
import EncadreRechercheEport from '../components/EncadreRechercheEport'
import SautDemarquation from '../components/SautDemarquation';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';

function EportBracket({ href, iconClass, text }) {
    const navigate = useNavigate();
    const handleClickOverview = (e) => {
        e.preventDefault();   
          navigate('/esportOverview', {
            state: {}});};
            const handleClickRanking = (e) => {
                e.preventDefault();   
                  navigate('/esportRanking', {
                    state: {}});};
                    const handleClickPickBan = (e) => {
                        e.preventDefault();   
                          navigate('/esportPickBan', {
                            state: {}});};
                            const handleClickMatchList = (e) => {
                                e.preventDefault();   
                                  navigate('/esportMatchList', {
                                    state: {}});};
                                    const handleClickSchedule = (e) => {
                                        e.preventDefault();   
                                          navigate('/esportSchedule', {
                                            state: {}});};
                                            const handleClickBracket = (e) => {
                                                e.preventDefault();   
                                                  navigate('/esportBracket', {
                                                    state: {}});};


  return (
    <div>
        <Navbar />
        <section className="home">




        <div className="text">Eport hub</div>
            <div className="flexwrap mt10" >
                <div className="encadre90SA">
                    <div className="newBtnNav" onClick={handleClickOverview}>Overview</div>
                    <div className="newBtnNav" onClick={handleClickRanking}>Ranking</div>
                    <div className="newBtnNav" onClick={handleClickPickBan}>Picks & Bans</div>
                    <div className="newBtnNav" onClick={handleClickMatchList}>Match List</div>
                    <div className="newBtnNav" onClick={handleClickSchedule}>Schedule</div>
                    <div className="newBtnNav" style={{backgroundColor:'white'}}>Bracket</div>
                </div>
            </div>

            <div className="flexwrap mt25">
                <h1 className="encadre90fs">Esports World Cup 2024 - Bracket</h1>
            </div>

            <div className="flexwrap mt25">Not yet implemanted</div>









        </section>
    </div>
  );
}

export default EportBracket;
