import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import EportBtnNav from '../components/EportBtnNav';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';
import $ from 'jquery';
import 'tablesorter';

function EportTeamList({ href, iconClass, text }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { name = 'Unknown Tournament', value = '' } = location.state || {};


    const [ranking, setRanking] = useState([]);

    const [selectedTournament, setSelectedTournament] = useState(value);


    // Fetch function for tournament ranking
    const fetchRanking = async (tournament) => {
        try {
            const url = `https://lolprostat.com:8088/tournamentTeam?to=${tournament}`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Résultat API:", result);
            setRanking(result['participants']);

        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error);
        }
    };

    // useEffect to fetch the data once the component mounts or when selectedTournament changes
    useEffect(() => {
        fetchRanking(selectedTournament);

    }, [selectedTournament]);

    useEffect(() => {
        // Assurez-vous que jQuery est chargé avant d'initialiser Tablesorter
        if (ranking.length) {
            $('#myTable').tablesorter();
        }
    }, [ranking]);

    console.log(ranking)


    return (
        <div>
            <Navbar />
            <section className="home">
                <div className="text">Eport hub</div>
                <div className="flexwrap mt10">
                    <div className="encadre90SA">
                        <EportBtnNav href='/esportOverview' name={name} value={value} text='Overview' />
                        <EportBtnNav href='/esportRanking' name={name} value={value} text='Ranking' />
                        <EportBtnNav href='/esportPickBan' name={name} value={value} text='Picks & Bans' />
                        <EportBtnNav href='/esportMatchList' name={name} value={value} text='Match List' />
                        <EportBtnNav href='/esportplayerlist' name={name} value={value} text='Player List' />
                        <div className="newBtnNav" style={{ backgroundColor: 'white' }}>Team List</div>
                    </div>
                </div>

                <div className="flexwrap mt25">
                    <h1 className="encadre90fs">{name} - Team list</h1>
                </div>

                <div>
                    <div>
                        {ranking.map((team, index) => (
                            <div key={index}>
                                {team.Team}
                            </div>
                        ))}
                    </div>
                </div>

            </section>
        </div>
    );
}

export default EportTeamList;
