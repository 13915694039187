import React from 'react';
import { useNavigate } from 'react-router-dom';

function Build({ data }) {
  const navigate = useNavigate();
  return (
    <div>CC</div>
  );
}

export default Build;
