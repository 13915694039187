import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './index.css';
import App from './page/App';
import Deux from './page/Deux'
import Tierlist from './page/Tierlist'
import Eport from './page/Eport'
import EportOverview from './page/EportOverview';
import EportRanking from './page/EportRanking';
import EportPickBan from './page/EportPickBan';
import EportMatchList from './page/EportMatchList';
import EportSchedule from './page/EportSchedule';
import EportBracket from './page/EportBracket';
import reportWebVitals from './reportWebVitals';
import EportBO from './page/EportBO';
import EportPlayerList from './page/EportPlayerList';
import EportTeamList from './page/EportTeamList';
import AnalysePartie from './components/profile/AnalysePartie';
import EsportPlayer from './page/EsportPlayer';
import ApofHome from './page/apofHome';
import ApofPlayer from './page/ApofPlayer';
import DetailChampion from './page/analyse/DetailChampion';
import DetailMatchup from './page/analyse/DetailMatchup';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/playerprofile" element={<Deux />} />
        <Route path="/analysegame" element={<AnalysePartie />} />
        <Route path="/tierlist" element={<Tierlist />} />
        <Route path="/esport" element={<Eport />} />
        <Route path="/esportOverview" element={<EportOverview />} />
        <Route path="/esportRanking" element={<EportRanking />} />
        <Route path="/esportPickBan" element={<EportPickBan />} />
        <Route path="/esportMatchList" element={<EportMatchList />} />
        <Route path="/esportSchedule" element={<EportSchedule />} />
        <Route path="/esportBracket" element={<EportBracket />} />
        <Route path="/esportBestOf" element={<EportBO />} />
        <Route path="/esportplayerlist" element={<EportPlayerList />} />
        <Route path="/esportteamlist" element={<EportTeamList />} />
        <Route path="/esport/player" element={<EsportPlayer />}/>
        <Route path="/analyse/home" element={<ApofHome />}/>
        <Route path="/analyse/player" element={<ApofPlayer />}/>
        <Route path="/analyse/playerChampion" element={<DetailChampion />}/>
        <Route path="/analyse/playerMatchup" element={<DetailMatchup />}/>
      </Routes>
    </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
