import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import SpinnerAttente from '../components/SpinnerAttente';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';

import Head from '../components/profile/Head';
import Game from '../components/profile/Game';

function Deux() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const name = queryParams.get('name');
  const tag = queryParams.get('tag');
  const region = queryParams.get('region');

  // État pour stocker les données du joueur, l'état de chargement et le numéro de start
  const [playerData, setPlayerData] = useState(null); // Données principales du joueur
  const [games, setGames] = useState([]); // Liste des parties (games)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [start, setStart] = useState(0); // Pour le décalage des parties à charger
  const [loadingMore, setLoadingMore] = useState(false); // Pour afficher le spinner lors du chargement de plus de parties

  const fetchGames = (startValue) => {
    setLoading(startValue === 0); // On affiche le spinner principal seulement lors de la première requête
    setLoadingMore(startValue > 0); // Spinner pour les autres requêtes
    fetch(`https://lolprostat.com:8088/playerProfile?name=${name}&tag=${tag}&region=${region}&start=${startValue}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erreur HTTP! Statut: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Data fetched:', data); // Debug: afficher les données récupérées
        if (startValue === 0) {
          setPlayerData(data.slice(0, 2)); // Stocker les deux premiers éléments (contexte)
          setGames(data.slice(2)); // Stocker les games restantes
        } else {
          setGames((prevGames) => [...prevGames, ...data.slice(2)]); // Ajouter les nouvelles games
        }
        setLoading(false);
        setLoadingMore(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
        setLoadingMore(false);
      });
  };

  // Appel initial pour récupérer les premières parties
  useEffect(() => {
    if (name && tag && region) {
      fetchGames(0); // Charger les données initiales avec start = 0
    } else {
      setError("Les données du joueur sont manquantes.");
      setLoading(false);
    }
  }, [name, tag, region]);

  const loadMoreGames = () => {
    const newStart = start + 10;
    setStart(newStart);
    fetchGames(newStart); // Charger les 10 parties suivantes
  };

  if (loading) {
    return (
      <div>
        <Navbar />
        <section className="home">
          <SpinnerAttente text="match"/>
        </section>
      </div>
    );
  }

  if (error) {
    return <p>Erreur: {error}</p>;
  }

  if (!playerData || !games.length) {
    return <p>Pas de données disponibles pour ce joueur.</p>;
  }

  // Debug: Afficher la structure complète de playerData et games
  console.log('Player data:', playerData);
  console.log('Games data:', games);

  return (
    <div>
      <Navbar />
      <h1>Player Profile</h1>
      <section className="home">
        {/* Affichage des informations de profil du joueur */}
        <Head info={playerData} name={name} tag={tag} />
        <br />
        <br />
        {/* Affichage des games */}
        {games.map((game, index) => (
          <Game key={index} game={game} puuid={playerData[0]['puuid']} region={region} />
        ))}
        {loadingMore && <SpinnerAttente />} {/* Afficher un spinner si on charge plus de parties */}
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <button onClick={loadMoreGames} disabled={loadingMore} className="btn" style={{border:"red solid 1px"}}>
          {loadingMore ? 'Loading...' : 'Loadmore'}
        </button>
        </div>
        <br></br>
        
      </section>
    </div>
  );
}

export default Deux;
