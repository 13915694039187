import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from './Menu'; 
import logo from './logo.png';



function Navbar() {
  const navigate = useNavigate();
  // Références pour accéder aux éléments du DOM
  const bodyRef = useRef(document.querySelector('body'));
  const sidebarRef = useRef(null);
  const toggleRef = useRef(null);
  const searchBtnRef = useRef(null);
  const modeSwitchRef = useRef(null);
  const modeTextRef = useRef(null);

  useEffect(() => {
    const body = bodyRef.current;
    const sidebar = sidebarRef.current;
    const toggle = toggleRef.current;
    const searchBtn = searchBtnRef.current;
    const modeSwitch = modeSwitchRef.current;
    const modeText = modeTextRef.current;

    // Ajout des événements
    const handleToggleClick = () => {
      sidebar.classList.toggle("close");
    };

    // const handleSearchClick = () => {
    //   sidebar.classList.remove("close");
    // };

    // const handleModeSwitchClick = () => {
    //   body.classList.toggle("dark");
    //   modeText.innerText = body.classList.contains("dark") ? "Light mode" : "Dark mode";
    // };

    toggle.addEventListener("click", handleToggleClick);
    //searchBtn.addEventListener("click", handleSearchClick);
    //modeSwitch.addEventListener("click", handleModeSwitchClick);

    // Cleanup des listeners lors du démontage du composant
    return () => {
      toggle.removeEventListener("click", handleToggleClick);
      //searchBtn.removeEventListener("click", handleSearchClick);
      //modeSwitch.removeEventListener("click", handleModeSwitchClick);
    };
  }, []); // Le tableau vide signifie que l'effet ne s'exécute qu'une seule fois, au montage



  return (
    <nav ref={sidebarRef} className="sidebar close">
      <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'></link>
      <header>
        <div className="image-text" onClick={() => navigate('/')}>
          <span className="image">
            
              <img src={logo} alt="" />
            
          </span>
          <div className="text logo-text" onClick={() => navigate('/')}>
            <span className="name">LolProStats</span>
            <span className="profession">Statistiques</span>
          </div>
        </div>
        <i ref={toggleRef} className="bx bx-chevron-right toggle"></i>
      </header>
      <Menu />
    </nav>
  );
}

export default Navbar;

