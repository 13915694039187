import React from 'react';
import { useNavigate } from 'react-router-dom';

function Preview({ href, iconClass, text }) {
  const navigate = useNavigate();
  return (
    <div>Hello Preview</div>
  );
}

export default Preview;
