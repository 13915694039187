import React from 'react';
import mapImage from '../../img/map.png'; // Assurez-vous de mettre le bon chemin de votre image de carte


function Heat({ points, nameC }) {
  const originalMapSize = 16000;
  const displayedMapSize = 512;
  const scaleRatio = displayedMapSize / originalMapSize;

  // Fonction pour importer toutes les images des champions
  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };

  const images = importAll(require.context('../../img/champion', false, /\.(png|jpe?g|svg)$/));

  // Fonction pour vérifier si deux points sont trop proches
  const arePointsClose = (x1, y1, x2, y2, tolerance = 10) => {
    return Math.abs(x1 - x2) < tolerance && Math.abs(y1 - y2) < tolerance;
  };

  // Appliquer un décalage pour éviter la superposition
  const offsetPoint = (x, y, index) => {
    return {
      x: x + (index * 5) % 10, // Modifiez ceci selon la taille souhaitée du décalage
      y: y + (index * 5) % 10
    };
  };

  return (
    <div style={{ position: 'relative', width: `${displayedMapSize}px`, height: `${displayedMapSize}px` }}>
      {/* Image de la carte */}
      <img src={mapImage} alt="map" style={{ width: '100%', height: '100%', borderRadius:"0.5rem", border:"black solid 3px" }} />

      {points.map((point, index) => {
        let x = point[0] * scaleRatio;
        let y = displayedMapSize - point[1] * scaleRatio; // Inversion de l'axe Y

        // Vérifier si les points sont proches et appliquer un décalage si nécessaire
        for (let i = 0; i < index; i++) {
          const previousPointX = points[i][0] * scaleRatio;
          const previousPointY = displayedMapSize - points[i][1] * scaleRatio; // Inversion de l'axe Y pour les points précédents
          if (arePointsClose(x, y, previousPointX, previousPointY)) {
            const offset = offsetPoint(x, y, index);
            x = offset.x;
            y = offset.y;
          }
        }

        // Définir la couleur de la bordure en fonction de l'index
        const borderColor = index < 5 ? 'blue' : 'red';

        // Construire dynamiquement le chemin vers l'icône du champion
        const championIconPath = images[`${nameC[index]}.png`];

        return (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: `${x}px`,
              top: `${y}px`,
              transform: 'translate(-50%, -50%)', // Centrer l'icône sur les coordonnées
            }}
          >
            <img
              src={championIconPath}
              alt={nameC[index]}
              title={nameC[index]} // Tooltip qui affiche le nom du champion
              style={{
                width: '32px', // Taille de l'icône
                height: '32px',
                border: `2px solid ${borderColor}`, // Bordure bleue ou rouge
                borderRadius: '50%', // Pour une bordure circulaire
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Heat;
