import React from 'react';
import { useNavigate } from 'react-router-dom';
function MenuLine({ href, iconClass, text }) {
  const navigate = useNavigate();
  return (
    <li className="nav-link">
      <a onClick={() => {navigate('/'+href)}}>
        <i className={`bx ${iconClass} icon`}></i>
        <span className="text nav-text">{text}</span>
      </a>
    </li>
  );
}

export default MenuLine;
