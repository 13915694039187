import React from 'react';
import { useNavigate } from 'react-router-dom';

function EportBtnNav({ href, name, value, text }) {
  const navigate = useNavigate();
  return (
    <div 
      className="newBtnNav" 
      onClick={() => {
        navigate(`${href}`, {  // Utilisation correcte des backticks pour href
          state: { 
            name: name,    // Nom que tu veux transmettre
            value: value    // Tag que tu veux transmettre
          }
        });
      }}
    >
      {text}
    </div>
  );
}

export default EportBtnNav;
