import React, { useState, useEffect } from "react";
import Navbar from '../components/Navbar';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';
import $ from 'jquery';
import 'tablesorter';

function Tierlist() {
    const [playerData, setPlayerData] = useState({ participants: [] });
    const [selectedLane, setSelectedLane] = useState('Global');
    const [selectedPatch, setSelectedPatch] = useState(''); // Initialiser avec une chaîne vide
    const [patches, setPatches] = useState([]); // État pour stocker les versions

    // Fonction pour récupérer les versions depuis l'API DDragon
    const fetchVersions = async () => {
        try {
            const response = await fetch('https://ddragon.leagueoflegends.com/api/versions.json');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // Filtrer pour ne garder que les versions commençant par "14"
            const filteredArray = data.filter(version => version.startsWith("14"));
            // Extraire la version principale "14.x"
            const mainVersions = [...new Set(filteredArray.map(v => v.split('.').slice(0, 2).join('.')))];
            setPatches(mainVersions); // Mettre à jour l'état avec les versions filtrées
            if (mainVersions.length > 0) {
                setSelectedPatch(mainVersions[0]); // Définir le premier patch comme valeur par défaut
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des versions :', error);
        }
    };

    // Appel à l'API DDragon pour récupérer les versions au montage
    useEffect(() => {
        fetchVersions();
    }, []);

    // Fonction pour récupérer les données de l'API tierlist
    const fetchTierlistData = async (lane, patch) => {
        try {
            const url = `https://lolprostat.com:8088/tierlist?lane=${lane}&patch=${patch}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            setPlayerData(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error);
        }
    };

    // Appel à fetchTierlistData lorsque le patch ou la lane est modifié
    useEffect(() => {
        if (selectedPatch) { // Ne pas appeler l'API tant que selectedPatch n'est pas défini
            fetchTierlistData(selectedLane, selectedPatch);
        }
    }, [selectedLane, selectedPatch]);

    // Gestion du changement de lane
    const handleButtonClick = (lane) => {
        setSelectedLane(lane);
    };

    // Gestion du changement de patch
    const handlePatchChange = (event) => {
        const newPatch = event.target.value;
        setSelectedPatch(newPatch);
    };

    const participants = playerData.participants || [];

    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    };
    const images = importAll(require.context('../img/champion', false, /\.(png|jpe?g|svg)$/));

    useEffect(() => {
        // Assurez-vous que jQuery est chargé avant d'initialiser Tablesorter
        if (participants.length) {
            $('#myTable').tablesorter();
        }
    }, [participants]);

    return (
        <div>
            <Navbar />
            <section className="home">
                <div className="text">Tierlist</div>

                <div className="encadre95Left cl233">
                    <button className="btn mr30" onClick={() => handleButtonClick('Global')}>ALL</button>
                    <button className="btn mlr10" onClick={() => handleButtonClick('TOP')}>TOP</button>
                    <button className="btn mlr10" onClick={() => handleButtonClick('JUNGLE')}>JUNGLE</button>
                    <button className="btn mlr10" onClick={() => handleButtonClick('MID')}>MIDDLE</button>
                    <button className="btn mlr10" onClick={() => handleButtonClick('ADC')}>ADC</button>
                    <button className="btn mlr10" onClick={() => handleButtonClick('SUPP')}>SUPP</button>

                    <select id="dropdown" className="select ml30" value={selectedPatch} onChange={handlePatchChange}>
                        {patches.map(patch => (
                            <option key={patch} value={patch}>{patch}</option>
                        ))}
                    </select>
                </div>

                <div className="debutEncadre95Centre" id="result">
                    <table id="myTable" className="tablesorter test neumorphic">
                        <thead>
                            <tr>
                                <th>Ranking</th>
                                <th>Icon</th>
                                <th>Champion</th>
                                <th>Game</th>
                                <th>Win rate</th>
                                <th>Ban rate</th>
                                <th>Presence rate</th>
                            </tr>
                        </thead>
                        <tbody id="tableBody">
                            {participants.length > 0 ? (
                                participants.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.ranking}</td>
                                        <td>
                                            <img
                                                src={images[`${item.champName}.png`]}
                                                alt={item.champion}
                                                style={{ width: "32px", height: "32px" }}
                                            />
                                        </td>
                                        <td>{item.champName}</td>
                                        <td>{item.gameNumber}</td>
                                        <td>{item.winPercentage}%</td>
                                        <td>{item.bannedPercentage}%</td>
                                        <td>{item.presencePercentage}%</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7">Loading data please wait . . .</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
}

export default Tierlist;
