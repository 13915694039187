import React, { useState } from 'react';
import mapImage from '../../img/map.png';

function Heatmap({ participants }) {
    const [selectedSide, setSelectedSide] = useState("BLUE"); // État pour la sélection BLUE/RED
    const [selectedRole, setSelectedRole] = useState("MIDDLE"); // État pour la sélection des rôles
    const [selectedTime, setSelectedTime] = useState("positionM1"); // État pour la sélection des rôles

    const originalMapSize = 16000;
    const displayedMapSize = 512;
    const scaleRatio = displayedMapSize / originalMapSize;

    // Fonction pour calculer la densité autour d'un point donné
    const getDensity = (x, y, radius = 50) => {
        let count = 0;
        participants['participants'].forEach((otherPoint) => {
            if (otherPoint['side'] === selectedSide && otherPoint['lane'] === selectedRole) {
                const point = JSON.parse(otherPoint[selectedTime]);
                const pointX = point['x'] * scaleRatio;
                const pointY = displayedMapSize - point['y'] * scaleRatio;

                const distance = Math.sqrt(Math.pow(x - pointX, 2) + Math.pow(y - pointY, 2));
                if (distance <= radius) {
                    count += 1;
                }
            }
        });
        return count;
    };

    // Fonction pour déterminer la couleur en fonction de la densité
    const getColor = (density, maxDensity) => {
        const blue = 255; // Valeur maximale pour le bleu
        const red = 255; // Valeur maximale pour le rouge

        const ratio = density / maxDensity; // Ratio de densité par rapport à la densité maximale
        const r = Math.floor(red * ratio); // Plus la densité est grande, plus le rouge sera élevé
        const b = Math.floor(blue * (1 - ratio)); // Plus la densité est petite, plus le bleu sera élevé

        return `rgb(${r}, 0, ${b})`; // Renvoie un gradient du bleu au rouge
    };

    // Calculer la densité maximale pour ajuster le gradient
    const densities = participants['participants'].map((participant) => {
        if (participant['side'] === selectedSide && participant['lane'] === selectedRole) {
            const point = JSON.parse(participant[selectedTime]);
            const x = point['x'] * scaleRatio;
            const y = displayedMapSize - point['y'] * scaleRatio;
            return getDensity(x, y);
        }
        return 0;
    });
    const maxDensity = Math.max(...densities);
    return (
        <div className='centerHome'>
            <div>
                {/* Sélections */}
                <div style={{ marginBottom: '1rem' }}>
                    <label style={{fontSize:"20px"}}>
                        Side:
                        <select value={selectedSide} onChange={(e) => setSelectedSide(e.target.value)} className='select' style={{marginLeft:"5px"}}>
                            <option value="BLUE">BLUE</option>
                            <option value="RED">RED</option>
                        </select>
                    </label>
                    <label style={{ marginLeft: '1rem',fontSize:"20px" }}>
                        Role:
                        <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)} className='select' style={{marginLeft:"5px"}}>
                            <option value="TOP">TOP</option>
                            <option value="JUNGLE">JUNGLE</option>
                            <option value="MIDDLE">MIDDLE</option>
                            <option value="BOTTOM">BOTTOM</option>
                            <option value="UTILITY">UTILITY</option>
                        </select>
                    </label>
                    <label style={{ marginLeft: '1rem',fontSize:"20px" }}>
                        Time:
                        <select value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} className='select' style={{marginLeft:"5px"}}>
                            <option value="positionM1">Min 1</option>
                            <option value="positionM2">Min 2</option>
                            <option value="positionM3">Min 3</option>
                            <option value="positionM4">Min 4</option>
                            <option value="positionM5">Min 5</option>
                            <option value="positionM6">Min 6</option>
                            <option value="positionM7">Min 7</option>
                            <option value="positionM8">Min 8</option>
                            <option value="positionM9">Min 9</option>
                            <option value="positionM10">Min 10</option>
                            <option value="positionM11">Min 11</option>
                            <option value="positionM12">Min 12</option>
                            <option value="positionM13">Min 13</option>
                            <option value="positionM14">Min 14</option>
                        </select>
                    </label>
                </div>

                <div style={{ position: 'relative', width: `${displayedMapSize}px`, height: `${displayedMapSize}px` }}>
                    {/* Image de la carte */}
                    <img src={mapImage} alt="map" style={{ width: '100%', height: '100%', borderRadius: "0.5rem", border: "black solid 3px", display: "block" }} />

                    {participants['participants'].map((participant, index) => {
                        if (participant['side'] === selectedSide && participant['lane'] === selectedRole) {
                            const point = JSON.parse(participant[selectedTime]);
                            const x = point['x'] * scaleRatio;
                            const y = displayedMapSize - point['y'] * scaleRatio;

                            // Calculer la densité autour du point
                            const density = getDensity(x, y);

                            // Calculer la couleur du point en fonction de la densité
                            const color = getColor(density, maxDensity);

                            return (
                                <div
                                    key={index}
                                    style={{
                                        position: 'absolute',
                                        left: `${x}px`,
                                        top: `${y}px`,
                                        transform: 'translate(-50%, -50%)', // Centrer le point sur les coordonnées
                                        width: '10px', // Taille du point
                                        height: '10px',
                                        backgroundColor: color, // Couleur dynamique en fonction de la densité
                                        borderRadius: '50%', // Forme circulaire
                                    }}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

export default Heatmap;
