import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import EportBtnNav from "../components/EportBtnNav";
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';
import $ from 'jquery';
import 'tablesorter';

function EportRanking() {
    const navigate = useNavigate();
    const location = useLocation();
    const { name,value } = location.state || {};
    console.log(name)
    console.log(value)
    
    // Initialiser ranking comme un tableau vide
    const [ranking, setRanking] = useState([]);

    const [selectedTournament, setSelectedTournament] = useState(value);
    

    // Fetch function for tournament ranking
    const fetchRanking = async (tournament) => {
        try {
            const url = `https://lolprostat.com:8088/tournament?to=${tournament}`;
            const response = await fetch(url);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        
            const result = await response.json();
            console.log("Résultat API:", result);
            setRanking(JSON.parse(result['participants'][0].ranking));

        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error);
        }
    };

    // useEffect to fetch the data once the component mounts or when selectedTournament changes
    useEffect(() => {
        fetchRanking(selectedTournament);
        
    }, [selectedTournament]);

    useEffect(() => {
        // Assurez-vous que jQuery est chargé avant d'initialiser Tablesorter
        if (ranking.length) {
            $('#myTable').tablesorter();
        }
    }, [ranking]);

    console.log(ranking)

    return (
        <div>
            <Navbar />
            <section className="home">
                <div className="text">Esport hub</div>
                <div className="flexwrap mt10">
                    <div className="encadre90SA">
                        <EportBtnNav href='/esportOverview' name={name} value={value} text='Overview'/>
                        <div className="newBtnNav" style={{ backgroundColor: 'white' }}>Ranking</div>
                        <EportBtnNav href='/esportPickBan' name={name} value={value} text='Picks & Bans'/>
                        <EportBtnNav href='/esportMatchList' name={name} value={value} text='Match List'/>
                        {/* <EportBtnNav href='/esportSchedule' name={name} value={value} text='Schedule'/>
                        <EportBtnNav href='/esportBracket' name={name} value={value} text='Bracket'/> */}
                        <EportBtnNav href='/esportplayerlist' name={name} value={value} text='Player List' />
                        <EportBtnNav href='/esportteamlist' name={name} value={value} text='Team List' />
                    </div>
                </div>

                <div className="flexwrap mt25">
                    <h1 className="encadre90fs">{name} - Ranking</h1>
                </div>

                <div className="debutEncadre95Centre">
                    <table id="myTable" className="tablesorter test neumorphic">
                        <thead>
                            <tr>
                                <th>Team</th>
                                <th>Win rate</th>
                                <th>Wins</th>
                                <th>Losses</th>
                                <th>Game duration</th>
                            </tr>
                        </thead>
                        <tbody id="tableBody">
                            {Array.isArray(ranking) && ranking.length > 0 ? (
                                ranking.map((item, index) => (
                                    <tr key={index}>
                                        <td><a href={`https://example.com/team/${item.TEAM}`} style={{ textDecoration: 'none' }}>{item.TEAM}</a></td>
                                        <td><div className="bar-container">
                                                <div
                                                    className="bar-fill"
                                                    style={{ width: item['WINRATE'] + '%' }}
                                                />
                                                <div className="label">{item['WINRATE'].toFixed(1) + '%'}</div>
                                            </div></td>
                                        <td>{item.WIN}</td>
                                        <td>{item.LOOSE}</td>
                                        <td>{item.MEAN_GAME_DURATION}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Loading data, please wait . . .</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
}

export default EportRanking;
