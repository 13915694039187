import React from 'react';
import { useNavigate } from 'react-router-dom';


function Polar({ }) {
  const navigate = useNavigate();
  return (
    <div>
        var             Polar
    </div>
  );
}

export default Polar;
