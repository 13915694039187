import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import EportBtnNav from '../components/EportBtnNav';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);


function EportOverview({ href, iconClass, text }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { name = 'Unknown Tournament', value = '' } = location.state || {};

    const [data, setData] = useState([]);
    const [tournamentData, setTournamentData] = useState({});
    const [topKda, setTopKda] = useState({});
    const [multi, setMulti] = useState([]); // Changer à tableau vide
    const [mostKill, setMostKill] = useState([]);
    const [topGpm, setTopGpm] = useState([]);
    const [bestFarm, setBestFarm] = useState([]);
    const [topFarm, setTopFarm] = useState([]);

    const [wrSide, setWrSide] = useState({});
    const [wrSideD, setWrSideD] = useState({});
    const [wrSideH, setWrSideH] = useState({});
    const [wrSideT, setWrSideT] = useState({});
    const [wrSideB, setWrSideB] = useState({});

    const [tableType, setTableType] = useState('champ');
    const [mid, setMid] = useState(null);

    const fetchData = async () => {
        console.log('Fetching data...');
        if (!value) {
            console.log("No tournament selected");
            return;
        }

        try {
            const response = await fetch(`https://lolprostat.com:8088/tournament?to=${value}`);
            const result = await response.json();
            console.log('API Result:', result);

            if (result && result['participants'] && result['participants'][0]) {
                setData(result['participants'][0]);
                try {
                    const overviewData = JSON.parse(result['participants'][0]['Overview']);
                    const multiData = JSON.parse(result['participants'][0]['top_multikill']) || [];
                    const mkill = JSON.parse(result['participants'][0]['most_kill']) || [];
                    const kda = JSON.parse(result['participants'][0]['kdaJson']);
                    const gpm = JSON.parse(result['participants'][0]['top_gpm']) || [];
                    const bestfar = JSON.parse(result['participants'][0]['top_farm']) || [];
                    const topfar = JSON.parse(result['participants'][0]['top_csm']) || [];

                    const wrsideside = JSON.parse(result['participants'][0]['wrSide']) || {};
                    const wrsidesideD = JSON.parse(result['participants'][0]['wrSideDragon']) || {};
                    const wrsidesideH = JSON.parse(result['participants'][0]['wrSideHerald']) || {};
                    const wrsidesideT = JSON.parse(result['participants'][0]['wrSideTour']) || {};
                    const wrsidesideB = JSON.parse(result['participants'][0]['wrSideBaron']) || {};

                    setTournamentData(overviewData || {});
                    setMulti(multiData);
                    setTopKda(kda || {});
                    setMostKill(mkill);
                    setTopGpm(gpm);
                    setBestFarm(bestfar);
                    setTopFarm(topfar);

                    setWrSide(wrsideside);
                    setWrSideD(wrsidesideD);
                    setWrSideH(wrsidesideH);
                    setWrSideT(wrsidesideT);
                    setWrSideB(wrsidesideB);

                } catch (error) {
                    console.error('Error parsing Overview data:', error);
                }
            } else {
                console.log('No valid data found in API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [value]); // Depend on `value` to refetch when it changes

    console.log('wrside:', wrSide);


    const wrB = [];
    wrB.push(wrSide['win_rate_blue']);
    wrB.push(wrSideD['win_rate_blue']);
    wrB.push(wrSideH['win_rate_blue']);
    wrB.push(wrSideT['win_rate_blue']);
    wrB.push(wrSideB['win_rate_blue']);

    const wrR = [];
    wrR.push(wrSide['win_rate_red']);
    wrR.push(wrSideD['win_rate_red']);
    wrR.push(wrSideH['win_rate_red']);
    wrR.push(wrSideT['win_rate_red']);
    wrR.push(wrSideB['win_rate_red']);

    // const { wrB, wrR } = data; // Assurez-vous que les données sont passées correctement

    const sideWRData = {
        labels: ['Win Rate', 'First Dragon', 'First Herald', 'First Tower', 'First Nashor'],
        datasets: [
            {
                data: wrB,
                backgroundColor: [
                    'rgba(97,148,188,1)',
                    'rgba(97,148,188,1)',
                    'rgba(97,148,188,1)',
                    'rgba(97,148,188,1)',
                    'rgba(97,148,188,1)'
                ],
                borderWidth: 0,
                label: 'Blue Side'
            },
            {
                data: wrR,
                backgroundColor: [
                    'rgba(238,50,51,1)',
                    'rgba(238,50,51,1)',
                    'rgba(238,50,51,1)',
                    'rgba(238,50,51,1)',
                    'rgba(238,50,51,1)'
                ],
                borderWidth: 0,
                label: 'Red Side'
            }
        ]
    };

    const options = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#FFF',
                    min: 0
                },
                stacked: true
            },
            y: {
                categoryPercentage: 0.9,
                barPercentage: 1.0,
                ticks: {
                    color: '#FFF',
                    min: 0
                },
                stacked: true
            }
        }
    };

    console.log('hello', bestFarm);

    const handleClick = async (tour, rid, equipes) => {
        try {
            const response = await fetch(`https://lolprostat.com:8088/tournamentRIDtoMID?to=${tour}&rid=${rid}`);

            if (!response.ok) {
                throw new Error("Erreur réseau lors de la récupération des données");
            }

            const data = await response.json();
            console.log('data', data['participants'][0]['MatchId'])
            console.log('url', `/esportBestOf?to=${value}&mid=${data['participants'][0]['MatchId']}`)
            // const matchId = data.matchId;  // Supposons que l'API retourne un `matchId`
            // setMid(matchId);

            // Navigation vers l'URL dynamique
            navigate(`/esportBestOf?to=${value}&mid=${data['participants'][0]['MatchId']}&equipe=${equipes}`);
        } catch (error) {
            console.error("Erreur lors de l'appel à l'API", error);
        }
    };


    return (
        <div>
            <Navbar />
            <section className="home">
                <div className="text">Eport hub</div>
                <div className="flexwrap mt10">
                    <div className="encadre90SA">
                        <div className="newBtnNav" style={{ backgroundColor: 'white' }}>Overview</div>
                        <EportBtnNav href='/esportRanking' name={name} value={value} text='Ranking' />
                        <EportBtnNav href='/esportPickBan' name={name} value={value} text='Picks & Bans' />
                        <EportBtnNav href='/esportMatchList' name={name} value={value} text='Match List' />
                        <EportBtnNav href='/esportplayerlist' name={name} value={value} text='Player List' />
                        <EportBtnNav href='/esportteamlist' name={name} value={value} text='Team List' />
                    </div>
                </div>

                <div className="flexwrap mt25">
                    <h1 className="encadre90fs">{name} - Overview</h1>
                </div>

                <div>
                    <div className="selectionTP">
                        <div className="selectionTPBtest3" style={{ border: '1px solid #9cb1eb' }}>
                            <div className="tournamentData">Tournament Data</div>
                            <div className="flexrow">
                                <div style={{ marginRight: '20px' }}>
                                    <div className="txtalignright mt5mb5">Number of Game:</div>
                                    <div className="txtalignright mt5mb5">Average game duration:</div>
                                    <div className="txtalignright mt5mb5">Average kills / game:</div>
                                    <div className="txtalignright mt5mb5">Shortest game:</div>
                                    <div className="txtalignright mt5mb5">Longest game:</div>
                                    <div className="txtalignright mt5mb5">Game with the most kills:</div>
                                </div>

                                <div style={{ marginLeft: '20px' }}>
                                    <div className="txtaligncenter mt5mb5" id="nbrgame">
                                        {tournamentData['nombre_de_parties'] || 'N/A'}
                                    </div>
                                    <div className="txtaligncenter mt5mb5" id="avggame">
                                        {tournamentData['moyenne_duree_parties'] ?
                                            `${tournamentData['moyenne_duree_parties']['minutes']}:${tournamentData['moyenne_duree_parties']['secondes']}` : 'N/A'}
                                    </div>
                                    <div className="txtaligncenter mt5mb5" id="avgkill">
                                        {tournamentData['moyenne_kills_par_partie'] || 'N/A'}
                                    </div>
                                    <div className="txtaligncenter mt5mb5" id="shortgame">
                                        {tournamentData['partie_la_plus_courte'] ? (
                                            <>
                                                {tournamentData['partie_la_plus_courte']['duree_minutes']}:
                                                {tournamentData['partie_la_plus_courte']['duree_secondes']} -
                                                <span
                                                    onClick={() => handleClick(value, tournamentData['partie_la_plus_courte']['game_id'], tournamentData['partie_la_plus_courte']['equipes'])}
                                                    // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                                    style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                                >
                                                    {tournamentData['partie_la_plus_courte']['equipes']}
                                                </span>
                                            </>
                                        ) : 'N/A'}
                                    </div>

                                    <div className="txtaligncenter mt5mb5" id="longgame">
                                        {tournamentData['partie_la_plus_longue'] ? (
                                            <>
                                                {tournamentData['partie_la_plus_longue']['duree_minutes']}:
                                                {tournamentData['partie_la_plus_longue']['duree_secondes']} -
                                                <span
                                                    onClick={() => handleClick(value, tournamentData['partie_la_plus_longue']['game_id'], tournamentData['partie_la_plus_longue']['equipes'])}
                                                    // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                                    style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                                >
                                                    {tournamentData['partie_la_plus_longue']['equipes']}
                                                </span>
                                            </>
                                        ) : 'N/A'}
                                    </div>

                                    <div className="txtaligncenter mt5mb5" id="gamekill">
                                        {tournamentData['jeu_avec_le_plus_de_kills'] ? (
                                            <>
                                                {tournamentData['jeu_avec_le_plus_de_kills']['nombre_kills']} kills in&nbsp; 
                                                {tournamentData['jeu_avec_le_plus_de_kills']['duree_minutes']}:
                                                {tournamentData['jeu_avec_le_plus_de_kills']['duree_secondes']} -
                                                <span
                                                    onClick={() => handleClick(value, tournamentData['jeu_avec_le_plus_de_kills']['game_id'], tournamentData['jeu_avec_le_plus_de_kills']['equipes'])}
                                                    // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                                    style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                                >
                                                    {tournamentData['jeu_avec_le_plus_de_kills']['equipes']}
                                                </span>
                                            </>
                                        ) : 'N/A'}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="selectionTPBtest3" style={{ border: '1px solid #9cb1eb' }}>
                            <div className="tournamentData">Blue Red/Side</div>
                            <div className="flexrow">
                                <div style={{ maxWidth: '765px', height: '150px' }}>
                                    <Bar data={sideWRData} options={options} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="selectionTP">
                        <div className="selectionTPBtest3" style={{ border: '1px solid #9cb1eb' }}>
                            <div className="tournamentData">Top KDA</div>
                            <div className="flexrow">
                                <div style={{ marginRight: '20px' }}>
                                    <div className="txtalignright mt5mb5" id="topkdaname1"><a href={`https://example.com/team/${topKda[0]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topKda[0]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topkdaname2"><a href={`https://example.com/team/${topKda[1]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topKda[1]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topkdaname3"><a href={`https://example.com/team/${topKda[2]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topKda[2]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topkdaname4"><a href={`https://example.com/team/${topKda[3]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topKda[3]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topkdaname5"><a href={`https://example.com/team/${topKda[4]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topKda[4]?.Player || 'N/A'}</a></div>
                                </div>

                                <div style={{ marginLeft: '20px' }}>
                                    <div className="txtalignleft mt5mb5" id="topkdavalue1">{topKda[0]?.KDA || 'NA'} - ({topKda[0]?.Kills || 'NA'}/{topKda[0]?.Deaths || 'NA'}/{topKda[0]?.Assists || 'NA'})</div>
                                    <div className="txtalignleft mt5mb5" id="topkdavalue2">{topKda[1]?.KDA || 'NA'} - ({topKda[1]?.Kills || 'NA'}/{topKda[1]?.Deaths || 'NA'}/{topKda[1]?.Assists || 'NA'})</div>
                                    <div className="txtalignleft mt5mb5" id="topkdavalue3">{topKda[2]?.KDA || 'NA'} - ({topKda[2]?.Kills || 'NA'}/{topKda[2]?.Deaths || 'NA'}/{topKda[2]?.Assists || 'NA'})</div>
                                    <div className="txtalignleft mt5mb5" id="topkdavalue4">{topKda[3]?.KDA || 'NA'} - ({topKda[3]?.Kills || 'NA'}/{topKda[3]?.Deaths || 'NA'}/{topKda[3]?.Assists || 'NA'})</div>
                                    <div className="txtalignleft mt5mb5" id="topkdavalue5">{topKda[4]?.KDA || 'NA'} - ({topKda[4]?.Kills || 'NA'}/{topKda[4]?.Deaths || 'NA'}/{topKda[4]?.Assists || 'NA'})</div>
                                </div>
                            </div>
                        </div>

                        <div className="selectionTPBtest3" style={{ border: '1px solid #9cb1eb' }}>
                            <div className="tournamentData">Top Multikill</div>
                            <div className="flexrow">
                                <div style={{ marginRight: '20px' }}>
                                    <div className="txtalignright mt5mb5" id="infomulti1">Player</div>
                                    <div className="txtalignright mt5mb5" id="multikill1"><a href={`https://example.com/team/${multi[0]?.player || 'N/A'}`} style={{ textDecoration: 'none' }}>{multi[0]?.player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="multikill2"><a href={`https://example.com/team/${multi[1]?.player || 'N/A'}`} style={{ textDecoration: 'none' }}>{multi[1]?.player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="multikill3"><a href={`https://example.com/team/${multi[2]?.player || 'N/A'}`} style={{ textDecoration: 'none' }}>{multi[2]?.player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="multikill4"><a href={`https://example.com/team/${multi[3]?.player || 'N/A'}`} style={{ textDecoration: 'none' }}>{multi[3]?.player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="multikill5"><a href={`https://example.com/team/${multi[4]?.player || 'N/A'}`} style={{ textDecoration: 'none' }}>{multi[4]?.player || 'N/A'}</a></div>
                                </div>

                                <div style={{ marginLeft: '20px' }}>
                                    <div className="txtalignright mt5mb5" id="infomulti2">Pentakill</div>
                                    <div className="txtalignright mt5mb5" id="penta1">{multi[0]?.penta || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="penta2">{multi[1]?.penta || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="penta3">{multi[2]?.penta || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="penta4">{multi[3]?.penta || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="penta5">{multi[4]?.penta || '0'}</div>
                                </div>

                                <div style={{ marginLeft: '20px' }}>
                                    <div className="txtalignright mt5mb5" id="infomulti3">Quadra</div>
                                    <div className="txtalignright mt5mb5" id="quadra1">{multi[0]?.quadra || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="quadra2">{multi[1]?.quadra || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="quadra3">{multi[2]?.quadra || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="quadra4">{multi[3]?.quadra || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="quadra5">{multi[4]?.quadra || '0'}</div>
                                </div>

                                <div style={{ marginLeft: '20px' }}>
                                    <div className="txtalignright mt5mb5" id="infomulti4">Triple</div>
                                    <div className="txtalignright mt5mb5" id="triple1">{multi[0]?.triple || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="triple2">{multi[1]?.triple || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="triple3">{multi[2]?.triple || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="triple4">{multi[3]?.triple || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="triple5">{multi[4]?.triple || '0'}</div>
                                </div>

                                <div style={{ marginLeft: '20px' }}>
                                    <div className="txtalignright mt5mb5" id="infomulti5">Double</div>
                                    <div className="txtalignright mt5mb5" id="double1">{multi[0]?.double || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="double2">{multi[1]?.double || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="double3">{multi[2]?.double || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="double4">{multi[3]?.double || '0'}</div>
                                    <div className="txtalignright mt5mb5" id="double5">{multi[4]?.double || '0'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>






                    <div className="selectionTP">
                        <div className="selectionTPBtest3" style={{ border: "#9cb1eb 1px solid" }}>
                            <div className="tournamentData">Most kills in a single game</div>
                            <div className="flxcol">
                                <div className="zuihgzeuihgfzeui">
                                    <div className="flxcol2">
                                        <div id="mostkillpicture1" className="flxcol2marginLi">{mostKill[0]?.Champion || 'NA'}</div>
                                        <div id="mostkillplayer1"><a href={`https://example.com/team/${mostKill[0]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{mostKill[0]?.Player || 'N/A'}</a>
                                        </div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostkillpicture2" className="flxcol2marginLi">{mostKill[1]?.Champion || 'NA'}</div>
                                        <div id="mostkillplayer2"><a href={`https://example.com/team/${mostKill[1]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{mostKill[1]?.Player || 'N/A'}</a></div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostkillpicture3" className="flxcol2marginLi">{mostKill[2]?.Champion || 'NA'}</div>
                                        <div id="mostkillplayer3"><a href={`https://example.com/team/${mostKill[2]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{mostKill[2]?.Player || 'N/A'}</a></div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostkillpicture4" className="flxcol2marginLi">{mostKill[3]?.Champion || 'NA'}</div>
                                        <div id="mostkillplayer4"><a href={`https://example.com/team/${mostKill[3]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{mostKill[3]?.Player || 'N/A'}</a></div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostkillpicture5" className="flxcol2marginLi">{mostKill[4]?.Champion || 'NA'}</div>
                                        <div id="mostkillplayer5"><a href={`https://example.com/team/${mostKill[4]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{mostKill[4]?.Player || 'N/A'}</a></div>
                                    </div>
                                </div>
                                <div className="aybiazbiyua">
                                    <div id="mostkillinfo1">{mostKill[0]?.Kills || 'NA'} (<span
                                        onClick={() => handleClick(value, mostKill[0]?.Game, mostKill[0]?.Match)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {mostKill[0]?.Match || 'N/A'}
                                    </span>)</div>
                                    <div id="mostkillinfo2">{mostKill[1]?.Kills || 'NA'} (<span
                                        onClick={() => handleClick(value, mostKill[1]?.Game, mostKill[1]?.Match)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {mostKill[1]?.Match || 'N/A'}
                                    </span>)</div>
                                    <div id="mostkillinfo3">{mostKill[2]?.Kills || 'NA'} (<span
                                        onClick={() => handleClick(value, mostKill[2]?.Game, mostKill[2]?.Match)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {mostKill[2]?.Match || 'N/A'}
                                    </span>)</div>
                                    <div id="mostkillinfo4">{mostKill[3]?.Kills || 'NA'} (<span
                                        onClick={() => handleClick(value, mostKill[3]?.Game, mostKill[3]?.Match)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {mostKill[3]?.Match || 'N/A'}
                                    </span>)</div>
                                    <div id="mostkillinfo5">{mostKill[4]?.Kills || 'NA'} (<span
                                        onClick={() => handleClick(value, mostKill[4]?.Game, mostKill[4]?.Match)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {mostKill[4]?.Match || 'N/A'}
                                    </span>)</div>
                                </div>
                            </div>
                        </div>










                        <div className="selectionTPBtest3" style={{ border: "#9cb1eb 1px solid" }}>
                            <div className="tournamentData">Top team GPM</div>
                            <div className="flexrow">
                                <div style={{ marginRight: "20px" }}>
                                    <div className="txtalignright mt5mb5" id="topteamgpm1"><a href={`https://example.com/team/${topGpm[0]?.Team || 'N/A'}`} style={{ textDecoration: 'none' }}>{topGpm[0]?.Team || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topteamgpm2"><a href={`https://example.com/team/${topGpm[1]?.Team || 'N/A'}`} style={{ textDecoration: 'none' }}>{topGpm[1]?.Team || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topteamgpm3"><a href={`https://example.com/team/${topGpm[2]?.Team || 'N/A'}`} style={{ textDecoration: 'none' }}>{topGpm[2]?.Team || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topteamgpm4"><a href={`https://example.com/team/${topGpm[3]?.Team || 'N/A'}`} style={{ textDecoration: 'none' }}>{topGpm[3]?.Team || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topteamgpm5"><a href={`https://example.com/team/${topGpm[4]?.Team || 'N/A'}`} style={{ textDecoration: 'none' }}>{topGpm[4]?.Team || 'N/A'}</a></div>
                                </div>

                                <div style={{ marginLeft: "20px" }}>
                                    <div className="txtalignleft mt5mb5" id="topteamgpmvalue1">{topGpm[0]?.Total_Gold_Per_Minute || 'NA'} GPM (<span
                                        onClick={() => handleClick(value, topGpm[0]?.GameId, topGpm[0]?.Game)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {topGpm[0]?.Game || 'N/A'}
                                    </span>)</div>
                                    <div className="txtalignleft mt5mb5" id="topteamgpmvalue2">{topGpm[1]?.Total_Gold_Per_Minute || 'NA'} GPM (<span
                                        onClick={() => handleClick(value, topGpm[1]?.GameId, topGpm[1]?.Game)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {topGpm[1]?.Game || 'N/A'}
                                    </span>)</div>
                                    <div className="txtalignleft mt5mb5" id="topteamgpmvalue3">{topGpm[2]?.Total_Gold_Per_Minute || 'NA'} GPM (<span
                                        onClick={() => handleClick(value, topGpm[2]?.GameId, topGpm[2]?.Game)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {topGpm[2]?.Game || 'N/A'}
                                    </span>)</div>
                                    <div className="txtalignleft mt5mb5" id="topteamgpmvalue4">{topGpm[3]?.Total_Gold_Per_Minute || 'NA'} GPM (<span
                                        onClick={() => handleClick(value, topGpm[3]?.GameId, topGpm[3]?.Game)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {topGpm[3]?.Game || 'N/A'}
                                    </span>)</div>
                                    <div className="txtalignleft mt5mb5" id="topteamgpmvalue5">{topGpm[4]?.Total_Gold_Per_Minute || 'NA'} GPM (<span
                                        onClick={() => handleClick(value, topGpm[4]?.GameId, topGpm[4]?.Game)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {topGpm[4]?.Game || 'N/A'}
                                    </span>)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>

                    <div className="selectionTP">






                        <div className="selectionTPBtest3" style={{ border: '#9cb1eb 1px solid' }}>
                            <div className="tournamentData">Best farm</div>
                            <div className="flxcol">
                                <div className="zuihgzeuihgfzeui">
                                    <div className="flxcol2">
                                        <div id="mostfarmpicture1" className="flxcol2marginLi">{bestFarm[0]?.champ || 'NA'}</div>
                                        <div id="mostfarmplayer1"><a href={`https://example.com/team/${bestFarm[0]?.PlayerName || 'N/A'}`} style={{ textDecoration: 'none' }}>{bestFarm[0]?.PlayerName || 'N/A'}</a></div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostfarmpicture2" className="flxcol2marginLi">{bestFarm[1]?.champ || 'NA'}</div>
                                        <div id="mostfarmplayer2"><a href={`https://example.com/team/${bestFarm[1]?.PlayerName || 'N/A'}`} style={{ textDecoration: 'none' }}>{bestFarm[1]?.PlayerName || 'N/A'}</a></div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostfarmpicture3" className="flxcol2marginLi">{bestFarm[2]?.champ || 'NA'}</div>
                                        <div id="mostfarmplayer3"><a href={`https://example.com/team/${bestFarm[2]?.PlayerName || 'N/A'}`} style={{ textDecoration: 'none' }}>{bestFarm[2]?.PlayerName || 'N/A'}</a></div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostfarmpicture4" className="flxcol2marginLi">{bestFarm[3]?.champ || 'NA'}</div>
                                        <div id="mostfarmplayer4"><a href={`https://example.com/team/${bestFarm[3]?.PlayerName || 'N/A'}`} style={{ textDecoration: 'none' }}>{bestFarm[3]?.PlayerName || 'N/A'}</a></div>
                                    </div>
                                    <div className="flxcol2">
                                        <div id="mostfarmpicture5" className="flxcol2marginLi">{bestFarm[4]?.champ || 'NA'}</div>
                                        <div id="mostfarmplayer5"><a href={`https://example.com/team/${bestFarm[4]?.PlayerName || 'N/A'}`} style={{ textDecoration: 'none' }}>{bestFarm[4]?.PlayerName || 'N/A'}</a></div>
                                    </div>
                                </div>
                                <div className="aybiazbiyua">
                                    <div id="mostfarminfo1">{bestFarm[0]?.CSM || 'NA'} CSM - {bestFarm[0]?.CS || 'NA'}CS in {bestFarm[0]?.TimePlayed || 'NA'} (<span
                                        onClick={() => handleClick(value, bestFarm[0]?.GameId, bestFarm[0]?.gamaName)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {bestFarm[0]?.gamaName}
                                    </span>)</div>
                                    <div id="mostfarminfo2">{bestFarm[1]?.CSM || 'NA'} CSM - {bestFarm[1]?.CS || 'NA'}CS in {bestFarm[1]?.TimePlayed || 'NA'} (<span
                                        onClick={() => handleClick(value, bestFarm[1]?.GameId, bestFarm[1]?.gamaName)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {bestFarm[1]?.gamaName}
                                    </span>)</div>
                                    <div id="mostfarminfo3">{bestFarm[2]?.CSM || 'NA'} CSM - {bestFarm[2]?.CS || 'NA'}CS in {bestFarm[2]?.TimePlayed || 'NA'} (<span
                                        onClick={() => handleClick(value, bestFarm[2]?.GameId, bestFarm[2]?.gamaName)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {bestFarm[2]?.gamaName}
                                    </span>)</div>
                                    <div id="mostfarminfo4">{bestFarm[3]?.CSM || 'NA'} CSM - {bestFarm[3]?.CS || 'NA'}CS in {bestFarm[3]?.TimePlayed || 'NA'} (<span
                                        onClick={() => handleClick(value, bestFarm[3]?.GameId, bestFarm[3]?.gamaName)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {bestFarm[3]?.gamaName}
                                    </span>)</div>
                                    <div id="mostfarminfo5">{bestFarm[4]?.CSM || 'NA'} CSM - {bestFarm[4]?.CS || 'NA'}CS in {bestFarm[4]?.TimePlayed || 'NA'} (<span
                                        onClick={() => handleClick(value, bestFarm[4]?.GameId, bestFarm[4]?.gamaName)}
                                        // onClick={handleClick(value, tournamentData['partie_la_plus_courte']['game_id'])}
                                        style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}
                                    >
                                        {bestFarm[4]?.gamaName}
                                    </span>)</div>
                                </div>
                            </div>
                        </div>








                        <div className="selectionTPBtest3" style={{ border: '#9cb1eb 1px solid' }}>
                            <div className="tournamentData">Top farmer</div>
                            <div className="flexrow">
                                <div style={{ marginRight: '20px' }}>
                                    <div className="txtalignright mt5mb5" id="topfarmname1"><a href={`https://example.com/team/${topFarm[0]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topFarm[0]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topfarmname2"><a href={`https://example.com/team/${topFarm[1]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topFarm[1]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topfarmname3"><a href={`https://example.com/team/${topFarm[2]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topFarm[2]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topfarmname4"><a href={`https://example.com/team/${topFarm[3]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topFarm[3]?.Player || 'N/A'}</a></div>
                                    <div className="txtalignright mt5mb5" id="topfarmname5"><a href={`https://example.com/team/${topFarm[4]?.Player || 'N/A'}`} style={{ textDecoration: 'none' }}>{topFarm[4]?.Player || 'N/A'}</a></div>
                                </div>

                                <div style={{ marginLeft: '20px' }}>
                                    <div className="txtalignleft mt5mb5" id="topfarmvalue1">{topFarm[0]?.CSM || 'NA'} CSM</div>
                                    <div className="txtalignleft mt5mb5" id="topfarmvalue2">{topFarm[1]?.CSM || 'NA'} CSM</div>
                                    <div className="txtalignleft mt5mb5" id="topfarmvalue3">{topFarm[2]?.CSM || 'NA'} CSM</div>
                                    <div className="txtalignleft mt5mb5" id="topfarmvalue4">{topFarm[3]?.CSM || 'NA'} CSM</div>
                                    <div className="txtalignleft mt5mb5" id="topfarmvalue5">{topFarm[4]?.CSM || 'NA'} CSM</div>
                                </div>
                            </div>
                        </div>
                    </div>








                </div>
            </section>
        </div>
    );
}

export default EportOverview;
