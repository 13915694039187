import React from 'react';
import { useNavigate } from 'react-router-dom';


function Heater({ }) {
  const navigate = useNavigate();
  return (
    <div>
        var             Heater
    </div>
  );
}

export default Heater;
