import React from 'react';
import MenuLine from './MenuLine'; 
import { useNavigate } from 'react-router-dom';

function Menu() {
    const navigate = useNavigate();
  return (
        <div className="menu-bar">
            
            <div className="menu">
                <li className="search-box">
                <i className="bx bx-search icon"></i>
                <input type="text" placeholder="Search..." />
                </li>
                <ul className="menu-links">
                <MenuLine href="" iconClass="bx-home" text="Home" />
                <MenuLine href="Tierlist" iconClass="bx bx-stats icon" text="Tierlist" />
                {/* <MenuLine href="" iconClass="bx bx-hard-hat icon" text="Build" /> */}
                {/* <MenuLine href="" iconClass="bx bx-happy-alt icon" text="Profil" /> */}
                <MenuLine href="esport" iconClass="bx bx-chalkboard icon" text="E-Sport" />
                <MenuLine href="analyse/home" iconClass="bx bx-book-content icon" text="Analyse" />
                {/* <MenuLine href="" iconClass="bx bx-sushi icon" text="Scrim" /> */}
                </ul>
            </div>
            <div className="bottom-content">

                <MenuLine href="https://lolprostat.com" iconClass="bx bx-log-in icon" text="Connexion" />
                <li className="mode">
                <div className="sun-moon">
                    <i className="bx bx-moon icon moon"></i>
                    <i className="bx bx-sun icon sun"></i>
                </div>
                <span className="mode-text text">Dark mode</span>
                <div className="toggle-switch">
                    <span className="switch"></span>
                </div>
                </li>
            </div>
        </div>
  );
}

export default Menu;