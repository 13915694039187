import React from 'react';
import '../style/sidebar.css'
import '../style/titre.css'
import '../style/encadre.css'
import '../style/input.css'
import '../style/button.css'
import '../style/composant.css'
import Navbar from '../components/Navbar';
import GrandTitre from '../components/GrandTitre';
import EncadreMain from '../components/EncadreMain';
import EncadreRechercheJoueur from '../components/EncadreRechercheJoueur';
import SautDemarquation from '../components/SautDemarquation';
import { useNavigate } from 'react-router-dom';

function ApofHome({ }) {
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();

        const playerName = e.target.search.value;
        const region = e.target.region.value;

        // Vérifier si le champ n'est pas vide et qu'il contient un '#'
        if (playerName.trim() !== "" && playerName.includes("#")) {
            // Séparer le nom et l'étiquette
            const [name, tag] = playerName.split("#");

            navigate(`/analyse/player?name=${name}&tag=${tag}&region=${region}`);

        } else {
            alert("Enter the name in the right format 'name#tagline'.");
        }
    };

    const handleSubmit2 = (e) => {

    };

    return (
        <div>
            <Navbar />
            <section className="home">
                <div className='Titre'>Deep player analysis</div>
                <div className='debutEncadre95Centre'>
                    <div className="encadre33min300max400">
                        <div className="titreEncadre">Player profile</div>
                        <div className="mt10 w100">
                            <form id="searchForm" onSubmit={handleSubmit} className='flexwrap'>
                                <input
                                    type="text"
                                    placeholder="Search player..."
                                    name="search"
                                    className="input200"
                                    id="searchInput"
                                />
                                <select name="region" id="regionSelect" className="inputSel">
                                    <option value="EUW1">EUW1</option>
                                    <option value="KR">KR</option>
                                    <option value="NA1">NA1</option>
                                    <option value="BR1">BR1</option>
                                    <option value="EUN1">EUN1</option>
                                    <option value="JP1">JP1</option>
                                    <option value="LA1">LA1</option>
                                    <option value="LA2">LA2</option>
                                    <option value="OC1">OC1</option>
                                    <option value="TR1">TR1</option>
                                    <option value="RU">RU</option>
                                    <option value="PH2">PH2</option>
                                    <option value="SG2">SG2</option>
                                    <option value="TH2">TH2</option>
                                    <option value="TW2">TW2</option>
                                    <option value="VN2">VN2</option>
                                </select>
                                <button type="submit" className="boutonClassique ml10">Search</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className='encadre95Left mt10 justifierTexte'>
                    <h2>What is Analyse ?</h2>
                    <p>If you are registered in our database, you can access a detailed analysis of your account. You'll be able to see all your account statistics, including the matchups you play most often, and your playing and invading habits.</p>
                </div>
                <br></br>

                <SautDemarquation />
                <br></br>

                <div className='encadre95Left mt10 justifierTexte'>
                    <h2>You are not in the database ?</h2>
                    <p>If you are not in the database, you can definitevely add your account in our database. The process will take approximately 24 hours, the time our Python script collect all the data and analyse it.</p>
                </div>

                <div className='debutEncadre95Centre'>
                    <div className="encadre33min300max400">
                        {/* <div className="titreEncadre">Player profile</div> */}
                        <div className="mt10 w100">
                            <form id="searchForm" onSubmit={handleSubmit2} className='flexwrap'>
                                <input
                                    type="text"
                                    placeholder="Add your account..."
                                    name="search"
                                    className="input200"
                                    id="searchInput"
                                />
                                <select name="region" id="regionSelect" className="inputSel">
                                    <option value="EUW1">EUW1</option>
                                    <option value="KR">KR</option>
                                    <option value="NA1">NA1</option>
                                    <option value="BR1">BR1</option>
                                    <option value="EUN1">EUN1</option>
                                    <option value="JP1">JP1</option>
                                    <option value="LA1">LA1</option>
                                    <option value="LA2">LA2</option>
                                    <option value="OC1">OC1</option>
                                    <option value="TR1">TR1</option>
                                    <option value="RU">RU</option>
                                    <option value="PH2">PH2</option>
                                    <option value="SG2">SG2</option>
                                    <option value="TH2">TH2</option>
                                    <option value="TW2">TW2</option>
                                    <option value="VN2">VN2</option>
                                </select>
                                <button type="submit" className="boutonClassique ml10">Add!</button>
                            </form>
                        </div>
                    </div>
                </div>






                <br></br>






            </section>

        </div>
    );
}

export default ApofHome;
