import React from 'react';
import './Game.css'
import analyse from '../../img/analyse.png'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';


function Game({ game, puuid, region }) {

    console.log(game)
    var infowin;
    var carachter;
    var sums1;
    var sums2;
    var runeMain;
    var k;
    var d;
    var a;
    var cs;
    var time;
    var itemC = [];
    var chPresent = [];
    var nameP = []
    var tagP = [];
    var gameId = game['metadata']['matchId'];
    for (var j = 0; j < 10; j++) {
        if (game['info']['participants'][j]['puuid'] === puuid) {
            if (game['info']['participants'][j]['win'] === true) {
                infowin = "Win";
            } else {
                infowin = "Loose"
            }
            carachter = game['info']['participants'][j]['championName'];
            sums1 = game['info']['participants'][j]['summoner1Id'];
            sums2 = game['info']['participants'][j]['summoner2Id'];
            runeMain = game['info']['participants'][j]['perks']['styles'][0]['style'];
            k = game['info']['participants'][j]['kills'];
            d = game['info']['participants'][j]['deaths'];
            a = game['info']['participants'][j]['assists'];
            cs = game['info']['participants'][j]['neutralMinionsKilled'] + game['info']['participants'][j]['totalMinionsKilled'];
            time = game['info']['gameDuration'];

            itemC[0] = game['info']['participants'][j]['item0']
            itemC[1] = game['info']['participants'][j]['item1']
            itemC[2] = game['info']['participants'][j]['item2']
            itemC[3] = game['info']['participants'][j]['item3']
            itemC[4] = game['info']['participants'][j]['item4']
            itemC[5] = game['info']['participants'][j]['item5']
            itemC[6] = game['info']['participants'][j]['item6']

            for (let i = 0; i < 10; i++) {
                chPresent[i] = game['info']['participants'][i]['championName'];
                nameP[i] = game['info']['participants'][i]['riotIdGameName'];
                tagP[i] = game['info']['participants'][i]['riotIdTagline'];
            }

        }
    }

    function formatDate(timestamp) {
        const date = new Date(timestamp); // Convertir le timestamp en objet Date

        const day = String(date.getDate()).padStart(2, '0'); // Obtenir le jour avec 2 chiffres
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtenir le mois (les mois commencent à 0, donc +1)
        const year = date.getFullYear(); // Obtenir l'année

        return `${day}/${month}/${year}`; // Retourner la date au format JJ/MM/AAAA
    }

    function formatDuration(seconds) {
        const minutes = Math.floor(seconds / 60); // Obtenir les minutes
        const remainingSeconds = seconds % 60; // Obtenir les secondes restantes

        return `${minutes}min ${remainingSeconds}s`; // Retourner le format XXminXXs
    }



    var timestamp = game['info']['gameCreation'];
    timestamp = formatDate(timestamp);

    time = formatDuration(time);

    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    };
    const images = importAll(require.context('../../img/champion', false, /\.(png|jpe?g|svg)$/));
    const runes = importAll(require.context('../../img/rune', false, /\.(png|jpe?g|svg)$/));
    const sums = importAll(require.context('../../img/sums', false, /\.(png|jpe?g|svg)$/));
    const itemimg = importAll(require.context('../../img/item', false, /\.(png|jpe?g|svg)$/));
    const navigate = useNavigate();

    return (
        <div>
            <div className='Card'>
                <div className='C1'>
                    <div className={infowin === 'Win' ? 'W' : 'L'}>{infowin}</div>
                    <div>Ranked Solo/Duo</div>
                    <div>{timestamp}</div>
                </div>
                <div className='C2'>
                    <div className='C2E1'>
                        <img src={images[`${carachter}.png`]} alt='' className='iconeChamp' />
                    </div>
                    <div className='C2E2'>
                        <img src={runes[`${runeMain}.png`]} alt='' className='runeL' />
                        <img src={sums[`${sums1}.png`]} alt='' className='sums1' />
                        <img src={sums[`${sums2}.png`]} alt='' className='sums2' />
                    </div>
                </div>
                <div className='C3'>
                    <div>{k} / {d} / {a}</div>
                    <div>{cs} CS</div>
                    <div>{time}</div>
                </div>
                <div className='C4'>
                    <div className='C4L1'>
                        <img src={itemimg[`${itemC[0]}.png`]} alt='' className='itemImg' />
                        <img src={itemimg[`${itemC[1]}.png`]} alt='' className='itemImg' />
                        <img src={itemimg[`${itemC[2]}.png`]} alt='' className='itemImg' />
                    </div>
                    <div className='C4L2'>
                        <img src={itemimg[`${itemC[3]}.png`]} alt='' className='itemImg' />
                        <img src={itemimg[`${itemC[4]}.png`]} alt='' className='itemImg' />
                        <img src={itemimg[`${itemC[5]}.png`]} alt='' className='itemImg' />
                    </div>
                    <div className='C4L3'>
                        <img src={itemimg[`${itemC[6]}.png`]} alt='' className='itemImg' />
                    </div>
                </div>
                <div className='C5'>
                    <div className='C5C1'>
                        <div onClick={() => navigate(`/playerprofile?name=${nameP[0]}&tag=${tagP[0]}&region=${region}`)}>{nameP[0]}</div>
                        <div onClick={() => navigate(`/playerprofile?name=${nameP[1]}&tag=${tagP[1]}&region=${region}`)}>{nameP[1]}</div>
                        <div onClick={() => navigate(`/playerprofile?name=${nameP[2]}&tag=${tagP[2]}&region=${region}`)}>{nameP[2]}</div>
                        <div onClick={() => navigate(`/playerprofile?name=${nameP[3]}&tag=${tagP[3]}&region=${region}`)}>{nameP[3]}</div>
                        <div onClick={() => navigate(`/playerprofile?name=${nameP[4]}&tag=${tagP[4]}&region=${region}`)}>{nameP[4]}</div>
                    </div>
                    <div className='C5C2'>
                        <img src={images[`${chPresent[0]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[1]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[2]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[3]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[4]}.png`]} alt='' height={30} />
                    </div>
                    <div className='C5C3' >
                        <img src={images[`${chPresent[5]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[6]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[7]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[8]}.png`]} alt='' height={30} />
                        <img src={images[`${chPresent[9]}.png`]} alt='' height={30} />
                    </div>
                    <div className='C5C4' >
                        <div style={{ textAlign: "right" }} onClick={() => navigate(`/playerprofile?name=${nameP[5]}&tag=${tagP[5]}&region=${region}`)}>{nameP[5]}</div>
                        <div style={{ textAlign: "right" }} onClick={() => navigate(`/playerprofile?name=${nameP[6]}&tag=${tagP[6]}&region=${region}`)}>{nameP[6]}</div>
                        <div style={{ textAlign: "right" }} onClick={() => navigate(`/playerprofile?name=${nameP[7]}&tag=${tagP[7]}&region=${region}`)}>{nameP[7]}</div>
                        <div style={{ textAlign: "right" }} onClick={() => navigate(`/playerprofile?name=${nameP[8]}&tag=${tagP[8]}&region=${region}`)}>{nameP[8]}</div>
                        <div style={{ textAlign: "right" }} onClick={() => navigate(`/playerprofile?name=${nameP[9]}&tag=${tagP[9]}&region=${region}`)}>{nameP[9]}</div>
                    </div>
                </div>
                <div className='C6'>
                    <img src={analyse} alt='' className='h75' onClick={() => navigate(`/analysegame?id=${gameId}&region=${region}`)}/>
                </div>
            </div>
            <br></br>
        </div>
    );
}

export default Game;
