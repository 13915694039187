import React, { useEffect, useState } from 'react';
import '../style/sidebar.css'
import '../style/titre.css'
import '../style/encadre.css'
import '../style/input.css'
import '../style/button.css'
import '../style/composant.css'
import './apofPlayer.css'
import Navbar from '../components/Navbar';
import GrandTitre from '../components/GrandTitre';
import EncadreMain from '../components/EncadreMain';
import EncadreRechercheJoueur from '../components/EncadreRechercheJoueur';
import SautDemarquation from '../components/SautDemarquation';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SpinnerAttente from '../components/SpinnerAttente';
import CarteChampion from '../components/analyse/CarteChampion';
import CarteMatchup from '../components/analyse/CarteMatchup';
import Champion from './analyse/champion';
import HeatMap from './analyse/Heatmap';
import Objective from './analyse/Objective';
import Generalite from './analyse/Generalite';


function ApofPlayer({ }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const name = queryParams.get('name');
    const tag = queryParams.get('tag');
    const region = queryParams.get('region');


    const [playerData, setPlayerData] = useState(null);
    const [puuid, setPuuid] = useState(null);
    const [positionData, setPositionData] = useState(null);
    const [games, setGames] = useState([]); // Liste des parties (games)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [start, setStart] = useState(0); // Pour le décalage des parties à charger
    const [loadingMore, setLoadingMore] = useState(false); // Pour afficher le spinner lors du chargement de plus de parties

    const [selectedButton, setSelectedButton] = useState('Champion');
    const [data, setData] = useState(null);

    const fetchGames = (startValue) => {

        fetch(`https://lolprostat.com:8088/player/getPuuid?name=${name}&tag=${tag}&region=${region}&start=${startValue}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setPuuid(data)
                //Fetch ici et on regarde si le puuid existe dans la table
                fetch(`https://lolprostat.com:8088/analyse/getPresent?puuid=${data}`)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data2) => {
                        if (data2['participants'][0]['COUNT(*)'] === 1) {
                            //L'utilisateur est présent, on réalise un autre FETCH pour récupérer les datas
                            fetch(`https://lolprostat.com:8088/analyse/getInfo?puuid=${data}`)
                                .then((response) => {
                                    if (!response.ok) {
                                        throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                    }
                                    return response.json();
                                })
                                .then((data23) => {
                                    setPlayerData(data23);
                                    // setLoading(false);
                                    // setLoadingMore(false);
                                })
                                .catch((error) => {

                                });

                            fetch(`https://lolprostat.com:8088/analyse/getPosition?puuid=${data}`)
                                .then((response) => {
                                    if (!response.ok) {
                                        throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                    }
                                    return response.json();
                                })
                                .then((data) => {
                                    setPositionData(data);
                                    setLoading(false);
                                    setLoadingMore(false);
                                })
                                .catch((error) => {

                                });

                        }
                        else {
                            //L'utilisateur n'existe pas.
                            setLoading(false);
                            setLoadingMore(false);
                            setError(true);
                        }
                    })
                    .catch((error) => {

                    });

            })
            .catch((error) => {

            });
    };

    useEffect(() => {
        if (name && tag && region) {
            fetchGames(0); // Charger les données initiales avec start = 0
        } else {

        }
    }, [name, tag, region]);

    if (loading) {
        return (
            <div>
                <Navbar />
                <section className="home">
                    <div className='Titre'>Deep player analysis</div>
                    <SpinnerAttente text="player" />
                </section>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <Navbar />
                <section className="home">
                    <div className='Titre'>Deep player analysis</div>
                    Alors la fréro on t'a pas dans la bdd
                </section>
            </div>
        );
    }


    console.log(playerData)
    console.log(positionData)
    const championStats = {};
    const matchupStats = {};

    playerData.participants.forEach(game => {
        const champ = game.champ;
        const win = game.win === 1; // Si win = 1, c'est une victoire
        const opponentChamp = game.opposantChamp;
        const matchupKey = `${champ} vs ${opponentChamp}`;

        // Si le champion n'existe pas encore dans championStats, initialiser ses stats
        if (!championStats[champ]) {
            championStats[champ] = {
                games: 0,
                wins: 0,
                losses: 0,
                kills: 0,
                deaths: 0,
                assists: 0,
                dpm: 0,
                gpm: 0,
                kp: 0,
            };
        }
        if (!matchupStats[matchupKey]) {
            matchupStats[matchupKey] = {
                games: 0,
                wins: 0,
                losses: 0,
                kills: 0,
                deaths: 0,
                assists: 0,
                dpm: 0,
                gpm: 0,
                kp: 0,
            };
        }

        // Mettre à jour les stats du champion
        championStats[champ].games += 1;
        championStats[champ].kills += game.kills;
        championStats[champ].deaths += game.death;
        championStats[champ].assists += game.assist;
        championStats[champ].dpm += game.damagePerMinute;
        championStats[champ].gpm += game.goldPerMinute;
        championStats[champ].kp += game.killParticipation;

        if (win) {
            championStats[champ].wins += 1;
        } else {
            championStats[champ].losses += 1;
        }

        matchupStats[matchupKey].games += 1;
        matchupStats[matchupKey].kills += game.kills;
        matchupStats[matchupKey].deaths += game.death;
        matchupStats[matchupKey].assists += game.assist;
        matchupStats[matchupKey].dpm += game.damagePerMinute;
        matchupStats[matchupKey].gpm += game.goldPerMinute;
        matchupStats[matchupKey].kp += game.killParticipation;

        if (win) {
            matchupStats[matchupKey].wins += 1;
        } else {
            matchupStats[matchupKey].losses += 1;
        }
    });


    const championDetails = [];
    for (const champ in championStats) {
        const { games, wins, losses, kills, deaths, assists, dpm, gpm, kp } = championStats[champ];
        const winrate = (wins / games) * 100;
        const kda = deaths === 0 ? (kills + assists) : (kills + assists) / deaths;

        // Pousser les détails dans un tableau
        championDetails.push({
            champion: champ,
            games,
            wins,
            losses,
            winrate: winrate.toFixed(0), // Pourcentage avec 2 décimales
            KDA: kda.toFixed(2), // KDA avec 2 décimales^
            kills: (kills / games).toFixed(1),
            deaths: (deaths / games).toFixed(1),
            assists: (assists / games).toFixed(1),
            dpm: (dpm / games).toFixed(0),
            gpm: (gpm / games).toFixed(0),
            kp: ((kp / games) * 100).toFixed(1)
        });
    }

    // Trier par nombre de parties (games) en ordre décroissant
    championDetails.sort((a, b) => b.games - a.games);


    const matchupDetails = [];
    for (const matchup in matchupStats) {
        const { games, wins, losses, kills, deaths, assists, dpm, gpm, kp } = matchupStats[matchup];

        // Garder seulement les matchups avec plus de 3 games
        if (games > 3) {
            const winrate = (wins / games) * 100;
            const kda = deaths === 0 ? (kills + assists) : (kills + assists) / deaths;

            // Pousser les détails dans un tableau
            matchupDetails.push({
                matchup, // Ce sera sous la forme "Champion vs OpponentChampion"
                games,
                wins,
                losses,
                winrate: winrate.toFixed(0), // Pourcentage avec 2 décimales
                KDA: kda.toFixed(2),
                kills: (kills / games).toFixed(1),
                deaths: (deaths / games).toFixed(1),
                assists: (assists / games).toFixed(1),
                dpm: (dpm / games).toFixed(0),
                gpm: (gpm / games).toFixed(0),
                kp: ((kp / games) * 100).toFixed(1)
            });
        }
    }

    // Trier par nombre de games (optionnel, similaire à ce que tu voulais avant)
    matchupDetails.sort((a, b) => b.games - a.games);

    console.log(championDetails);
    console.log(matchupDetails);






    const heatmapData = positionData['participants'].map(participant => {
        const position = JSON.parse(participant.positionM1);
        return { x: position.x, y: position.y };
    });

    console.log(heatmapData)









    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    };
    const images = importAll(require.context('../img/champion', false, /\.(png|jpe?g|svg)$/));

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        // Vous pouvez ajouter ici la logique pour la navigation si nécessaire
    };

    const renderComponent = () => {
        switch (selectedButton) {
            case 'Champion':
                return <Champion championSS={championDetails} matchupSS={matchupDetails} puuid={puuid} />;
            case 'Heatmap':
                return <HeatMap participants={positionData} />;
            case 'Objective':
                return <Objective puuid={puuid}/>;
            case 'Generalite':
                return <Generalite puuid={puuid}/>
            default:
                return <Champion championSS={championDetails} matchupSS={matchupDetails}  puuid={puuid}/>;  // Option par défaut
        }
    };


    return (
        <div>
            <Navbar />
            <section className="home">
                <div className='Titre'>Deep player analysis - {name}#{tag}</div>

                <div className='centerHome' style={{ marginBottom: "30px" }}>
                    <div className='hello95' style={{ padding: "5px" }}>
                        <button
                            className={`btn2 mr30 ${selectedButton === 'Champion' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('Champion')}
                        >
                            Champion / Matchup
                        </button>
                        <button
                            className={`btn2 mr30 ${selectedButton === 'Generalite' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('Generalite')}
                        >
                            Generalite
                        </button>
                        <button
                            className={`btn2 mr30 ${selectedButton === 'Heatmap' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('Heatmap')}
                        >
                            HeatMap
                        </button>
                        <button
                            className={`btn2 mr30 ${selectedButton === 'Objective' ? 'selected' : ''}`}
                            onClick={() => handleButtonClick('Objective')}
                        >
                            Objective
                        </button>
                    </div>
                </div>
                <div>
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: {error}</p>}
                    {!loading && !error && renderComponent()}
                </div>


            </section>

        </div>
    );
}

export default ApofPlayer;
