import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Preview from '../components/Preview';
import Game from '../components/Game';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';

function EportBO({ }) {
    
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const to = queryParams.get('to');
    const mid = queryParams.get('mid');
    const equipe = queryParams.get('equipe');
    // const { to, mid } = location.state || {};

    const [activeView, setActiveView] = useState(''); // State to manage active view
    const [numberOfGames, setNumberOfGames] = useState(0); // State to store the number of games
    const [rid, setRid] = useState([]); // State to store the array of RiotPlatformGameId
    const [selectedRid, setSelectedRid] = useState(null); // State to store the selected RiotPlatformGameId
    const [loading, setLoading] = useState(true); // State to handle loading state

    // Function to handle button click and change the active view
    const handleButtonClick = (view, selectedGameRid) => {
        setActiveView(view); // Set the active view based on button clicked
        setSelectedRid(selectedGameRid); // Store the selected RiotPlatformGameId
    };

    // Function to fetch the number of games from the API
    useEffect(() => {
        const fetchNumberOfGames = async () => {
            try {
                const response = await fetch(`https://lolprostat.com:8088/tournamentNbrGame?to=${to}&mid=${mid}`);
                const data = await response.json();
                setNumberOfGames(data['participants'].length);
                setRid(data['participants']); // Assuming 'participants' contains the array of RiotPlatformGameId
                setLoading(false); // Set loading to false after fetching the data
            } catch (error) {
                console.error("Error fetching number of games:", error);
                setLoading(false); // Set loading to false even if there's an error
            }
        };

        fetchNumberOfGames(); // Call the function when the component mounts
    }, []); // Empty dependency array means this will run only once when the component mounts

    // Generate an array of game buttons based on the number of games
    const renderGameButtons = () => {
        const buttons = [];
        for (let i = 0; i < numberOfGames; i++) { // Adjusted index starting from 0 for rid[i]
            if (rid[i] && rid[i]['RiotPlatformGameId']) {
                buttons.push(
                    <button key={i} className="btn mlr10" onClick={() => handleButtonClick('Game', rid[i]['RiotPlatformGameId'])}>
                        Game {i + 1}
                    </button>
                );
            }
        }
        return buttons;
    };

    return (
        <div>
            <Navbar />
            <section className="home">
                <div className="text">{mid} -  {equipe}</div>

                <div className="encadre95Left cl233">
                    {/* Show loading text if still fetching data */}
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            <button className="btn mr30" onClick={() => handleButtonClick('Preview')}>Preview</button>
                            {renderGameButtons()} {/* Dynamically generated game buttons */}
                            <button className="btn mlr10" onClick={() => handleButtonClick('Summary')}>Summary</button>
                        </>
                    )}
                </div>

                {/* Conditional rendering based on active view */}
                {activeView === 'Preview' && <Preview />}
                {activeView === 'Game' && selectedRid && <Game to={to} rid={selectedRid} />}
                {/* You can add other conditions for different views as needed */}
            </section>
        </div>
    );
}

export default EportBO;
