import React from 'react';
import { useNavigate } from 'react-router-dom';

function EncadreRechercheJoueur() {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const playerName = e.target.search.value;
    const region = e.target.region.value;

    // Vérifier si le champ n'est pas vide et qu'il contient un '#'
    if (playerName.trim() !== "" && playerName.includes("#")) {
      // Séparer le nom et l'étiquette
      const [name, tag] = playerName.split("#");

      navigate(`/playerprofile?name=${name}&tag=${tag}&region=${region}`);

    } else {
      alert("Enter the name in the right format 'name#tagline'.");
    }
  };

  return (
    <div className="encadre33min300max400">
      <div className="titreEncadre">Player profile</div>
      <div className="mt10 w100">
        <form id="searchForm" onSubmit={handleSubmit} className='flexwrap'>
          <input
            type="text"
            placeholder="Search player..."
            name="search"
            className="input200"
            id="searchInput"
          />
          <select name="region" id="regionSelect" className="inputSel">
            <option value="EUW1">EUW1</option>
            <option value="KR">KR</option>
            <option value="NA1">NA1</option>
            <option value="BR1">BR1</option>
            <option value="EUN1">EUN1</option>
            <option value="JP1">JP1</option>
            <option value="LA1">LA1</option>
            <option value="LA2">LA2</option>
            <option value="OC1">OC1</option>
            <option value="TR1">TR1</option>
            <option value="RU">RU</option>
            <option value="PH2">PH2</option>
            <option value="SG2">SG2</option>
            <option value="TH2">TH2</option>
            <option value="TW2">TW2</option>
            <option value="VN2">VN2</option>
          </select>
          <button type="submit" className="boutonClassique ml10">Search</button>
        </form>
      </div>
    </div>
  );
}

export default EncadreRechercheJoueur;
