import React from 'react';
import Navbar from '../components/Navbar'
import EncadreRechercheEport from '../components/EncadreRechercheEport'
import SautDemarquation from '../components/SautDemarquation';
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';

function Eport({ href, iconClass, text }) {
  return (
    <div>
        <Navbar />
        <section className="home">
            <div className="text">Eport hub</div>
            <div className='debutEncadre95Centre'>
                <EncadreRechercheEport />
            </div>

            <SautDemarquation />

            <div className='encadre95Left mt10 justifierTexte'>
                <h2>Subtitle</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>

            <div className='encadre95Left mt10 justifierTexte'>
                <h2>Featuring tournament</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
        </section>
    </div>
  );
}

export default Eport;
