import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Objective({ puuid }) {

    const [loading, setLoading] = useState(true);

    const [solo, setSolo] = useState(null);
    const [mine, setMine] = useState(null);
    const [victoriesCount, setVictoriesCount] = useState({});
    const [defeatsCount, setDefeatsCount] = useState({});
    const [data, setData] = useState(null);
    const [options, setOptions] = useState({});

    const [playerTG, setPlayerTG] = useState(null);
    const [soloTG, setSoloTG] = useState(null);
    const [tgtemp, setTgtemp] = useState(null);
    const [dataTG, setDataTG] = useState(null);
    const [optionsTG, setOptionsTG] = useState({});

    const [playerTower, setPlayerTower] = useState(null);
    const [soloTower, setSoloTower] = useState(null);
    const [dataTower, setDataTower] = useState(null);
    const [optionTower, setOptionTower] = useState({});
    const [victoriesCountTower, setVictoriesCountTower] = useState({});
    const [defeatsCountTower, setDefeatsCountTower] = useState({});

    const [playerBaron, setPlayerBaron] = useState(null);
    const [soloBaron, setSoloBaron] = useState(null);
    const [dataBaron, setDataBaron] = useState(null);
    const [optionBaron, setOptionBaron] = useState({});
    const [victoriesCountBaron, setVictoriesCountBaron] = useState({});
    const [defeatsCountBaron, setDefeatsCountBaron] = useState({});

    const [playerDragon, setPlayerDragon] = useState(null);
    const [soloDragon, setSoloDragon] = useState(null);
    const [dataDragon, setDataDragon] = useState(null);
    const [optionDragon, setOptionDragon] = useState({});
    const [victoriesCountDragon, setVictoriesCountDragon] = useState({});
    const [defeatsCountDragon, setDefeatsCountDragon] = useState({});

    const [playerHerald, setPlayerHerald] = useState(null);
    const [soloHerald, setSoloHerald] = useState(null);
    const [dataHerald, setDataHerald] = useState(null);
    const [optionHerald, setOptionHerald] = useState({});
    const [victoriesCountHerald, setVictoriesCountHerald] = useState({});
    const [defeatsCountHerald, setDefeatsCountHerald] = useState({});

    const [playerTpsGrubs, setPlayerTpsGrubs] = useState(null);
    const [soloTpsGrubs, setSoloTpsGrubs] = useState(null);
    const [dataTempTpsGrubs, setDataTempTpsGrubs] = useState(null);
    const [dataTpsGrubs, setDataTpsGrubs] = useState(null);
    const [optionTpsGrubs, setOptionTpsGrubs] = useState({});

    




    const fetchGames = (puuid) => {

        fetch(`https://lolprostat.com:8088/data/getGrubs`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setMine(data);
                fetch(`https://lolprostat.com:8088/analyse/getGrubs?puuid=${puuid}`)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setSolo(data);
                        fetch(`https://lolprostat.com:8088/data/getTowerGrubs`)
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                }
                                return response.json();
                            })
                            .then((data) => {
                                setSoloTG(data);
                                fetch(`https://lolprostat.com:8088/analyse/getTowerGrubs?puuid=${puuid}`)
                                    .then((response) => {
                                        if (!response.ok) {
                                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                        }
                                        return response.json();
                                    })
                                    .then((data) => {
                                        setPlayerTG(data);
                                        fetch(`https://lolprostat.com:8088/data/getTower`)
                                            .then((response) => {
                                                if (!response.ok) {
                                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                }
                                                return response.json();
                                            })
                                            .then((data) => {
                                                setSoloTower(data);
                                                fetch(`https://lolprostat.com:8088/analyse/getTower?puuid=${puuid}`)
                                                    .then((response) => {
                                                        if (!response.ok) {
                                                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                        }
                                                        return response.json();
                                                    })
                                                    .then((data) => {
                                                        setPlayerTower(data);
                                                        fetch(`https://lolprostat.com:8088/data/getBaron`)
                                                            .then((response) => {
                                                                if (!response.ok) {
                                                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                }
                                                                return response.json();
                                                            })
                                                            .then((data) => {
                                                                setSoloBaron(data);
                                                                fetch(`https://lolprostat.com:8088/analyse/getBaron?puuid=${puuid}`)
                                                                    .then((response) => {
                                                                        if (!response.ok) {
                                                                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                        }
                                                                        return response.json();
                                                                    })
                                                                    .then((data) => {
                                                                        setPlayerBaron(data);
                                                                        fetch(`https://lolprostat.com:8088/data/getDragon`)
                                                                            .then((response) => {
                                                                                if (!response.ok) {
                                                                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                                }
                                                                                return response.json();
                                                                            })
                                                                            .then((data) => {
                                                                                setSoloDragon(data);
                                                                                fetch(`https://lolprostat.com:8088/analyse/getDragon?puuid=${puuid}`)
                                                                                    .then((response) => {
                                                                                        if (!response.ok) {
                                                                                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                                        }
                                                                                        return response.json();
                                                                                    })
                                                                                    .then((data) => {
                                                                                        setPlayerDragon(data);
                                                                                        fetch(`https://lolprostat.com:8088/data/getHerald`)
                                                                                            .then((response) => {
                                                                                                if (!response.ok) {
                                                                                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                                                }
                                                                                                return response.json();
                                                                                            })
                                                                                            .then((data) => {
                                                                                                setSoloHerald(data);
                                                                                                fetch(`https://lolprostat.com:8088/analyse/getHerald?puuid=${puuid}`)
                                                                                                    .then((response) => {
                                                                                                        if (!response.ok) {
                                                                                                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                                                        }
                                                                                                        return response.json();
                                                                                                    })
                                                                                                    .then((data) => {
                                                                                                        setPlayerHerald(data);
                                                                                                        fetch(`https://lolprostat.com:8088/data/getTpsGrubs`)
                                                                                                            .then((response) => {
                                                                                                                if (!response.ok) {
                                                                                                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                                                                }
                                                                                                                return response.json();
                                                                                                            })
                                                                                                            .then((data) => {
                                                                                                                setSoloTpsGrubs(data);
                                                                                                                fetch(`https://lolprostat.com:8088/analyse/getTpsGrubs?puuid=${puuid}`)
                                                                                                                    .then((response) => {
                                                                                                                        if (!response.ok) {
                                                                                                                            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                                                                                                        }
                                                                                                                        return response.json();
                                                                                                                    })
                                                                                                                    .then((data) => {
                                                                                                                        console.log("le fetch donne", data)
                                                                                                                        setPlayerTpsGrubs(data);
                                                                                                                        setLoading(false);


                                                                                                                    })
                                                                                                                    .catch((error) => {

                                                                                                                    });


                                                                                                            })
                                                                                                            .catch((error) => {

                                                                                                            });


                                                                                                    })
                                                                                                    .catch((error) => {

                                                                                                    });

                                                                                            })
                                                                                            .catch((error) => {

                                                                                            });

                                                                                    })
                                                                                    .catch((error) => {

                                                                                    });

                                                                            })
                                                                            .catch((error) => {

                                                                            });

                                                                    })
                                                                    .catch((error) => {

                                                                    });

                                                            })
                                                            .catch((error) => {

                                                            });

                                                    })
                                                    .catch((error) => {

                                                    });

                                            })
                                            .catch((error) => {

                                            });

                                    })
                                    .catch((error) => {

                                    });

                            })
                            .catch((error) => {

                            });

                    })
                    .catch((error) => {

                    });

            })
            .catch((error) => {

            });
    };

    useEffect(() => {
        if (puuid) {
            // console.log("Appel de fetchGames avec puuid :", puuid);
            fetchGames(puuid);
        }
    }, [puuid]);

   


    useEffect(() => {
        if (playerTpsGrubs && playerTpsGrubs.participants) {
            const groupes = playerTpsGrubs.participants.reduce((acc, participant) => {
                const { hordeK, duree } = participant;
                if (!acc[hordeK]) {
                    acc[hordeK] = [];
                }
                acc[hordeK].push(duree);
                return acc;
            }, {});
    
            const moyennes = Object.entries(groupes).map(([hordeK, durees]) => {
                const somme = durees.reduce((total, duree) => total + duree, 0);
                const moyenne = somme / durees.length;
                return { hordeK: Number(hordeK), moyenne };
            });
    
            console.log('Moyennes calculées:', moyennes); // Vérification des données
            setDataTempTpsGrubs(moyennes);
        }
    }, [playerTpsGrubs]);



    useEffect(() => {
        if (solo && solo.participants) {
            const victories = {};
            const defeats = {};

            solo.participants.forEach(({ hordeK, win }) => {
                if (win) {
                    victories[hordeK] = (victories[hordeK] || 0) + 1;
                } else {
                    defeats[hordeK] = (defeats[hordeK] || 0) + 1;
                }
            });

            setVictoriesCount(victories);
            setDefeatsCount(defeats);
        }



    }, [solo]);

    useEffect(() => {
        if (playerTG && playerTG.participants) {
            const grubData = {};

            playerTG.participants.forEach(({ towerK, hordeK }) => {
                if (!grubData[hordeK]) {
                    grubData[hordeK] = { totalTowerK: 0, count: 0 };
                }
                grubData[hordeK].totalTowerK += towerK;
                grubData[hordeK].count += 1;
            });

            const averageTowerKByGrub = {};
            for (const grub in grubData) {
                averageTowerKByGrub[grub] = grubData[grub].totalTowerK / grubData[grub].count;
            }
            setTgtemp(averageTowerKByGrub);
        }
    }, [playerTG]);

    useEffect(() => {
        if (playerTower && playerTower.participants) {
            // Initialisation des compteurs de victoires et défaites
            const victories = {};
            const defeats = {};

            playerTower.participants.forEach(({ towerK, win }) => {
                if (win) {
                    victories[towerK] = (victories[towerK] || 0) + 1;
                } else {
                    defeats[towerK] = (defeats[towerK] || 0) + 1;
                }
            });

            setVictoriesCountTower(victories);
            setDefeatsCountTower(defeats);
        }
    }, [playerTower]);

    useEffect(() => {

        if (playerBaron && playerBaron.participants) {
            // Initialisation des compteurs de victoires et défaites
            const victories = {};
            const defeats = {};

            playerBaron.participants.forEach(({ baronK, win }) => {
                if (win) {
                    victories[baronK] = (victories[baronK] || 0) + 1;
                } else {
                    defeats[baronK] = (defeats[baronK] || 0) + 1;
                }
            });

            setVictoriesCountBaron(victories);
            setDefeatsCountBaron(defeats);
        }

        if (playerDragon && playerDragon.participants) {
            // Initialisation des compteurs de victoires et défaites
            const victories = {};
            const defeats = {};

            playerDragon.participants.forEach(({ dragonK, win }) => {
                if (win) {
                    victories[dragonK] = (victories[dragonK] || 0) + 1;
                } else {
                    defeats[dragonK] = (defeats[dragonK] || 0) + 1;
                }
            });

            setVictoriesCountDragon(victories);
            setDefeatsCountDragon(defeats);
        }

        if (playerHerald && playerHerald.participants) {
            // Initialisation des compteurs de victoires et défaites
            const victories = {};
            const defeats = {};

            playerHerald.participants.forEach(({ heraldK, win }) => {
                if (win) {
                    victories[heraldK] = (victories[heraldK] || 0) + 1;
                } else {
                    defeats[heraldK] = (defeats[heraldK] || 0) + 1;
                }
            });

            setVictoriesCountHerald(victories);
            setDefeatsCountHerald(defeats);
        }
    }, [solo, playerTG, playerTower, playerBaron, playerDragon, playerHerald]);






    useEffect(() => {
        if (solo && mine && victoriesCount && defeatsCount) {
            // Calcul du winRateByGrubsKilled
            const winRateByGrubsKilled = {};
            const allGrubsKilledCounts = new Set([
                ...Object.keys(victoriesCount),
                ...Object.keys(defeatsCount),
            ]);

            allGrubsKilledCounts.forEach(grubsKilled => {
                const totalGames = (victoriesCount[grubsKilled] || 0) + (defeatsCount[grubsKilled] || 0);
                winRateByGrubsKilled[grubsKilled] = (victoriesCount[grubsKilled] || 0) / totalGames;
            });

            // Transformation des données de mine pour SoloResult
            const transformedData = mine.participants.reduce((acc, { grubs_killed, win_rate }) => {
                acc[grubs_killed] = win_rate;
                return acc;
            }, {});

            const SoloResult = Object.fromEntries(
                Object.entries(transformedData).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );

            const JoueurResult = Object.fromEntries(
                Object.entries(winRateByGrubsKilled).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );


            const labels = Object.keys(JoueurResult); // Labels de 0 à 6
            console.log('1', labels);

            // Configuration des données pour Chart.js
            const chartData = {
                labels,
                datasets: [
                    {
                        label: 'SoloQ',
                        data: Object.values(SoloResult),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Couleur des barres globales
                    },
                    {
                        label: 'Player',
                        data: Object.values(JoueurResult),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)', // Couleur des barres joueur
                    },
                ],
            };

            setData(chartData);

            const chartOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false, // Le titre principal reste masqué
                        text: 'Winrate en fonction du nombre de grubs tués',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Nombre de Grubs Tués',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Winrate (%)',
                        },
                    },
                },
            };

            setOptions(chartOptions);
        }
    }, [solo, mine, victoriesCount, defeatsCount]);

    useEffect(() => {
        if (soloTG && tgtemp) {
            const soloQtransformedData = soloTG.participants.reduce((acc, participant) => {
                acc[participant.grubs_killed] = participant.avg_tower_destroyed;
                return acc;
            }, {});



            const labels = Object.keys(soloQtransformedData);
            console.log('2', labels);

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'SoloQ',
                        data: Object.values(soloQtransformedData),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    },
                    {
                        label: 'Player',
                        data: Object.values(tgtemp),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    }
                ],
            };

            setDataTG(data);

            const options = {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false, // Le titre principal reste masqué
                        text: 'Winrate en fonction du nombre de grubs tués',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Nombre de Grubs Tués',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Nombre de tours détruites',
                        },
                    },
                },
            };

            setOptionsTG(options);
        }


    }, [soloTG, tgtemp]);

    useEffect(() => {
        if (soloTower && playerTower && victoriesCountTower && defeatsCountTower) {
            // Calcul du winRateByGrubsKilled
            const winRateByGrubsKilled = {};
            const allGrubsKilledCounts = new Set([
                ...Object.keys(victoriesCountTower),
                ...Object.keys(defeatsCountTower),
            ]);

            allGrubsKilledCounts.forEach(towerKilled => {
                const totalGames = (victoriesCountTower[towerKilled] || 0) + (defeatsCountTower[towerKilled] || 0);
                winRateByGrubsKilled[towerKilled] = (victoriesCountTower[towerKilled] || 0) / totalGames;
            });

            // Transformation des données de mine pour SoloResult
            const transformedData = soloTower.participants.reduce((acc, { towers_killed, win_rate }) => {
                acc[towers_killed] = win_rate;
                return acc;
            }, {});

            const SoloResult = Object.fromEntries(
                Object.entries(transformedData).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );

            const JoueurResult = Object.fromEntries(
                Object.entries(winRateByGrubsKilled).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );


            const labels = Object.keys(JoueurResult); // Labels de 0 à 6
            console.log('3', labels);

            // Configuration des données pour Chart.js
            const chartData = {
                labels,
                datasets: [
                    {
                        label: 'SoloQ',
                        data: Object.values(SoloResult),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Couleur des barres globales
                    },
                    {
                        label: 'Player',
                        data: Object.values(JoueurResult),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)', // Couleur des barres joueur
                    },
                ],
            };

            setDataTower(chartData);

            const chartOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false, // Le titre principal reste masqué
                        text: 'Winrate en fonction du nombre de grubs tués',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Nombre de tours détruites',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Winrate (%)',
                        },
                    },
                },
            };

            setOptionTower(chartOptions);
        }
    }, [soloTower, playerTower, victoriesCountTower, defeatsCountTower]);

    useEffect(() => {
        if (soloBaron && playerBaron && victoriesCountBaron && defeatsCountBaron) {
            // Calcul du winRateByGrubsKilled
            const winRateByGrubsKilled = {};
            const allGrubsKilledCounts = new Set([
                ...Object.keys(victoriesCountBaron),
                ...Object.keys(defeatsCountBaron),
            ]);

            allGrubsKilledCounts.forEach(BaronKilled => {
                const totalGames = (victoriesCountBaron[BaronKilled] || 0) + (defeatsCountBaron[BaronKilled] || 0);
                winRateByGrubsKilled[BaronKilled] = (victoriesCountBaron[BaronKilled] || 0) / totalGames;
            });

            // Transformation des données de mine pour SoloResult
            const transformedData = soloBaron.participants.reduce((acc, { baron_killed, win_rate }) => {
                acc[baron_killed] = win_rate;
                return acc;
            }, {});

            const SoloResult = Object.fromEntries(
                Object.entries(transformedData).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );

            const JoueurResult = Object.fromEntries(
                Object.entries(winRateByGrubsKilled).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );


            const labels = Object.keys(JoueurResult); // Labels de 0 à 6
            console.log('4', labels);

            // Configuration des données pour Chart.js
            const chartData = {
                labels,
                datasets: [
                    {
                        label: 'SoloQ',
                        data: Object.values(SoloResult),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Couleur des barres globales
                    },
                    {
                        label: 'Player',
                        data: Object.values(JoueurResult),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)', // Couleur des barres joueur
                    },
                ],
            };

            setDataBaron(chartData);

            const chartOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false, // Le titre principal reste masqué
                        text: 'Winrate en fonction du nombre de grubs tués',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Nombre de Nashor tués',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Winrate (%)',
                        },
                    },
                },
            };

            setOptionBaron(chartOptions);
        }
    }, [soloBaron, playerBaron, victoriesCountBaron, defeatsCountBaron]);

    useEffect(() => {
        if (soloDragon && playerDragon && victoriesCountDragon && defeatsCountDragon) {
            // Calcul du winRateByGrubsKilled
            const winRateByGrubsKilled = {};
            const allGrubsKilledCounts = new Set([
                ...Object.keys(victoriesCountDragon),
                ...Object.keys(defeatsCountDragon),
            ]);

            allGrubsKilledCounts.forEach(DragonKilled => {
                const totalGames = (victoriesCountDragon[DragonKilled] || 0) + (defeatsCountDragon[DragonKilled] || 0);
                winRateByGrubsKilled[DragonKilled] = (victoriesCountDragon[DragonKilled] || 0) / totalGames;
            });

            // Transformation des données de mine pour SoloResult
            const transformedData = soloDragon.participants.reduce((acc, { dragon_killed, win_rate }) => {
                acc[dragon_killed] = win_rate;
                return acc;
            }, {});

            const SoloResult = Object.fromEntries(
                Object.entries(transformedData).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );

            const JoueurResult = Object.fromEntries(
                Object.entries(winRateByGrubsKilled).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );


            const labels = Object.keys(JoueurResult); // Labels de 0 à 6
            console.log('5', labels);

            // Configuration des données pour Chart.js
            const chartData = {
                labels,
                datasets: [
                    {
                        label: 'SoloQ',
                        data: Object.values(SoloResult),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Couleur des barres globales
                    },
                    {
                        label: 'Player',
                        data: Object.values(JoueurResult),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)', // Couleur des barres joueur
                    },
                ],
            };

            setDataDragon(chartData);

            const chartOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false, // Le titre principal reste masqué
                        text: 'Winrate en fonction du nombre de grubs tués',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Nombre de Dragon tués',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Winrate (%)',
                        },
                    },
                },
            };

            setOptionDragon(chartOptions);
        }
    }, [soloDragon, playerDragon, victoriesCountDragon, defeatsCountDragon]);

    useEffect(() => {
        if (soloHerald && playerHerald && victoriesCountHerald && defeatsCountHerald) {
            // Calcul du winRateByGrubsKilled
            const winRateByGrubsKilled = {};
            const allGrubsKilledCounts = new Set([
                ...Object.keys(victoriesCountHerald),
                ...Object.keys(defeatsCountHerald),
            ]);

            allGrubsKilledCounts.forEach(HeraldKilled => {
                const totalGames = (victoriesCountHerald[HeraldKilled] || 0) + (defeatsCountHerald[HeraldKilled] || 0);
                winRateByGrubsKilled[HeraldKilled] = (victoriesCountHerald[HeraldKilled] || 0) / totalGames;
            });

            // Transformation des données de mine pour SoloResult
            const transformedData = soloHerald.participants.reduce((acc, { herald_killed, win_rate }) => {
                acc[herald_killed] = win_rate;
                return acc;
            }, {});

            const SoloResult = Object.fromEntries(
                Object.entries(transformedData).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );

            const JoueurResult = Object.fromEntries(
                Object.entries(winRateByGrubsKilled).map(([key, value]) => [
                    key,
                    parseFloat((value * 100).toFixed(1)),
                ])
            );


            const labels = Object.keys(JoueurResult); // Labels de 0 à 6
            console.log('6', labels);

            // Configuration des données pour Chart.js
            const chartData = {
                labels,
                datasets: [
                    {
                        label: 'SoloQ',
                        data: Object.values(SoloResult),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Couleur des barres globales
                    },
                    {
                        label: 'Player',
                        data: Object.values(JoueurResult),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)', // Couleur des barres joueur
                    },
                ],
            };

            setDataHerald(chartData);

            const chartOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false, // Le titre principal reste masqué
                        text: 'Winrate en fonction du nombre de grubs tués',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Nombre de Herald tués',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Winrate (%)',
                        },
                    },
                },
            };

            setOptionHerald(chartOptions);
        }
    }, [soloHerald, playerHerald, victoriesCountHerald, defeatsCountHerald]);
    console.log('alors', soloTpsGrubs)
    console.log('alors2', dataTempTpsGrubs)
    useEffect(() => {
        if (soloTpsGrubs && dataTempTpsGrubs) {
            const labels = dataTempTpsGrubs.map(item => `${item.hordeK}`);
            console.log('7', labels);
            const data1 = dataTempTpsGrubs.map(item => item.moyenne);
            // console.log('data1', data1);
            const data2 = soloTpsGrubs.participants.map(item => item.time);
            // console.log('data2', data2);

            if(labels){
                // Configuration des données pour Chart.js
            const chartData = {
                labels,
                datasets: [
                    {
                        label: 'SoloQ',
                        data: Object.values(data2),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Couleur des barres globales
                    },
                    {
                        label: 'Player',
                        data: Object.values(data1),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)', // Couleur des barres joueur
                    },
                ],
            };

            setDataTpsGrubs(chartData);

            const chartOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false, // Le titre principal reste masqué
                        text: 'Winrate en fonction du nombre de grubs tués',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Nombre de grubs tués',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Durée moyenne des parties (s)',
                        },
                    },
                },
            };

            setOptionTpsGrubs(chartOptions);
            }

            
        }
    }, [soloTpsGrubs, dataTempTpsGrubs]);


    if (loading) {
        return (
            <div>

            </div>
        );
    }

    return (
        <div className='centerHome'>
            <div className='tempnamess'>
                <div className='graphOk'>
                    <div className='Titlebaza'>Winrate en fonction du nombre de grubs tués</div>
                    {data ? (
                        <Bar data={data} options={options} />
                    ) : (
                        <p>Chargement des données...</p>
                    )}
                </div>

                <div className='graphOk'>
                    <div className='Titlebaza'>Nombre de tours détruites en fonction du nombre de grubs tués</div>
                    {dataTG ? (
                        <Bar data={dataTG} options={optionsTG} />
                    ) : (
                        <p>Chargement des données...</p>
                    )}
                </div>

                <div className='graphOk'>
                    <div className='Titlebaza'>Winrate en fonction du nombre de tours détruites</div>
                    {dataTG ? (
                        <Bar data={dataTower} options={optionTower} />
                    ) : (
                        <p>Chargement des données...</p>
                    )}
                </div>

                <div className='graphOk'>
                    <div className='Titlebaza'>Winrate en fonction du nombre de Dragon tués</div>
                    {dataTG ? (
                        <Bar data={dataDragon} options={optionDragon} />
                    ) : (
                        <p>Chargement des données...</p>
                    )}
                </div>

                <div className='graphOk'>
                    <div className='Titlebaza'>Winrate en fonction du nombre de Nashor tués</div>
                    {dataTG ? (
                        <Bar data={dataBaron} options={optionBaron} />
                    ) : (
                        <p>Chargement des données...</p>
                    )}
                </div>

                <div className='graphOk'>
                    <div className='Titlebaza'>Winrate en fonction du nombre de Herald tués</div>
                    {dataTG ? (
                        <Bar data={dataHerald} options={optionHerald} />
                    ) : (
                        <p>Chargement des données...</p>
                    )}
                </div>

                <div className='graphOk'>
                    <div className='Titlebaza'>Durée moyenne des parties en fonction du nombre de grubs tués</div>
                    {dataTpsGrubs && optionTpsGrubs  ? (
                        <Bar data={dataTpsGrubs} options={optionTpsGrubs} />
                    ) : (
                        <p>Chargement des données...</p>
                    )}
                </div>
               {/* */}
            </div>

        </div>
    );
}

export default Objective;
