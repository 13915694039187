import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Summary from '../EsportGame/Summary';
import AllStats from './AllStats';
import Bubble from './Chart/Bubble';
import Combined from './Chart/Combined';
import Donut from './Chart/Donut';
import Hbar from './Chart/Hbar';
import Heater from './Chart/Heater';
import Line from './Chart/Line';
import Pie from './Chart/Pie';
import Polar from './Chart/Polar';
import Radar from './Chart/Radar';
import Scatter from './Chart/Scatter';
import Vbar from './Chart/Vbar';
import Tutorial from './Chart/TutorialDeux';

function CreatChart({ data }) {
    

    const [selectedButton, setSelectedButton] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        // Vous pouvez ajouter ici la logique pour la navigation si nécessaire
    };

    const renderComponent = () => {
        switch (selectedButton) {
            case 'hbar':
                return <Hbar data={data}/>;
            case 'vbar':
                return <Vbar data={data}/>;
            case 'bubble':
                return <Bubble data={data}/>;
            case 'donut':
                return <Donut data={data}/>;
            case 'pie':
                return <Pie data={data}/>;
            case 'line':
                return <Line data={data}/>;
            case 'combined':
                return <Combined data={data}/>;
            case 'polar':
                return <Polar data={data}/>;
            case 'radar':
                return <Radar data={data}/>;
            case 'scatter':
                return <Scatter data={data}/>;
            case 'heater':
                return <Heater data={data}/>;
            default:
                return <Tutorial  />;  // Option par défaut
        }
    };



    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const icon = importAll(require.context('../../img/chart', false, /\.(png|jpe?g|svg)$/));
    const tailleIcone = 55;

    //1 - Sélection du graphique
    //2 - Affiche les données à inclure + les options


    //Il nous faut une liste par type de graphe des éléments que l'on peut mettre
    //Essayer dans un premier temps une liste réduite de 20 éléments sur un type de graphique et on regarde après si ça marche
    return (
        // Une partie overview

        /* power bi desktop integration

            11 Chart disponible :


            - Horizontal Bar chart 
            - Vertical Bar chart
            - Bubble chart
            - Doughnut chart
            - Pie chart
            - Line chart

            - Mixed (Line et Bar)
            - Polar Area chart
            - Radar chart
            - Scatter chart (x, y) carte avec coordonnées
            - HeatMap */


        <div>
            <div className='chartsSelection'>
                <div className='interieur90'>
                    <div className={`tooltip-container ${selectedButton === 'hbar' ? 'selected2' : ''}`} onClick={() => handleButtonClick('hbar')}><img src={icon[`vbar.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Horizontal Bar Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'vbar' ? 'selected2' : ''}`} onClick={() => handleButtonClick('vbar')}><img src={icon[`hbar.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Vertical Bar Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'bubble' ? 'selected2' : ''}`} onClick={() => handleButtonClick('bubble')}><img src={icon[`bubble.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Bubble Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'donut' ? 'selected2' : ''}`} onClick={() => handleButtonClick('donut')}><img src={icon[`donut.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Donut Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'pie' ? 'selected2' : ''}`} onClick={() => handleButtonClick('pie')}><img src={icon[`pie.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Pie Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'line' ? 'selected2' : ''}`} onClick={() => handleButtonClick('line')}><img src={icon[`line.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Line Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'combined' ? 'selected2' : ''}`} onClick={() => handleButtonClick('combined')}><img src={icon[`combined.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Combined Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'polar' ? 'selected2' : ''}`} onClick={() => handleButtonClick('polar')}><img src={icon[`polar.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Polar Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'radar' ? 'selected2' : ''}`} onClick={() => handleButtonClick('radar')}><img src={icon[`radar.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Radar Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'scatter' ? 'selected2' : ''}`} onClick={() => handleButtonClick('scatter')}><img src={icon[`scatter.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Scatter Chart</span></div>
                    <div className={`tooltip-container ${selectedButton === 'heater' ? 'selected2' : ''}`} onClick={() => handleButtonClick('heater')}><img src={icon[`heater.png`]} alt="" width={tailleIcone} className='block hoverimg' /><span className="tooltip-text">Heat Map</span></div>                </div>
            </div>
            <div>Select Max 3 data + Créer graphe Temporelle : Ligne / Barre = Ressource x time</div>    
            <div>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                {!loading && !error && renderComponent()}
            </div>
        </div>
        //Une partie scoreboard

        //Gold graph & Timeline

        //Plates
    );
}

export default CreatChart;
