import React from 'react';

function TableChampBan({ data }) {
  return (
    <table id="myTable" className="tablesorter test neumorphic">
                        <thead>
                            <tr>
                                <th>Champion</th>
                                <th>Bans</th>
                                <th>Ban rate</th>
                                

                            </tr>
                        </thead>
                        <tbody id="tableBody">
                            {Array.isArray(data) && data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.champion}</td>
                                        <td>{item.ban_count}</td>
                                        <td>{item.ban_rate}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Loading data, please wait . . .</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
  );
}

export default TableChampBan;
