import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Summary from './EsportGame/Summary';
import AllStats from './EsportGame/AllStats';
import Build from './EsportGame/Build';
import TimeLine from './EsportGame/TimeLine';
import HeatMap from './EsportGame/HeatMap';
import CreatChart from './EsportGame/CreatChart';

function Game({to, rid}) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const [selectedButton, setSelectedButton] = useState('Summary');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Définir une fonction asynchrone pour l'appel API
        const fetchData = async () => {
            try {
                const response = await fetch(`https://lolprostat.com:8088/tournamentGameJson?to=${to}&rid=${rid}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();

                setData(result);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [to, rid]); // Re-exécuter l'effet lorsque 'to' ou 'rid' changent

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        // Vous pouvez ajouter ici la logique pour la navigation si nécessaire
    };

    const renderComponent = () => {
        switch (selectedButton) {
            case 'Summary':
                return <Summary data={data} />;
            case 'All Stats':
                return <AllStats data={data} />;
            case 'Build':
                return <Build data={data} />;
            case 'TimeLine':
                return <TimeLine data={data} />;
            case 'Heat Map':
                return <HeatMap data={data} />;
            case 'Create chart':
                return <CreatChart data={data} />;
            default:
                return <Summary data={data} />;  // Option par défaut
        }
    };

    return (
        <div>
            <div className='encadre95Left mt10'>
                <button
                    className={`btn2 mr30 ${selectedButton === 'Summary' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('Summary')}
                >
                    Summary
                </button>
                <button
                    className={`btn2 mr30 ${selectedButton === 'All Stats' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('All Stats')}
                >
                    All Stats
                </button>
                <button
                    className={`btn2 mr30 ${selectedButton === 'Build' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('Build')}
                >
                    Builds
                </button>
                <button
                    className={`btn2 mr30 ${selectedButton === 'TimeLine' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('TimeLine')}
                >
                    TimeLine
                </button>
                <button
                    className={`btn2 mr30 ${selectedButton === 'Heat Map' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('Heat Map')}
                >
                    Heat Map
                </button>
                <button
                    className={`btn2 mr30 ${selectedButton === 'Create chart' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('Create chart')}
                >
                    Create chart
                </button>
            </div>
            <div>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                {!loading && !error && renderComponent()}
            </div>
        </div>
    );
}

export default Game;
