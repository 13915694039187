import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'; // Importer les éléments nécessaires
import './Head.css'
ChartJS.register(ArcElement, Tooltip, Legend);

function Head({ info, name, tag }) {
    var lp = 0;
    var wins = 0;
    var loses = 0;
    var game = 0;
    var tier = "BRONZE";
    var rank = "IV";
    var winrate = 0;
    
    for (let i = 0; i < info[1].length; i++) {
        if (info[1][i]['queueType'] === "RANKED_SOLO_5x5") {
            lp = info[1][i]['leaguePoints'];
            wins = info[1][i]['wins'];
            loses = info[1][i]['losses'];
            game = wins + loses;
            tier = info[1][i]['tier'];
            rank = info[1][i]['rank'];
            winrate = (((wins) / game) * 100).toFixed(1);
        }
    }

    // Importer toutes les images du dossier 'champion'
    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    };
    const images = importAll(require.context('../../img/rank', false, /\.(png|jpe?g|svg)$/));

    // Configuration du graphique pour Chart.js
    const dataCx = {
        labels: ["Wins", "Losses"],
        datasets: [{
            data: [wins, loses],
            backgroundColor: [
                'rgba(54, 162, 235, 0.5)', // Couleur pour les victoires
                'rgba(255, 99, 132, 0.5)' // Couleur pour les défaites
            ],
            borderColor: [
                'rgba(54, 162, 235, 1)', // Couleur de la bordure pour les victoires
                'rgba(255, 99, 132, 1)' // Couleur de la bordure pour les défaites
            ],
            borderWidth: 1
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
                text: 'Répartition des Victoires et Défaites'
            }
        }
    };


    return (
        <div>
            <div className='Titre'>Player profile</div>
            <div className='Cadre'>
                <div className='C1'>
                    <div><img className='IconProfile' alt='' src={`https://ddragon.leagueoflegends.com/cdn/14.5.1/img/profileicon/${info[0]['profileIconId']}.png`} /></div>
                    <div className='Niveau'>{info[0]['summonerLevel']}</div>
                </div>
                <div className='C3'>
                    <div className='nameC'>{name}#{tag}</div>
                    <div className='lpC'>LP : {lp}</div>
                    <div className='lpC'>Games played : {game}</div>
                    <div className='lpC'>Wins : {wins}</div>
                    <div className='lpC'>Losses : {loses}</div>
                </div>
                <div className='C2'>
                    <div><img src={images[`${tier}.png`]} alt=''/></div>
                    <div className='tierC'>{rank}</div>
                    <div className='lpC'>{winrate} %</div>
                </div>
                <div className='C4'>
                    {/* Ajouter le graphique ici */}
                    <Doughnut data={dataCx} options={options} />
                </div>
            </div>
        </div>
    );
}

export default Head;
