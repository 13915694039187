import React from 'react';
import sword from '../../img/sword.png';
import killImg from '../../img/ic_kills.png'
import tourImg from '../../img/ic_tours.png'
import baronImg from '../../img/ic_barons.png'
import goldImg from '../../img/ic_golds.png'
import dragonImg from '../../img/ic_dragons.png'
import info from '../../img/info.png';
import info2 from '../../img/info.png';
import { Line, Radar, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Filler, RadarController, RadialLinearScale, Tooltip, Legend, BarElement } from 'chart.js';
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Filler, RadarController, RadialLinearScale, Tooltip, Legend, BarElement);

//Tester de passer les paramètres via l'url pour la page game !! très important pour du ctrl c ctrl v
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS
//AJOUTER LES BANS


function Summary({ data }) {
  const game = JSON.parse(data['results1'][0].gameR);
  const gameTimeline = JSON.parse(data['results1'][0].gameTimeLine);
  const info = data['results2'][0];

  console.log('Game', game);
  console.log('GameTimeLine', gameTimeline);
  console.log('info', info);
  var teamB = info['Blue'];
  var teamR = info['Red'];

  function formatSecondsToMMSS(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Format minutes and seconds with leading zeros if needed
    const minutesFormatted = minutes.toString().padStart(2, '0');
    const secondsFormatted = remainingSeconds.toString().padStart(2, '0');

    return `${minutesFormatted}:${secondsFormatted}`;
  }

  function formatNumber(num) {
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'k';
    } else {
      return num.toString();
    }
  }
  //Calculer nbrkill, double parcrous de boucle
  var killB = 0;
  var killR = 0;
  var goldB = 0;
  var goldR = 0;

  var wardsPlacedB = 0;
  var wardsPlacedR = 0;
  var wardsDestroyedB = 0;
  var wardsDestroyedR = 0;

  for (let i = 0; i < 5; i++) {
    killB += game[0]['participants'][i]['kills'];
    goldB += game[0]['participants'][i]['goldEarned'];
    wardsPlacedB += game[0]['participants'][i]['wardsPlaced'];
    wardsDestroyedB += game[0]['participants'][i]['wardsKilled'];
  }
  for (let i = 5; i < 10; i++) {
    killR += game[0]['participants'][i]['kills'];
    goldR += game[0]['participants'][i]['goldEarned'];
    wardsPlacedR += game[0]['participants'][i]['wardsPlaced'];
    wardsDestroyedR += game[0]['participants'][i]['wardsKilled'];
  }
  const labelWard = ['Wards Placed', 'Wards Destroyed'];
  const wardB = [wardsPlacedB, wardsDestroyedB];
  const wardR = [wardsPlacedR, wardsDestroyedR];

  const goldBnonFormate = goldB;
  const goldRnonFormate = goldR;

  goldB = formatNumber(goldB);
  goldR = formatNumber(goldR);

  var minuteTimeLine = [];
  var totalGoldB = [];
  var totalGoldR = [];
  var totalGoldDiff = [];
  for (let i = 0; i < gameTimeline[0]['frames'].length; i++) {
    minuteTimeLine[i] = i;
    totalGoldB[i] = gameTimeline[0]['frames'][i]['participantFrames'][1]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][2]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][3]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][4]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][5]['totalGold'];
    totalGoldR[i] = gameTimeline[0]['frames'][i]['participantFrames'][6]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][7]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][8]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][9]['totalGold'] + gameTimeline[0]['frames'][i]['participantFrames'][10]['totalGold'];
    totalGoldDiff[i] = totalGoldB[i] - totalGoldR[i];
  }

  //On prépare maintenant les données pour le gold distribution
  var goldBRadar = [game[0]['participants'][0]['goldEarned'], game[0]['participants'][1]['goldEarned'], game[0]['participants'][2]['goldEarned'], game[0]['participants'][3]['goldEarned'], game[0]['participants'][4]['goldEarned']];
  var goldRRadar = [game[0]['participants'][5]['goldEarned'], game[0]['participants'][6]['goldEarned'], game[0]['participants'][7]['goldEarned'], game[0]['participants'][8]['goldEarned'], game[0]['participants'][9]['goldEarned']];
  var labelGold = ['TOP', 'JUNGLE', 'MID', 'ADC', 'SUPPORT'];

  var dmgB = [game[0]['participants'][0]['totalDamageDealtToChampions'], game[0]['participants'][1]['totalDamageDealtToChampions'], game[0]['participants'][2]['totalDamageDealtToChampions'], game[0]['participants'][3]['totalDamageDealtToChampions'], game[0]['participants'][4]['totalDamageDealtToChampions']];
  var dmgR = [game[0]['participants'][5]['totalDamageDealtToChampions'], game[0]['participants'][6]['totalDamageDealtToChampions'], game[0]['participants'][7]['totalDamageDealtToChampions'], game[0]['participants'][8]['totalDamageDealtToChampions'], game[0]['participants'][9]['totalDamageDealtToChampions']];
  var labelDmg = labelGold;

  var totdmgB = dmgB[0] + dmgB[1] + dmgB[2] + dmgB[3] + dmgB[4];
  var totdmgR = dmgR[0] + dmgR[1] + dmgR[2] + dmgR[3] + dmgR[4];

  var tpsmax = gameTimeline[0]['frames'].length - 1;
  var jglcs15B = gameTimeline[0]['frames'][15]['participantFrames'][2]['jungleMinionsKilled'];
  var jglcs15R = gameTimeline[0]['frames'][15]['participantFrames'][7]['jungleMinionsKilled'];
  var jglcsfinB = gameTimeline[0]['frames'][tpsmax]['participantFrames'][2]['jungleMinionsKilled'];
  var jglcsfinR = gameTimeline[0]['frames'][tpsmax]['participantFrames'][7]['jungleMinionsKilled'];
  var totcs15 = jglcs15B + jglcs15R;
  var totcs = jglcsfinB + jglcsfinR;

  var labeljgl = ['At 15 min', 'End game'];
  var jglB = [(jglcs15B * 100) / totcs15, (jglcsfinB * 100) / totcs];
  var jglR = [(jglcs15R * 100) / totcs15, (jglcsfinR * 100) / totcs];

  var csdiff15 = [0];
  var csdiffEnd = [0];
  var lvldiff15 = [0];
  var lvldiffEnd = [0];
  var golddiff15 = [0];
  var golddiffEnd = [0];

  for (let i = 1; i < 11; i++) {
    csdiff15[i] = gameTimeline[0]['frames'][15]['participantFrames'][i]['minionsKilled'] + gameTimeline[0]['frames'][15]['participantFrames'][i]['minionsKilled'];
    csdiffEnd[i] = gameTimeline[0]['frames'][tpsmax]['participantFrames'][i]['minionsKilled'] + gameTimeline[0]['frames'][tpsmax]['participantFrames'][i]['minionsKilled'];
    lvldiff15[i] = gameTimeline[0]['frames'][15]['participantFrames'][i]['level'];
    lvldiffEnd[i] = gameTimeline[0]['frames'][tpsmax]['participantFrames'][i]['level'];
    golddiff15[i] = gameTimeline[0]['frames'][15]['participantFrames'][i]['totalGold'];
    golddiffEnd[i] = gameTimeline[0]['frames'][tpsmax]['participantFrames'][i]['totalGold'];
  }

  var labelLane = ['TOP', 'JUNGLE', 'MID', 'ADC', 'SUPPORT'];
  var csdiff15Final = [];
  var csdiffEndFinal = [];
  var lvldiff15Final = [];
  var lvldiffEndFinal = [];
  var golddiff15Final = [];
  var golddiffEndFinal = [];

  for (let i = 1; i < 6; i++) {
    csdiff15Final.push(csdiff15[i] - csdiff15[i + 5]);
    csdiffEndFinal.push(csdiffEnd[i] - csdiffEnd[i + 5]);
    lvldiff15Final.push(lvldiff15[i] - lvldiff15[i + 5]);
    lvldiffEndFinal.push(lvldiffEnd[i] - lvldiffEnd[i + 5]);
    golddiff15Final.push(golddiff15[i] - golddiff15[i + 5]);
    golddiffEndFinal.push(golddiffEnd[i] - golddiffEnd[i + 5]);
  }




  const GoldLeadChart = ({ timestamps, diff, tailleIconeChamp }) => {

    const chartData3 = {
      labels: timestamps,  // Vos données pour l'axe des x (timestamps)
      datasets: [{
        label: 'Gold lead',
        data: diff,  // Vos données pour l'axe des y (diff)
        fill: {
          target: 'origin',
          above: 'rgb(54, 162, 235, 0.5)',  // Zone bleue au-dessus de l'origine
          below: 'rgb(255, 99, 132, 0.5)'   // Zone rouge en-dessous de l'origine
        },
        borderColor: 'yellow',  // Couleur de la ligne
        borderWidth: 2,
      }]
    };

    const chartOptions = {
      plugins: {
        legend: {
          display: false,  // Cache la légende
        }
      },
      scales: {
        x: {
          type: 'linear',
          position: 'bottom',
          title: {
            display: true,
            text: 'Time (minutes)'  // Label de l'axe des x
          }
        },
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'Total Gold'  // Label de l'axe des y
          }
        }
      }
    };

    return (

      <Line data={chartData3} options={chartOptions} height={75} />

    );
  };

  const RadarChart = ({ labelGold, goldB, goldR }) => {

    const chartData = {
      labels: labelGold, // ['TOP', 'JUNGLE', 'MID', 'ADC', 'SUPPORT']
      datasets: [
        {
          label: 'Blue Gold',
          data: goldB,  // Série de données pour l'équipe bleue
          backgroundColor: 'rgba(54, 162, 235, 0.5)',  // Couleur de remplissage en bleu
          borderColor: 'rgba(54, 162, 235, 1)',  // Bordure bleue
          borderWidth: 4,
          fill: false,
        },
        {
          label: 'Red Gold',
          data: goldR,  // Série de données pour l'équipe rouge
          backgroundColor: 'rgba(255, 99, 132, 0.5)',  // Couleur de remplissage en rouge
          borderColor: 'rgba(255, 99, 132, 1)',  // Bordure rouge
          borderWidth: 4,
          fill: false,
        },
      ],
    };

    const chartOptions = {
      scales: {
        r: {
          angleLines: {
            display: true,  // Affiche les lignes d'angle
          },
          suggestedMin: 0,  // Minimum suggéré pour l'axe radial
          suggestedMax: Math.max(...goldB, ...goldR) + 1000,  // Maximum basé sur les valeurs max des séries
          ticks: {
            beginAtZero: true,  // Commencer à 0 pour l'axe radial
          },
        },
      },
      plugins: {
        legend: {
          display: true,  // Affiche la légende
          position: 'top',  // Position en haut
        },
      },
    };

    return (
      <div style={{ width: '70%' }}>
        <Radar className='borderred' data={chartData} options={chartOptions} />
      </div>
    );
  };

  const HorizontalBarChart = ({ labelDmg, dmgB, dmgR, teamB, teamR }) => {

    const chartData = {
      labels: labelDmg,  // Les labels pour les barres
      datasets: [
        {
          label: teamB + ' Damage',
          data: dmgB,  // Les données pour l'équipe bleue
          backgroundColor: 'rgba(54, 162, 235, 0.7)',  // Couleur de la barre bleue
          borderColor: 'rgba(54, 162, 235, 1)',  // Bordure bleue
          borderWidth: 1,
        },
        {
          label: teamR + ' Damage',
          data: dmgR,  // Les données pour l'équipe rouge
          backgroundColor: 'rgba(255, 99, 132, 0.7)',  // Couleur de la barre rouge
          borderColor: 'rgba(255, 99, 132, 1)',  // Bordure rouge
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      indexAxis: 'y',  // Définit l'axe principal comme horizontal
      scales: {
        x: {
          title: {
            display: true,
            text: 'Damage'  // Label de l'axe des x
          },
        },
        y: {
          title: {
            display: true,
            text: 'Lane'  // Label de l'axe des y
          },
        },
      },
      plugins: {
        legend: {
          position: 'top',  // Position de la légende
        },
      },
    };

    return (
      <div style={{ width: '95%' }}>
        <Bar className='' data={chartData} options={chartOptions} />
      </div>
    );
  };

  const HorizontalBarChartGold = ({ labelDmg, dmgB, dmgR, teamB, teamR }) => {

    const chartData = {
      labels: labelDmg,  // Les labels pour les barres
      datasets: [
        {
          label: teamB + ' Gold',
          data: dmgB,  // Les données pour l'équipe bleue
          backgroundColor: 'rgba(54, 162, 235, 0.7)',  // Couleur de la barre bleue
          borderColor: 'rgba(54, 162, 235, 1)',  // Bordure bleue
          borderWidth: 1,
        },
        {
          label: teamR + ' Gold',
          data: dmgR,  // Les données pour l'équipe rouge
          backgroundColor: 'rgba(255, 99, 132, 0.7)',  // Couleur de la barre rouge
          borderColor: 'rgba(255, 99, 132, 1)',  // Bordure rouge
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      indexAxis: 'y',  // Définit l'axe principal comme horizontal
      scales: {
        x: {
          title: {
            display: true,
            text: 'Gold'  // Label de l'axe des x
          },
        },
        y: {
          title: {
            display: true,
            text: 'Lane'  // Label de l'axe des y
          },
        },
      },
      plugins: {
        legend: {
          position: 'top',  // Position de la légende
        },
      },
    };

    return (
      <div style={{ width: '95%' }}>
        <Bar className='' data={chartData} options={chartOptions} />
      </div>
    );
  };


  const HorizontalBarChartWard = ({ labelDmg, dmgB, dmgR, teamB, teamR }) => {

    const chartData = {
      labels: labelDmg,  // Les labels pour les barres
      datasets: [
        {
          label: teamB + ' Ward',
          data: dmgB,  // Les données pour l'équipe bleue
          backgroundColor: 'rgba(54, 162, 235, 0.7)',  // Couleur de la barre bleue
          borderColor: 'rgba(54, 162, 235, 1)',  // Bordure bleue
          borderWidth: 1,
        },
        {
          label: teamR + ' Ward',
          data: dmgR,  // Les données pour l'équipe rouge
          backgroundColor: 'rgba(255, 99, 132, 0.7)',  // Couleur de la barre rouge
          borderColor: 'rgba(255, 99, 132, 1)',  // Bordure rouge
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      indexAxis: 'y',  // Définit l'axe principal comme horizontal
      scales: {
        x: {
          title: {
            display: true,
            text: 'Ward'  // Label de l'axe des x
          },
        },
        y: {
          title: {
            display: true,
            text: ''  // Label de l'axe des y
          },
        },
      },
      plugins: {
        legend: {
          position: 'top',  // Position de la légende
        },
      },
    };

    return (
      <div style={{ width: '95%' }}>
        <Bar className='' data={chartData} options={chartOptions} />
      </div>
    );
  };

  const HorizontalBarChartJgl = ({ labelDmg, dmgB, dmgR, teamB, teamR }) => {

    const chartData = {
      labels: labelDmg,  // Les labels pour les barres
      datasets: [
        {
          label: teamB + ' - Blue Side',
          data: dmgB,  // Les données pour l'équipe bleue
          backgroundColor: 'rgba(54, 162, 235, 0.7)',  // Couleur de la barre bleue
          borderColor: 'rgba(54, 162, 235, 1)',  // Bordure bleue
          borderWidth: 1,
        },
        {
          label: teamR + ' - Red Side',
          data: dmgR,  // Les données pour l'équipe rouge
          backgroundColor: 'rgba(255, 99, 132, 0.7)',  // Couleur de la barre rouge
          borderColor: 'rgba(255, 99, 132, 1)',  // Bordure rouge
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      indexAxis: 'y',  // Définit l'axe principal comme horizontal
      scales: {
        x: {
          title: {
            display: true,
            text: 'Jungle Share %'  // Label de l'axe des x
          },
        },
        y: {
          title: {
            display: true,
            text: ''  // Label de l'axe des y
          },
        },
      },
      plugins: {
        legend: {
          position: 'top',  // Position de la légende
        },
      },
    };

    return (
      <div style={{ width: '95%' }}>
        <Bar className='' data={chartData} options={chartOptions} />
      </div>
    );
  };

  const HorizontalBarChartGeneral = ({ labelDmg, dmgB, dmgR, teamB, teamR, text }) => {

    const chartData = {
      labels: labelDmg,  // Les labels pour les barres
      datasets: [
        {
          label: teamB + ' - Blue Side',
          data: dmgB,  // Les données pour l'équipe bleue
          backgroundColor: 'rgba(54, 162, 235, 0.7)',  // Couleur de la barre bleue
          borderColor: 'rgba(54, 162, 235, 1)',  // Bordure bleue
          borderWidth: 1,
        }

      ],
    };

    const chartOptions = {
      indexAxis: 'y',  // Définit l'axe principal comme horizontal
      scales: {
        x: {
          title: {
            display: true,
            text: text  // Label de l'axe des x
          },
        },
        y: {
          title: {
            display: true,
            text: ''  // Label de l'axe des y
          },
        },
      },
      plugins: {
        legend: {
          position: 'top',  // Position de la légende
        },
      },
    };

    return (
      <div style={{ width: '95%' }}>
        <Bar className='' data={chartData} options={chartOptions} />
      </div>
    );
  };

  // Importer toutes les images du dossier 'champion'
  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const images = importAll(require.context('../../img/champion', false, /\.(png|jpe?g|svg)$/));
  const sums = importAll(require.context('../../img/sums', false, /\.(png|jpe?g|svg)$/));
  const items = importAll(require.context('../../img/item', false, /\.(png|jpe?g|svg)$/));

  const tailleIconeChamp = 25;

  return (
    // Une partie overview
    <div className='selectionTPSummary'>
      <div className='encadreGameSummary'>
        <div className='tournamentData2'>Overview</div>
        <div>
          {formatSecondsToMMSS(game[0]['gameDuration'])}
        </div>
        <div className='encadreinterne'>
          {/* Equipe1 */}
          <div className='einint mr10'>
            <div style={{ textAlign: 'center' }} className={game[0]['teams'][0]['win'] ? 'textgreen' : 'textred'}>{info['Blue']} - {(game[0]['teams'][0]['win'] === true ? 'Win' : 'Loss')}</div>
            <div className={game[0]['teams'][0]['win'] ? 'borderbottomgreen' : 'borderbottomred'}></div>
            <div className='flexwrap mt10' style={{ justifyContent: 'space-around' }}>{/* Ligne flex, wrap, indicateurs kills tours, gold .. */}
              <div className='flexwrap infoobj tooltip-container'>{/*Kills*/}
                <div><img src={killImg} alt="" style={{ width: 20 }} className='block' /></div>
                <div style={{ marginLeft: "7px" }}>- {killB}</div>
                <span className="tooltip-text">Total Kills</span>
              </div>
              <div className='flexwrap infoobj  tooltip-container'>{/* Tour */}
                <div><img src={tourImg} alt="" style={{ width: 15 }} className='block' /></div>
                <div style={{ marginLeft: "7px" }}>- {game[0]['teams'][0]['objectives']['tower']['kills']}</div>
                <span className="tooltip-text" >Total Towers</span>
              </div>
              <div className='flexwrap infoobj  tooltip-container'>{/* Dragon */}
                <div><img src={dragonImg} alt="" style={{ width: 20 }} className='block' /></div>
                <div style={{ marginLeft: "7px" }}>- {game[0]['teams'][0]['objectives']['dragon']['kills']}</div>
                <span className="tooltip-text">Total Dragon</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Baron */}
                <div><img src={baronImg} alt="" style={{ width: 15 }} className='block' /></div>
                <div style={{ marginLeft: "7px" }}>- {game[0]['teams'][0]['objectives']['baron']['kills']}</div>
                <span className="tooltip-text">Total Baron</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Horde */}
                <div><img src={baronImg} alt="" style={{ width: 15 }} className='block' /></div>
                <div style={{ marginLeft: "7px" }}>- {game[0]['teams'][0]['objectives']['horde']['kills']}</div>
                <span className="tooltip-text">Total Grubbs</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Gold */}
                <div><img src={goldImg} alt="" style={{ width: 20 }} className='block' /></div>
                <div style={{ marginLeft: "7px" }}>- {goldB}</div>
                <span className="tooltip-text">Total Golds</span>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', border: 'green solid 2px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>Player</div>
                <div>KDA / CS</div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>{/* Conteneur qui a les lignes */}


                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", border: "red solid 1px", alignItems: "center" }}> {/* Une lgine */}
                  <div style={{ border: "yellow 1px solid" }}><img src={images[`${game[0]['participants'][0]['championName']}.png`]} alt="Aatrox" width={50} className='block' /></div>
                  <div style={{ border: "yellow 1px solid", width: "175px" }}>{game[0]['participants'][0]['riotIdGameName']}</div>
                  <div style={{ display: 'flex', flexDirection: 'row', border: "yellow 1px solid" }}>
                    <img src={sums[`${game[0]['participants'][0]['spell1Id']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />
                    <img src={sums[`${game[0]['participants'][0]['spell2Id']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: "yellow 1px solid", width: "176px" }}>
                    <div style={{display:'flex', flexDirection:'row', border:"red solid 1px"}}>
                      {game[0]['participants'][0]['item0'] !== 0 && (<img src={items[`${game[0]['participants'][0]['item0']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][0]['item1'] !== 0 && (<img src={items[`${game[0]['participants'][0]['item1']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][0]['item2'] !== 0 && (<img src={items[`${game[0]['participants'][0]['item2']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][0]['item3'] !== 0 && (<img src={items[`${game[0]['participants'][0]['item3']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][0]['item4'] !== 0 && (<img src={items[`${game[0]['participants'][0]['item4']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][0]['item5'] !== 0 && (<img src={items[`${game[0]['participants'][0]['item5']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][0]['item6'] !== 0 && (<img src={items[`${game[0]['participants'][0]['item6']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                    </div>
                  </div>
                  <div style={{ border: "yellow 1px solid" }}>{game[0]['participants'][0]['kills']}/{game[0]['participants'][0]['deaths']}/{game[0]['participants'][0]['assists']}</div>
                  <div style={{ border: "yellow 1px solid" }}>{game[0]['participants'][0]['totalMinionsKilled'] + game[0]['participants'][0]['neutralMinionsKilled']}</div>
                </div>


                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", border: "red solid 1px", alignItems: "center" }}> {/* Une lgine */}
                  <div style={{ border: "yellow 1px solid" }}><img src={images[`${game[0]['participants'][1]['championName']}.png`]} alt="Aatrox" width={50} className='block' /></div>
                  <div style={{ border: "yellow 1px solid", width: "175px" }}>{game[0]['participants'][1]['riotIdGameName']}</div>
                  <div style={{ display: 'flex', flexDirection: 'row', border: "yellow 1px solid" }}>
                    <img src={sums[`${game[0]['participants'][1]['spell1Id']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />
                    <img src={sums[`${game[0]['participants'][1]['spell2Id']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: "yellow 1px solid", width: "176px" }}>
                    <div style={{display:'flex', flexDirection:'row', border:"red solid 1px"}}>
                      {game[0]['participants'][1]['item0'] !== 0 && (<img src={items[`${game[0]['participants'][1]['item0']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][1]['item1'] !== 0 && (<img src={items[`${game[0]['participants'][1]['item1']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][1]['item2'] !== 0 && (<img src={items[`${game[0]['participants'][1]['item2']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][1]['item3'] !== 0 && (<img src={items[`${game[0]['participants'][1]['item3']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][1]['item4'] !== 0 && (<img src={items[`${game[0]['participants'][1]['item4']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][1]['item5'] !== 0 && (<img src={items[`${game[0]['participants'][1]['item5']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                      {game[0]['participants'][1]['item6'] !== 0 && (<img src={items[`${game[0]['participants'][1]['item6']}.png`]} alt="" width={tailleIconeChamp} height={tailleIconeChamp} className='block' />)}
                    </div>
                  </div>
                  <div style={{ border: "yellow 1px solid" }}>{game[0]['participants'][1]['kills']}/{game[0]['participants'][1]['deaths']}/{game[0]['participants'][1]['assists']}</div>
                  <div style={{ border: "yellow 1px solid" }}>{game[0]['participants'][1]['totalMinionsKilled'] + game[0]['participants'][1]['neutralMinionsKilled']}</div>
                </div>




              </div>
            </div>

          </div>

          {/* Equipe 2 */}
          <div className='einint ml10'>
            <div style={{ textAlign: 'center' }} className={game[0]['teams'][1]['win'] ? 'textgreen' : 'textred'}>{info['Red']} - {(game[0]['teams'][1]['win'] === true ? 'Win' : 'Loss')}</div>
            <div className={game[0]['teams'][1]['win'] ? 'borderbottomgreen' : 'borderbottomred'}></div>
            <div className='flexwrap mt10' style={{ justifyContent: 'space-around' }}>{/* Ligne flex, wrap, indicateurs kills tours, gold .. */}
              <div className='flexwrap infoobj'>{/*Kills*/}
                <div><img src={sword} alt="" style={{ width: 20 }} className='block' /></div>
                <div>{killR}</div>
                <span className="tooltip-text">Total Kills</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Tour */}
                <div><img src={sword} alt="" style={{ width: 20 }} className='block' /></div>
                <div>{game[0]['teams'][1]['objectives']['tower']['kills']}</div>
                <span className="tooltip-text">Total Towers</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Dragon */}
                <div><img src={sword} alt="" style={{ width: 20 }} className='block' /></div>
                <div>{game[0]['teams'][1]['objectives']['dragon']['kills']}</div>
                <span className="tooltip-text">Total Dragon</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Baron */}
                <div><img src={sword} alt="" style={{ width: 20 }} className='block' /></div>
                <div>{game[0]['teams'][1]['objectives']['baron']['kills']}</div>
                <span className="tooltip-text">Total Baron</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Horde */}
                <div><img src={sword} alt="" style={{ width: 20 }} className='block' /></div>
                <div>{game[0]['teams'][1]['objectives']['horde']['kills']}</div>
                <span className="tooltip-text">Total Grubbs</span>
              </div>
              <div className='flexwrap infoobj tooltip-container'>{/* Gold */}
                <div><img src={sword} alt="" style={{ width: 20 }} className='block' /></div>
                <div>{goldR}</div>
                <span className="tooltip-text">Total Golds</span>
              </div>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column' }}> {/* Cadre pour Player KDA /CS - On fonctionne par ligne */}
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>Player</div>
                <div>KDA / CS</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>  {/* Colonne Image */}
                  <div><img src={images[`${game[0]['participants'][5]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></div>
                  <div><img src={images[`${game[0]['participants'][6]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></div>
                  <div><img src={images[`${game[0]['participants'][7]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></div>
                  <div><img src={images[`${game[0]['participants'][8]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></div>
                  <div><img src={images[`${game[0]['participants'][9]['championName']}.png`]} alt="Aatrox" width={tailleIconeChamp} className='block' /></div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>  {/* Colonne Nom */}
                  <div>{game[0]['participants'][5]['riotIdGameName']}</div>
                  <div>{game[0]['participants'][6]['riotIdGameName']}</div>
                  <div>{game[0]['participants'][7]['riotIdGameName']}</div>
                  <div>{game[0]['participants'][8]['riotIdGameName']}</div>
                  <div>{game[0]['participants'][9]['riotIdGameName']}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>  {/* Colonne Sums/rune (carré 2x2) */}
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={sums[`${game[0]['participants'][5]['spell1Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                    <img src={sums[`${game[0]['participants'][5]['spell2Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={sums[`${game[0]['participants'][6]['spell1Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                    <img src={sums[`${game[0]['participants'][6]['spell2Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={sums[`${game[0]['participants'][7]['spell1Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                    <img src={sums[`${game[0]['participants'][7]['spell2Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={sums[`${game[0]['participants'][8]['spell1Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                    <img src={sums[`${game[0]['participants'][8]['spell2Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={sums[`${game[0]['participants'][9]['spell1Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                    <img src={sums[`${game[0]['participants'][9]['spell2Id']}.png`]} alt="" width={tailleIconeChamp} className='block' />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>  {/* Colonne Items */}
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {game[0]['participants'][5]['item0'] !== 0 && (<img src={items[`${game[0]['participants'][5]['item0']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][5]['item1'] !== 0 && (<img src={items[`${game[0]['participants'][5]['item1']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][5]['item2'] !== 0 && (<img src={items[`${game[0]['participants'][5]['item2']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][5]['item3'] !== 0 && (<img src={items[`${game[0]['participants'][5]['item3']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][5]['item4'] !== 0 && (<img src={items[`${game[0]['participants'][5]['item4']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][5]['item5'] !== 0 && (<img src={items[`${game[0]['participants'][5]['item5']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][5]['item6'] !== 0 && (<img src={items[`${game[0]['participants'][5]['item6']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {game[0]['participants'][6]['item0'] !== 0 && (<img src={items[`${game[0]['participants'][6]['item0']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][6]['item1'] !== 0 && (<img src={items[`${game[0]['participants'][6]['item1']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][6]['item2'] !== 0 && (<img src={items[`${game[0]['participants'][6]['item2']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][6]['item3'] !== 0 && (<img src={items[`${game[0]['participants'][6]['item3']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][6]['item4'] !== 0 && (<img src={items[`${game[0]['participants'][6]['item4']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][6]['item5'] !== 0 && (<img src={items[`${game[0]['participants'][6]['item5']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][6]['item6'] !== 0 && (<img src={items[`${game[0]['participants'][6]['item6']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {game[0]['participants'][7]['item0'] !== 0 && (<img src={items[`${game[0]['participants'][7]['item0']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][7]['item1'] !== 0 && (<img src={items[`${game[0]['participants'][7]['item1']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][7]['item2'] !== 0 && (<img src={items[`${game[0]['participants'][7]['item2']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][7]['item3'] !== 0 && (<img src={items[`${game[0]['participants'][7]['item3']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][7]['item4'] !== 0 && (<img src={items[`${game[0]['participants'][7]['item4']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][7]['item5'] !== 0 && (<img src={items[`${game[0]['participants'][7]['item5']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][7]['item6'] !== 0 && (<img src={items[`${game[0]['participants'][7]['item6']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {game[0]['participants'][8]['item0'] !== 0 && (<img src={items[`${game[0]['participants'][8]['item0']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][8]['item1'] !== 0 && (<img src={items[`${game[0]['participants'][8]['item1']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][8]['item2'] !== 0 && (<img src={items[`${game[0]['participants'][8]['item2']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][8]['item3'] !== 0 && (<img src={items[`${game[0]['participants'][8]['item3']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][8]['item4'] !== 0 && (<img src={items[`${game[0]['participants'][8]['item4']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][8]['item5'] !== 0 && (<img src={items[`${game[0]['participants'][8]['item5']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][8]['item6'] !== 0 && (<img src={items[`${game[0]['participants'][8]['item6']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {game[0]['participants'][9]['item0'] !== 0 && (<img src={items[`${game[0]['participants'][9]['item0']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][9]['item1'] !== 0 && (<img src={items[`${game[0]['participants'][9]['item1']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][9]['item2'] !== 0 && (<img src={items[`${game[0]['participants'][9]['item2']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][9]['item3'] !== 0 && (<img src={items[`${game[0]['participants'][9]['item3']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][9]['item4'] !== 0 && (<img src={items[`${game[0]['participants'][9]['item4']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][9]['item5'] !== 0 && (<img src={items[`${game[0]['participants'][9]['item5']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                    {game[0]['participants'][9]['item6'] !== 0 && (<img src={items[`${game[0]['participants'][9]['item6']}.png`]} alt="" width={tailleIconeChamp} className='block' />)}
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>  {/* Colonne KDA */}
                  <div>{game[0]['participants'][5]['kills']}/{game[0]['participants'][5]['deaths']}/{game[0]['participants'][5]['assists']}</div>
                  <div>{game[0]['participants'][6]['kills']}/{game[0]['participants'][6]['deaths']}/{game[0]['participants'][6]['assists']}</div>
                  <div>{game[0]['participants'][7]['kills']}/{game[0]['participants'][7]['deaths']}/{game[0]['participants'][7]['assists']}</div>
                  <div>{game[0]['participants'][8]['kills']}/{game[0]['participants'][8]['deaths']}/{game[0]['participants'][8]['assists']}</div>
                  <div>{game[0]['participants'][9]['kills']}/{game[0]['participants'][9]['deaths']}/{game[0]['participants'][9]['assists']}</div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>  {/* Colonne CS */}
                  <div>{game[0]['participants'][5]['totalMinionsKilled'] + game[0]['participants'][5]['neutralMinionsKilled']}</div>
                  <div>{game[0]['participants'][6]['totalMinionsKilled'] + game[0]['participants'][6]['neutralMinionsKilled']}</div>
                  <div>{game[0]['participants'][7]['totalMinionsKilled'] + game[0]['participants'][7]['neutralMinionsKilled']}</div>
                  <div>{game[0]['participants'][8]['totalMinionsKilled'] + game[0]['participants'][8]['neutralMinionsKilled']}</div>
                  <div>{game[0]['participants'][9]['totalMinionsKilled'] + game[0]['participants'][9]['neutralMinionsKilled']}</div>
                </div>
              </div>
            </div>







          </div>
        </div>


      </div>

      <div className='encadreGameSummary'>
        <div className='tournamentData2'>Gold Diff Graph</div>
        <div className='encadreinterne'>
          <GoldLeadChart timestamps={minuteTimeLine} diff={totalGoldDiff} />

        </div>
      </div>

      <div className='encadre2graphesLigne'>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Gold Distribution</div>
          <div className='encadreinterne' style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <RadarChart labelGold={labelGold} goldB={goldBRadar} goldR={goldRRadar} /> */}
            <HorizontalBarChartGold labelDmg={labelGold} dmgB={goldBRadar} dmgR={goldRRadar} teamB={teamB} teamR={teamR} />
            <div style={{ display: 'flex', flexDirection: 'row' }} className='bordersympa'>
              <div style={{ marginRight: "10px" }}>
                <div style={{ color: '#d2dcf8', marginBottom: "10px" }}>y'a pas</div>
                <div >TOP</div>
                <div>JUNGLE</div>
                <div>MID</div>
                <div>ADC</div>
                <div>SUPPORT</div>
              </div>
              <div style={{ marginRight: "10px", marginLeft: "10px" }}>
                <div style={{ borderBottom: 'blue solid 1px', marginBottom: "10px" }}>{info['Blue']}</div>
                <div>{((goldBRadar[0] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldBRadar[1] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldBRadar[2] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldBRadar[3] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldBRadar[4] * 100) / goldBnonFormate).toFixed(1)}%</div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div style={{ borderBottom: 'red solid 1px', marginBottom: "10px" }}>{info['Red']}</div>
                <div>{((goldRRadar[0] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldRRadar[1] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldRRadar[2] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldRRadar[3] * 100) / goldBnonFormate).toFixed(1)}%</div>
                <div>{((goldRRadar[4] * 100) / goldBnonFormate).toFixed(1)}%</div>
              </div>
            </div>
          </div>
        </div>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Damage Distribution</div>
          <div className='encadreinterne' style={{ flexDirection: 'column' }}>
            <HorizontalBarChart labelDmg={labelDmg} dmgB={dmgB} dmgR={dmgR} teamB={teamB} teamR={teamR} />
            <div style={{ display: 'flex', flexDirection: 'row' }} className='bordersympa'>
              <div style={{ marginRight: "10px" }}>
                <div style={{ color: '#d2dcf8', marginBottom: "10px" }}>y'a pas</div>
                <div>TOP</div>
                <div>JUNGLE</div>
                <div>MID</div>
                <div>ADC</div>
                <div>SUPPORT</div>
              </div>
              <div style={{ marginRight: "10px", marginLeft: "10px" }}>
                <div style={{ borderBottom: 'blue solid 1px', marginBottom: "10px" }}>{info['Blue']}</div>
                <div>{((dmgB[0] * 100) / totdmgB).toFixed(1)}%</div>
                <div>{((dmgB[1] * 100) / totdmgB).toFixed(1)}%</div>
                <div>{((dmgB[2] * 100) / totdmgB).toFixed(1)}%</div>
                <div>{((dmgB[3] * 100) / totdmgB).toFixed(1)}%</div>
                <div>{((dmgB[4] * 100) / totdmgB).toFixed(1)}%</div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div style={{ borderBottom: 'red solid 1px', marginBottom: "10px" }}>{info['Red']}</div>
                <div>{((dmgR[0] * 100) / totdmgR).toFixed(1)}%</div>
                <div>{((dmgR[1] * 100) / totdmgR).toFixed(1)}%</div>
                <div>{((dmgR[2] * 100) / totdmgR).toFixed(1)}%</div>
                <div>{((dmgR[3] * 100) / totdmgR).toFixed(1)}%</div>
                <div>{((dmgR[4] * 100) / totdmgR).toFixed(1)}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className='encadre2graphesLigne'>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Vision</div>
          <div className='encadreinterne ' style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalBarChartWard labelDmg={labelWard} dmgB={wardB} dmgR={wardR} teamB={teamB} teamR={teamR} />
          </div>
        </div>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Jungle Share</div>
          <div className='encadreinterne ' style={{ flexDirection: 'column' }}>
            <HorizontalBarChartJgl labelDmg={labeljgl} dmgB={jglB} dmgR={jglR} teamB={teamB} teamR={teamR} />
          </div>
        </div>
      </div>

      <div className='encadre2graphesLigne'>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>CS diff @ 15</div>
          <div className='encadreinterne ' style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalBarChartGeneral labelDmg={labelLane} dmgB={csdiff15Final} teamB={teamB} teamR={teamR} text="CS" />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div><img src={info2} alt="" style={{ width: 15 }} className='block' /></div>
              <div className='petittexteindication ml10'>A negative value indicates that Red side was ahead</div>
            </div>
          </div>
        </div>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>CS diff @ End</div>
          <div className='encadreinterne ' style={{ flexDirection: 'column' }}>
            <HorizontalBarChartGeneral labelDmg={labelLane} dmgB={csdiffEndFinal} teamB={teamB} teamR={teamR} text="CS" />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div><img src={info2} alt="" style={{ width: 15 }} className='block' /></div>
              <div className='petittexteindication ml10'>A negative value indicates that Red side was ahead</div>
            </div>
          </div>
        </div>
      </div>

      <div className='encadre2graphesLigne'>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Gold diff @ 15</div>
          <div className='encadreinterne ' style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalBarChartGeneral labelDmg={labelLane} dmgB={golddiff15Final} teamB={teamB} teamR={teamR} text="Gold" />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div><img src={info2} alt="" style={{ width: 15 }} className='block' /></div>
              <div className='petittexteindication ml10'>A negative value indicates that Red side was ahead</div>
            </div>
          </div>
        </div>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Gold diff @ End</div>
          <div className='encadreinterne ' style={{ flexDirection: 'column' }}>
            <HorizontalBarChartGeneral labelDmg={labelLane} dmgB={golddiffEndFinal} teamB={teamB} teamR={teamR} text="Gold" />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div><img src={info2} alt="" style={{ width: 15 }} className='block' /></div>
              <div className='petittexteindication ml10'>A negative value indicates that Red side was ahead</div>
            </div>
          </div>
        </div>
      </div>

      <div className='encadre2graphesLigne'>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Level diff @ 15</div>
          <div className='encadreinterne ' style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalBarChartGeneral labelDmg={labelLane} dmgB={lvldiff15Final} teamB={teamB} teamR={teamR} text="Level" />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div><img src={info2} alt="" style={{ width: 15 }} className='block' /></div>
              <div className='petittexteindication ml10'>A negative value indicates that Red side was ahead</div>
            </div>
          </div>
        </div>
        <div className='encadreGameSummary48'>
          <div className='tournamentData2'>Level diff @ End</div>
          <div className='encadreinterne ' style={{ flexDirection: 'column' }}>
            <HorizontalBarChartGeneral labelDmg={labelLane} dmgB={lvldiffEndFinal} teamB={teamB} teamR={teamR} text="Level" />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div><img src={info2} alt="" style={{ width: 15 }} className='block' /></div>
              <div className='petittexteindication ml10'>A negative value indicates that Red side was ahead</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    //Une partie scoreboard

    //Gold graph & Timeline

    //Plates
  );
}

export default Summary;
