import React from 'react';
import { useNavigate } from 'react-router-dom';


function Donut({ }) {
  const navigate = useNavigate();
  return (
    <div>
        var             Donut
    </div>
  );
}

export default Donut;
