import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar'
import EncadreRechercheEport from '../components/EncadreRechercheEport'
import SautDemarquation from '../components/SautDemarquation';
import EportBtnNav from "../components/EportBtnNav";
import '../style/titre.css';
import '../style/composant.css';
import '../style/encadre.css';
import '../style/table.css';

function EportMatchList({ href, iconClass, text }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { name,value } = location.state || {};

    const handleClickOverview = (e) => {
        e.preventDefault();   
          navigate('/esportOverview', {
            state: {}});};
            const handleClickRanking = (e) => {
                e.preventDefault();   
                  navigate('/esportRanking', {
                    state: {}});};
                    const handleClickPickBan = (e) => {
                        e.preventDefault();   
                          navigate('/esportPickBan', {
                            state: {}});};
                            const handleClickMatchList = (e) => {
                                e.preventDefault();   
                                  navigate('/esportMatchList', {
                                    state: {}});};
                                    const handleClickSchedule = (e) => {
                                        e.preventDefault();   
                                          navigate('/esportSchedule', {
                                            state: {}});};
                                            const handleClickBracket = (e) => {
                                                e.preventDefault();   
                                                  navigate('/esportBracket', {
                                                    state: {}});};

    // Initialiser ranking comme un tableau vide
    const [ranking, setRanking] = useState([]);

    const [selectedTournament, setSelectedTournament] = useState(value);

    // Fetch function for tournament ranking
    const fetchRanking = async (tournament) => {
      try {
        const url = `https://lolprostat.com:8088/tomatchlist?to=${tournament}`;
        const response = await fetch(url);
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const result = await response.json();
        console.log("Résultat brut API:", result);  // Ajoute ce log pour vérifier le type de données
    
        // Vérifie si le résultat est un tableau avant d'appliquer .filter()
        if (Array.isArray(result['participants'])) {
          const uniqueMatchIds = new Set();
          const filteredResult = result['participants'].filter(item => {
            if (!uniqueMatchIds.has(item.MatchId)) {
              uniqueMatchIds.add(item.MatchId);
              return true;
            }
            return false;
          });
    
          setRanking(filteredResult);
          console.log("Ranking filtré avec MatchId uniques:", filteredResult);
        } else {
          console.error("Le résultat de l'API n'est pas un tableau :", result);
        }
    
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

  // useEffect to fetch the data once the component mounts or when selectedTournament changes
  useEffect(() => {
      fetchRanking(selectedTournament);
      
  }, [selectedTournament]);

  console.log(ranking)

  return (
    <div>
        <Navbar />
        <section className="home">




        <div className="text">Eport hub</div>
            <div className="flexwrap mt10" >
                <div className="encadre90SA">
                    <EportBtnNav href='/esportOverview' name={name} value={value} text='Overview'/>
                    <EportBtnNav href='/esportRanking' name={name} value={value} text='Ranking'/>
                    <EportBtnNav href='/esportPickBan' name={name} value={value} text='Picks & Bans'/>
                    <div className="newBtnNav" style={{backgroundColor:'white'}}>Match List</div>
                    {/* <EportBtnNav href='/esportSchedule' name={name} value={value} text='Schedule'/>
                    <EportBtnNav href='/esportBracket' name={name} value={value} text='Bracket'/> */}
                    <EportBtnNav href='/esportplayerlist' name={name} value={value} text='Player List' />
                    <EportBtnNav href='/esportteamlist' name={name} value={value} text='Team List' />
                </div>
            </div>

            <div className="flexwrap mt25">
                <h1 className="encadre90fs">{name} - Match List</h1>
            </div>

            <div className="debutEncadre95Centre">
                    <table id="myTable" className="tablesorter test neumorphic">
                        <thead>
                            <tr>
                                <th>Game</th>
                                <th></th>
                                <th>Score</th>
                                <th></th>
                                <th>Tab</th>
                                <th>Best-of</th>
                                <th>Patch</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody id="tableBody">
                            {Array.isArray(ranking) && ranking.length > 0 ? (
                                ranking.map((item, index) => (
                                    <tr key={index}>
                                        <td><span onClick={() => navigate(`/esportBestOf?to=${value}&mid=${item.MatchId}&equipe=${item.Team1} vs ${item.Team2}`)} style={{ textDecoration: 'none', cursor: 'pointer', color: 'blue' }}>{item.Team1} vs {item.Team2}</span></td>
                                        {/* <td><a href={`https://example.com/team/${item.Team1}v${item.Team2}`} style={{ textDecoration: 'none' }}>{item.Team1} vs {item.Team2}</a></td> */}
                                        <td style={{ color: item.Winner == 1 ? 'green' : 'red' }}>{item.Team1}</td>
                                        <td></td>
                                        <td style={{ color: item.Winner == 2 ? 'green' : 'red' }}>{item.Team2}</td>
                                        <td>{item.Tab}</td>
                                        <td>BO{item.BestOf}</td>
                                        <td>{item.Patch}</td>
                                        <td>{item.Date}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Loading data, please wait . . .</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>









        </section>
    </div>
  );
}

export default EportMatchList;
