import React, { useEffect, useRef } from 'react';

// Fonction pour générer des points aléatoires
const generateRandomPoints = (numPoints, maxWidth, maxHeight) => {
  const points = [];
  for (let i = 0; i < numPoints; i++) {
    const x = Math.floor(Math.random() * maxWidth);
    const y = Math.floor(Math.random() * maxHeight);
    points.push({ x, y });
  }
  return points;
};

function HeatMap() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Dimensions de l'image de fond (grande)
    const imgWidth = 15000;
    const imgHeight = 15000;

    // Dimensions du canvas (petit)
    const canvasWidth = 600;
    const canvasHeight = 600;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Générer des points aléatoires
    const points = generateRandomPoints(10, imgWidth, imgHeight);

    // Dessiner une image de fond fictive (par exemple, une couleur de fond)
    ctx.fillStyle = '#e0e0e0'; // Couleur de fond gris clair
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // Dessiner les points
    ctx.fillStyle = 'red'; // Couleur des points

    // Calculer les échelles pour ajuster les coordonnées des points
    const xScale = canvasWidth / imgWidth;
    const yScale = canvasHeight / imgHeight;

    points.forEach(point => {
      const scaledX = point.x * xScale;
      const scaledY = point.y * yScale;
      ctx.beginPath();
      ctx.arc(scaledX, scaledY, 5, 0, 2 * Math.PI); // Dessiner un cercle de rayon 5
      ctx.fill();
    });
  }, []);

  return (
    <div style={{  width: '600px', height: '600px' }}>
      <canvas ref={canvasRef} />
    </div>
  );
}

export default HeatMap;
