import React from 'react';



function SpinnerAttente({ text }) {
  return (
    <div className="graph0col">
        <div className="textSpinner">We are charging data for your {text}... This steps will long up to 5s!</div>
        <div className="spinner"></div>
    </div>
  );
}

export default SpinnerAttente;
