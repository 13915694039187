import React from 'react';

function SautDemarquation({ href, iconClass, text }) {
  return (
    <div className='demarcation'>
        <div className='border'></div>
    </div>
  );
}

export default SautDemarquation;
